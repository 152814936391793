import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Card, Row, Col } from 'react-bootstrap';

function SeoCard({ title, type, data, flip }) {
  const { formatMessage } = useIntl();

  const getCardColor = () => {
    let cl = 'secondary';
    if (data) {
      cl = flip ? 'danger' : 'success';
    } else if (data === 0) {
      cl = flip ? 'success' : 'danger';
    }
    return cl;
  };

  const getCardIcon = () => {
    let icon = 'bi-dash';
    if (data) {
      icon = flip ? 'bi-x' : 'bi-check';
    } else if (data === 0) {
      icon = flip ? 'bi-check' : 'bi-x';
    }
    return icon;
  };

  const getCardValue = () => {
    let v = formatMessage({ id: 'app.common.n/a' });
    if (data) {
      v =
        type === 'ap'
          ? formatMessage({ id: 'app.common.active' })
          : formatMessage({ id: 'app.common.yes' });
    } else if (data === 0) {
      v =
        type === 'ap'
          ? formatMessage({ id: 'app.common.inactive' })
          : formatMessage({ id: 'app.common.no' });
    }
    return v;
  };

  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="align-items-center gx-2">
          <Col>
            <Card.Subtitle className="mb-2">{title}</Card.Subtitle>
            <span className="text-dark">{getCardValue()}</span>
          </Col>
          <Col xs="auto">
            <span className={`icon icon-circle icon-${getCardColor()}`}>
              <i className={`display-5 ${getCardIcon()}`} />
            </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

SeoCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flip: PropTypes.bool,
};

SeoCard.defaultProps = {
  data: null,
  flip: false,
};

export default SeoCard;
