import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Form,
  Button,
  InputGroup,
  Spinner,
  ListGroup,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { lowerCase } from 'lodash';
import {
  FormError,
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../../components';
import { yup } from '../../../lib';
import {
  useAxiosMutation,
  useAxiosQuery,
  useConfirmModal,
} from '../../../hooks';
import { PropertyContext } from '../../../context/PropertyContext';

const itemShape = {
  id: PropTypes.number.isRequired,
  client_id: PropTypes.number.isRequired,
  property_id: PropTypes.number.isRequired,
  keyword: PropTypes.string.isRequired,
};

function KeywordListItem({ item, onDelete, propertyId }) {
  const { formatMessage } = useIntl();
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });

  const {
    isLoading: apiLoading,
    error: apiError,
    mutate: apiFetch,
  } = useAxiosMutation({
    url: '/review_keywords/delete',
    params: { hotel_id: propertyId },
  });

  const handleOnDelete = async () => {
    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDeleteXY"
          values={{
            x: item.keyword,
            y: lowerCase(formatMessage({ id: 'app.common.keyword' })),
          }}
        />
      ),
    });
    if (isSuccess) {
      apiFetch(
        { id: item.id },
        {
          onSuccess: () => {
            onDelete(item);
          },
        }
      );
    }
  };

  return (
    <ListGroup.Item className="d-flex align-items-center">
      <span>{item.keyword}</span>
      <Button
        disabled={apiLoading}
        size="xs"
        variant="danger"
        className={`ms-auto ${apiLoading || apiError ? '' : 'btn-icon'}`}
        onClick={handleOnDelete}
      >
        {apiLoading ? (
          <>
            <Spinner animation="border" size="xs" className="me-1" />
            <FormattedMessage id="app.common.loading" />
          </>
        ) : (
          <span>
            {apiError ? (
              <>
                <i className="bi-exclamation-triangle me-1" />
                <FormattedMessage id="app.common.tryAgain" />
              </>
            ) : (
              <i className="bi-trash" />
            )}
          </span>
        )}
      </Button>
    </ListGroup.Item>
  );
}

KeywordListItem.propTypes = {
  propertyId: PropTypes.number.isRequired,
  item: PropTypes.shape(itemShape).isRequired,
  onDelete: PropTypes.func,
};
KeywordListItem.defaultProps = {
  onDelete: () => {},
};

function KeywordList({ items, onDelete, propertyId }) {
  return (
    <ListGroup className="list-group-sm">
      {items.length === 0 && (
        <ListGroup.Item>
          <FormattedMessage id="app.common.noResultsFound" />
        </ListGroup.Item>
      )}
      {items.map((item) => (
        <KeywordListItem
          key={item.id}
          item={item}
          onDelete={onDelete}
          propertyId={propertyId}
        />
      ))}
    </ListGroup>
  );
}

KeywordList.propTypes = {
  propertyId: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(itemShape)),
  onDelete: PropTypes.func,
};
KeywordList.defaultProps = {
  items: [],
  onDelete: () => {},
};

function KeywordForm({ onSuccess, propertyId }) {
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(
      yup
        .object({
          keyword: yup.string().required().min(3),
        })
        .required()
    ),
  });

  const {
    isLoading: apiLoading,
    error: apiError,
    mutate: apiFetch,
  } = useAxiosMutation({
    url: '/review_keywords/insert',
    params: { hotel_id: propertyId },
  });

  const handleOnSubmit = (formData) => {
    apiFetch(formData, {
      onSuccess: (data) => {
        if (data?.id) {
          reset();
          onSuccess(data);
        }
      },
    });
  };

  return (
    <>
      <RequestResult type="error" message={apiError} />
      <Form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
        <Form.Label htmlFor="keyword">
          <FormattedMessage id="app.common.addKeyword" />
        </Form.Label>
        <InputGroup
          className={`${isSubmitted && !!errors.keyword ? 'is-invalid' : ''} ${
            isSubmitted && !errors.keyword ? 'is-valid' : ''
          }`}
        >
          <Form.Control
            disabled={apiLoading}
            isInvalid={isSubmitted && !!errors.keyword}
            isValid={isSubmitted && !errors.keyword}
            id="keyword"
            placeholder={formatMessage({ id: 'app.common.enterKeyword' })}
            {...register('keyword')}
          />
          <div className="input-group-append input-group-append-last-sm-down-none">
            <Button
              type="submit"
              disabled={apiLoading}
              className="d-none d-sm-inline-block"
            >
              {apiLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-1" />
                  <FormattedMessage id="app.common.loading" />
                </>
              ) : (
                <FormattedMessage id="app.common.add" />
              )}
            </Button>
          </div>
        </InputGroup>
        <FormError error={errors.keyword} className="mb-3 mb-sm-0" />
        <Button
          type="submit"
          disabled={apiLoading}
          className="w-100 d-sm-none mt-2"
        >
          {apiLoading ? (
            <>
              <Spinner animation="border" size="sm" className="me-1" />
              <FormattedMessage id="app.common.loading" />
            </>
          ) : (
            <FormattedMessage id="app.common.add" />
          )}
        </Button>
      </Form>
    </>
  );
}

KeywordForm.propTypes = {
  propertyId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
};
KeywordForm.defaultProps = {
  onSuccess: () => {},
};

function Preferences() {
  const { activeProperty } = useContext(PropertyContext);

  const [controlledApiData, setControlledApiData] = useState(null);

  const { isFetching: apiLoading, error: apiError } = useAxiosQuery({
    url: '/review_keywords/list',
    preventFetch: !activeProperty?.id,
    params: { hotel_id: activeProperty?.id },
    onSuccess: setControlledApiData,
  });

  const addItem = (item) => {
    setControlledApiData((prevState) => [...prevState, item]);
  };

  const removeItem = (item) => {
    setControlledApiData((prevState) =>
      prevState.filter((nItem) => nItem.id !== item.id)
    );
  };

  return (
    <div>
      <Card>
        <Card.Header>
          <Card.Title bsPrefix="card-header-title" as="h4">
            <FormattedMessage id="app.common.manageReviewKeywords" />
            <Tooltip
              content={
                <FormattedMessage id="app.helpers.reputationManager.preferences.reviewKeywords" />
              }
            >
              <i className="bi-question-circle text-body ms-1" />
            </Tooltip>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError} />
          {!apiLoading && !apiError && controlledApiData && (
            <>
              <div className="mb-5">
                <KeywordForm
                  onSuccess={addItem}
                  propertyId={activeProperty?.id}
                />
              </div>
              <div className="form-label mb-3">
                <FormattedMessage id="app.common.keywordList" />
              </div>
              <KeywordList
                items={controlledApiData}
                onDelete={removeItem}
                propertyId={activeProperty?.id}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default Preferences;
