import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Badge, Card, Nav, Tab, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { PropertyContext } from '../../../../context/PropertyContext';
import { RequestLoading, RequestResult } from '../../../../components';
import { WordCloudChart } from '../../../../components/Charts';
import Constants from '../../../../constants';

function Badges({ data, color }) {
  return (
    <div>
      {data.map(({ val, result }, index) => (
        <Button
          key={`keyword_${index.toString()}`}
          variant={color}
          size="sm"
          className="me-1 mb-1 rounded-pill py-1"
          style={{ cursor: 'default' }}
        >
          {val}
          <Badge bg="dark" className="fw-normal ms-2">
            {result}
          </Badge>
        </Button>
      ))}
    </div>
  );
}

Badges.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ val: PropTypes.string, result: PropTypes.number })
  ).isRequired,
  color: PropTypes.string.isRequired,
};

const sections = [
  {
    titleKey: 'wordCloud',
    Component: WordCloudChart,
  },
  {
    titleKey: 'keywords',
    Component: Badges,
  },
];

const types = [
  { ...Constants.Review.Types.Positive, params: {} },
  { ...Constants.Review.Types.Negative, params: { rating: [1, 2] } },
];
function TopKeywords() {
  const { activeProperty } = useContext(PropertyContext);
  const [activeSection, setActiveSection] = useState(sections[0].titleKey);
  const [activeType, setActiveType] = useState(types[0].id);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/most_used_words',
    preventFetch: !activeProperty?.id || !activeType,
    params: {
      id: activeProperty?.id,
      ...(types.find((item) => item.id === activeType)?.params || {}),
    },
    select: (data) =>
      data?.own?.reviews.sort((a, b) => a.result - b.result) || [],
  });

  return (
    <Card className="h-100">
      <Card.Header className="card-header-content-between">
        <Row className="justify-content-between align-items-center flex-grow-1">
          <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.topKeywords" />
            </Card.Title>
          </Col>
          <Col xs="auto">
            <Row className="align-items-sm-center">
              <Col sm="auto" className="mb-2 mb-md-0">
                <Nav
                  variant="segment"
                  fill
                  as="ul"
                  activeKey={activeType}
                  onSelect={setActiveType}
                >
                  {types.map(({ id, labelKey, icon, color }) => (
                    <Nav.Item key={id} as="li">
                      <Nav.Link eventKey={id}>
                        <i className={`${icon} text-${color} nav-icon me-1`} />
                        <FormattedMessage id={`app.common.${labelKey}`} />
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>

              {!apiLoading && !apiError && apiData?.length > 0 && (
                <Col sm="auto" className="mb-2 mb-md-0">
                  <Nav
                    variant="segment"
                    fill
                    as="ul"
                    activeKey={activeSection}
                    onSelect={setActiveSection}
                  >
                    {sections.map(({ titleKey }) => (
                      <Nav.Item key={titleKey} as="li">
                        <Nav.Link eventKey={titleKey}>
                          <FormattedMessage id={`app.common.${titleKey}`} />
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData?.length === 0 && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && !apiError && apiData?.length > 0 && (
          <Tab.Container activeKey={activeSection}>
            <Tab.Content>
              {sections.map(({ titleKey, Component }) => (
                <Tab.Pane key={titleKey} eventKey={titleKey}>
                  <Component
                    data={
                      titleKey === 'wordCloud'
                        ? {
                            labels: apiData.map((item) => item.val),
                            datasets: [
                              {
                                data: apiData.map(
                                  (item, index) => 10 + index * 10
                                ),
                                color:
                                  Constants.Review.Types[activeType].hexColor,
                              },
                            ],
                          }
                        : apiData
                    }
                    color={
                      titleKey === 'wordCloud'
                        ? undefined
                        : Constants.Review.Types[activeType].color
                    }
                  />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        )}
      </Card.Body>
    </Card>
  );
}

export default TopKeywords;
