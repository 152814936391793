import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  ListGroup,
  Accordion,
  Badge,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';

const accordionItems = ['internal_links', 'external_links'];

function BrokenLinksCard({ url }) {
  const [showModal, setShowModal] = useState(false);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url,
    requestType: 'get',
    preventFetch: !showModal,
  });

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCheck = () => {
    setShowModal(true);
  };

  const getCodeColor = (code) => {
    let cl = 'secondary';

    const nCode = code[0];

    switch (nCode) {
      case '2':
        cl = 'primary';
        break;

      case '3':
        cl = 'info';
        break;

      case '4':
        cl = 'warning';
        break;

      case '5':
        cl = 'danger';
        break;

      default:
        cl = 'secondary';
        break;
    }
    return cl;
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <Row className="align-items-center gx-2">
            <Col>
              <Card.Subtitle className="mb-2">
                <FormattedMessage id="app.common.brokenLinks" />
              </Card.Subtitle>
              <Button variant="primary" size="xs" onClick={handleCheck}>
                <FormattedMessage id="app.common.check" />
              </Button>
            </Col>
            <Col xs="auto">
              <span className="icon icon-circle icon-primary">
                <i className="fs-4 bi-search" />
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={closeModal} scrollable size="lg">
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="app.common.brokenLinks" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RequestLoading loading={apiLoading} />
          <RequestResult type="error" message={apiError} />

          {!apiLoading && !apiError && apiData && (
            <div>
              <Accordion>
                {accordionItems.map((key, index) => (
                  <Accordion.Item key={key} eventKey={`google_speed_${index}`}>
                    <Accordion.Header>
                      <span className="text-capitalize">
                        {key.split('_').join(' ')}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ListGroup>
                        {apiData.broken_links[key].map((item, i) => (
                          <ListGroup.Item
                            key={`google_speed_${key}_${i.toString()}`}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div className="overflow-hidden me-2">
                              <Badge
                                bg={getCodeColor(item[1])}
                                className="me-2"
                              >
                                {item[1]}
                              </Badge>
                              {item[0]}
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={closeModal} variant="white">
            <FormattedMessage id="app.common.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
BrokenLinksCard.propTypes = {
  url: PropTypes.string.isRequired,
};

export default BrokenLinksCard;
