import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import {
  useAxiosQuery,
  useConfirmModal,
  useFormModal,
} from '../../../../hooks';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { yup } from '../../../../lib';

const scoreOptions = [
  {
    Name: 'Must-Have',
    Description:
      "This feature is critically important for the customer's home selection and must be present in the house.",
    Score: 10,
    Color: '#00FF00',
  },
  {
    Name: 'Highly Preferred',
    Description:
      "This feature is important for the customer and is a desired feature in the house, but it's not mandatory.",
    Score: 8,
    Color: '#009900',
  },
  {
    Name: 'Preferred',
    Description:
      "This feature is preferred by the customer, but it's less important than others.",
    Score: 6,
    Color: '#33CC33',
  },
  {
    Name: 'Nice to Have',
    Description:
      "This feature is a nice addition for the customer, but it doesn't play a fundamental role in home selection.",
    Score: 4,
    Color: '#3399FF',
  },
  {
    Name: 'Not Necessary',
    Description:
      "This feature is not important to the customer, and it's not required in the house.",
    Score: 2,
    Color: '#6666CC',
  },
  {
    Name: 'Not Important',
    Description:
      "This feature is of no significance to the customer and doesn't affect home selection.",
    Score: 0,
    Color: '#999999',
  },
  {
    Name: 'Must Not Have',
    Description:
      'This feature is absolutely unacceptable to the customer, and it should never be present in the house.',
    Score: -10,
    Color: '#FF0000',
  },
  {
    Name: 'Strongly Discouraged',
    Description:
      'This feature can have a negative impact on the customer and should be avoided in the house if possible.',
    Score: -8,
    Color: '#CC0000',
  },
  {
    Name: 'Discouraged',
    Description:
      "The customer may not want this feature in the house, but it's not a strict requirement.",
    Score: -6,
    Color: '#FF3333',
  },
];

const formatValueLabel = ({ label, description }) => (
  <div className="d-flex flex-column">
    {label}
    <small>{description}</small>
  </div>
);

const formatScoreLabel = ({ label, description, color }) => (
  <div className="d-flex flex-column">
    <span style={{ color }}>{label}</span>
    <small>{description}</small>
  </div>
);

function ProfileForm({ paths, params }) {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [apiData, setApiData] = useState([]);
  const formRef = useRef();
  const { form, updateFields } = useFormModal();
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });

  const {
    data: parametersData,
    isLoading: parametersLoading,
    error: parametersError,
  } = useAxiosQuery({
    url: paths.profileJson,
    requestType: 'get',
    preventDataValidation: true,
  });

  const { isLoading: apiLoading, error: apiError } = useAxiosQuery({
    url: paths.list,
    params: { page: 1, quantity: 999999, ...params, ...paths.listParams },
    onSuccess: (data) => {
      if (data) {
        setApiData(data);
      }
    },
  });

  const getFields = useCallback(
    (type, category, parameter) => [
      {
        cols: [
          {
            key: 'category',
            apiKey: 'value',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: parametersData?.[type]
                    ? Object.keys(parametersData[type]).map((categoryKey) => ({
                        value: categoryKey,
                        label: categoryKey,
                      }))
                    : [],
                  isSearchable: true,
                },
              },
            },
            schema: yup.string().required(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'parameter',
            apiKey: 'parameter',
            type: 'react-select',
            visibility: { field: 'category', is: (v) => v || v === 0 },
            options: {
              controller: {
                props: {
                  options:
                    parametersData?.[type] && parametersData[type][category]
                      ? parametersData[type][category].map((nItem) => ({
                          value: nItem.parameter,
                          label: nItem.label,
                          description: nItem.description,
                        }))
                      : [],
                  formatOptionLabel: formatValueLabel,
                  isSearchable: true,
                },
              },
            },
            schema: yup.string().when('category', {
              is: (v) => v,
              then: yup.string().required(),
            }),
          },
        ],
      },
      {
        cols: [
          {
            key: 'value',
            apiKey: 'value1',
            type:
              parametersData[type][category]?.find(
                (nItem) => nItem.parameter === parameter
              )?.input === 'request'
                ? 'async-select'
                : 'react-select',
            visibility: { field: 'parameter', is: (v) => v || v === 0 },
            options: {
              controller: {
                props:
                  parametersData[type][category]?.find(
                    (nItem) => nItem.parameter === parameter
                  )?.input === 'request'
                    ? {
                        requestUrl: paths.parameters,
                        requestType: 'post',
                        requestParams: {
                          valueKey: parameter,
                          ...params,
                          ...paths.parametersParams,
                        },
                        requestInputKey: null,
                        requestResultParser: (data) => {
                          if (data) {
                            return data.map((nItem) => ({
                              value: `${nItem.value}`,
                              label: `${nItem.value}`,
                            }));
                          }
                          return [];
                        },
                        getOptionValue: ({ value }) => value,
                      }
                    : {
                        options:
                          parametersData?.[type] &&
                          parametersData[type][category] &&
                          parametersData[type][category].find(
                            (nItem) => nItem.parameter === parameter
                          )
                            ? parametersData[type][category]
                                .find((nItem) => nItem.parameter === parameter)
                                .options.map((nItem) => ({
                                  value: `${nItem.value}`,
                                  label: nItem.label,
                                }))
                            : [],
                        isSearchable: true,
                      },
              },
            },
            schema: yup.string().when('parameter', {
              is: (v) => v,
              then: yup.string().required(),
            }),
          },
        ],
      },
      {
        cols: [
          {
            key: 'score',
            apiKey: 'point',
            type: 'react-select',
            visibility: { field: 'value', is: (v) => v || v === 0 },
            options: {
              controller: {
                props: {
                  options: scoreOptions.map((nItem) => ({
                    value: nItem.Score,
                    label: nItem.Name,
                    description: nItem.Description,
                    color: nItem.Color,
                  })),
                  formatOptionLabel: formatScoreLabel,
                  isSearchable: true,
                },
              },
            },
            schema: yup.string().when('value', {
              is: (v) => v,
              then: yup.string().required(),
            }),
          },
        ],
      },
    ],
    [parametersData, params, paths]
  );

  const updateFormFields = useCallback(
    (type, data, options) => {
      if (options.type === 'change' && options.name === 'category') {
        updateFields(getFields(type, data.category));
        formRef.current.resetField('parameter');
        formRef.current.resetField('value');
        formRef.current.resetField('score');
      }

      if (options.type === 'change' && options.name === 'parameter') {
        updateFields(getFields(type, data.category, data.parameter));
        formRef.current.resetField('value');
        formRef.current.resetField('score');
      }

      if (options.type === 'change' && options.name === 'value') {
        formRef.current.resetField('score');
      }
    },
    [getFields, updateFields]
  );

  const onDeleteItem = async (id, title) => {
    const requestUrl = paths.delete;
    const requestParams = {
      ...params,
      ...paths.deleteParams,
      id,
    };

    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureYouWantToDeleteXY"
          values={{
            x: title,
            y: lowerCase(formatMessage({ id: `app.common.parameter` })),
          }}
        />
      ),
      requestUrl,
      requestParams,
    });

    if (isSuccess) {
      setApiData((prevItems) => prevItems.filter((nItem) => nItem.id !== id));
    }
  };

  const onCreateOrUpdateItem = async (type, item) => {
    const defaultValues = item
      ? {
          category: item.value,
          parameter: item.parameter,
          value: item.value1,
          score: item.point,
        }
      : {};

    const requestParams = item
      ? {
          id: item.id,
          table: type,
          ...params,
          ...paths.updateParams,
        }
      : {
          table: type,
          ...params,
          ...paths.createParams,
        };

    const formData = await form({
      ref: formRef,
      size: 'lg',
      title: item ? `edit${type}Item` : `new${type}Item`,
      confirmLabel: item ? 'save' : 'create',
      requestUrl: item ? paths.update : paths.create,
      requestParams,
      fields: item
        ? getFields(type, defaultValues.category, defaultValues.parameter)
        : getFields(type),
      fetchOnStart: false,
      defaultValues,
      onChange: (data, options) => {
        updateFormFields(type, data, options);
      },
    });

    if (formData) {
      setApiData((prevItems) => [
        ...prevItems.filter((nItem) => nItem.id !== formData.Result.id),
        formData.Result,
      ]);
    }
  };

  useEffect(() => {
    setLoading(parametersLoading || apiLoading);
  }, [parametersLoading, apiLoading]);

  useEffect(() => {
    setError(parametersError || apiError);
  }, [parametersError, apiError]);

  return (
    <>
      <RequestLoading loading={loading} size="lg" margin="5" />
      <RequestResult type="error" message={error} />

      {!loading && !error && parametersData && (
        <Row>
          {Object.keys(parametersData).map((key) => (
            <Col key={key} xl="6" className="mb-3 mb-xl-5">
              <Card className="h-100">
                <Card.Header className="card-header-content-between">
                  <Card.Title bsPrefix="card-header-title" as="h4">
                    <FormattedMessage id={`app.common.${key}`} />
                  </Card.Title>
                  {paths.create && (
                    <Button
                      size="sm"
                      onClick={() => {
                        onCreateOrUpdateItem(key);
                      }}
                    >
                      <i className="bi-plus me-1" />
                      <FormattedMessage id="app.common.add" />
                    </Button>
                  )}
                </Card.Header>
                <Card.Body>
                  {apiData?.filter((item) => item.table === key).length > 0 ? (
                    <ListGroup
                      variant="flush"
                      className="list-group-start-bordered"
                    >
                      {apiData
                        ?.filter((item) => item.table === key)
                        .map((item) => {
                          const scoreOption = scoreOptions.find(
                            (nItem) => nItem.Score === item.point
                          );

                          const parameter = parametersData[key][
                            item.value
                          ].find((nItem) => nItem.parameter === item.parameter);
                          const valueOption = parameter.options.find(
                            (nItem) => `${nItem.value}` === `${item.value1}`
                          );

                          return (
                            <ListGroup.Item key={item.id}>
                              <div
                                className="list-group-item-action"
                                style={{ borderColor: scoreOption.Color }}
                              >
                                <Row>
                                  <Col sm className="mb-2 mb-sm-0">
                                    <h6 className="text-body fw-normal mb-3">
                                      {item.value}
                                      <i className="bi-chevron-right mx-1 small" />
                                      {parameter.label}
                                      <Tooltip
                                        content={parameter.description}
                                        className="d-inline-block small"
                                      >
                                        <i className="bi-info-circle text-body ms-1" />
                                      </Tooltip>
                                    </h6>
                                    <h4 className="mb-3">
                                      {valueOption || valueOption === 0
                                        ? valueOption.label
                                        : item.value1}
                                    </h4>

                                    <div>
                                      <span
                                        className="legend-indicator"
                                        style={{
                                          backgroundColor: scoreOption.Color,
                                        }}
                                      />
                                      <span
                                        style={{ color: scoreOption.Color }}
                                      >
                                        {scoreOption.Name}
                                        <Tooltip
                                          content={scoreOption.Description}
                                          className="d-inline-block small"
                                        >
                                          <i className="bi-info-circle ms-1" />
                                        </Tooltip>
                                      </span>
                                    </div>
                                  </Col>
                                  <Col sm="auto">
                                    {paths.update && (
                                      <Button
                                        size="xs"
                                        className="btn-icon me-1"
                                        onClick={() => {
                                          onCreateOrUpdateItem(key, item);
                                        }}
                                      >
                                        <i className="bi-pencil" />
                                      </Button>
                                    )}
                                    {paths.delete && (
                                      <Button
                                        variant="danger"
                                        size="xs"
                                        className="btn-icon"
                                        onClick={() => {
                                          onDeleteItem(
                                            item.id,
                                            parameter.label
                                          );
                                        }}
                                      >
                                        <i className="bi-trash" />
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                    </ListGroup>
                  ) : (
                    <RequestResult
                      type="secondary"
                      message="warnings.emptyList.message"
                      image
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

ProfileForm.propTypes = {
  paths: PropTypes.shape({
    profileJson: PropTypes.string,
    parameters: PropTypes.string,
    parametersParams: PropTypes.objectOf(PropTypes.any),
    list: PropTypes.string,
    listParams: PropTypes.objectOf(PropTypes.any),
    create: PropTypes.string,
    createParams: PropTypes.objectOf(PropTypes.any),
    delete: PropTypes.string,
    deleteParams: PropTypes.objectOf(PropTypes.any),
    update: PropTypes.string,
    updateParams: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  params: PropTypes.objectOf(PropTypes.any),
};

ProfileForm.defaultProps = {
  params: {},
};

export default ProfileForm;
