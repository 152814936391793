import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import { FormattedMessage } from 'react-intl';
import { yup } from '../../../lib';
import { SmartForm } from '../../../components';
import ProfileForm from './partials/ProfileForm';

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

function Form() {
  const { id } = useParams();

  /* const {
    isLoading: apiLoading,
    error: apiError,
    refetch: apiFetch,
  } = useAxiosQuery({
    url: 'https://maps.googleapis.com/maps/api/place/autocomplete/json',
    requestType: 'get',
    preventFetch: true,
    params: {
      key: mapsApiKey,
      types: ['establishment'],
      input: titleQuery,
    },
    onSuccess: (data) => {
      if (data.status === 'OK') {
        setApiData(data.predictions);
      }
    },
  }); */

  /* const fields = [
    {
      cols: [
        {
          key: 'name',
          schema: yup.string().required(),
        },
        {
          key: 'email',
          schema: yup.string().email().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'password',
          type: 'password',
          schema: yup.string().min(6).required(),
        },
        {
          key: 'passwordConfirmation',
          type: 'password',
          schema: yup
            .string()
            .min(6)
            .oneOf([yup.ref('password')], {
              key: 'app.common.passwordConfirmationNotMatchError',
            })
            .required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'notification',
          type: 'switch',
          label: <FormattedMessage id="app.common.allowNotifications" />,
          schema: yup.boolean().oneOf([true], {
            key: 'app.common.allowNotifications',
          }),
        },
        {
          key: 'summary',
          type: 'textarea',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'terms',
          type: 'checkbox',
          label: <FormattedMessage id="app.common.acceptTerms" />,
          schema: yup.boolean().oneOf([true], {
            key: 'app.common.acceptTermsError',
          }),
        },
        {
          key: 'selectProperty',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: [
                  { label: 'x', value: 'x' },
                  { label: 'y', value: 'y' },
                ],
                isSearchable: false,
              },
            },
          },
          schema: yup.object().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'color',
          type: 'color',
          schema: yup.string().required(),
        },
        {
          key: 'file',
          type: 'file',
          schema: yup
            .object()
            .shape({
              file: yup.mixed().required(),
            })
            .nullable(),
        },
      ],
    },
  ]; */

  /*
const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

function SelectYourPropertyStep({ onNext, onLoading, value, onChange }) {
  const { formatMessage } = useIntl();
  const [apiData, setApiData] = useState([]);
  const [titleQuery, setTitleQuery] = useState('');

  
  */

  const fields = [
    {
      cols: [
        {
          key: 'asyncSelect',
          type: 'async-select',
          options: {
            controller: {
              props: {
                requestUrl:
                  'https://maps.googleapis.com/maps/api/place/autocomplete/json',
                requestType: 'get',
                requestParams: {
                  key: mapsApiKey,
                  types: ['establishment'],
                },
                requestInputKey: 'input',
                requestResultParser: (data) => {
                  if (data.status === 'OK') {
                    return data.predictions.map((suggestion) => ({
                      label: suggestion.description,
                      value: suggestion,
                    }));
                  }
                  return [];
                },
                getOptionValue: ({ value }) => value,
              },
            },
          },
          schema: yup.object().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'x',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                ],
              },
            },
          },
          schema: yup.string().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'for1',
          type: 'maskedInput',
          visibility: { field: 'x', is: (v) => v === '1' },
          options: {
            controller: {
              mask: 'currency',
              maskOptions: {
                digits: 0,
                allowMinus: false,
                rightAlign: false,
                max: 999999,
                autoUnmask: true,
              },
            },
          },
          schema: yup.string().when('x', {
            is: '1',
            then: yup.string().required(),
          }),
        },
      ],
    },
    {
      cols: [
        {
          key: 'for2',
          type: 'maskedInput',
          visibility: { field: 'x', is: (v) => v === '2' },
          options: {
            controller: {
              mask: 'currency',
              maskOptions: {
                digits: 0,
                allowMinus: false,
                rightAlign: false,
                max: 9999,
                autoUnmask: true,
              },
            },
          },
          schema: yup.string().when('x', {
            is: '2',
            then: yup.string().required(),
          }),
        },
      ],
    },
    {
      cols: [
        {
          key: 'for3',
          type: 'maskedInput',
          visibility: { field: 'x', is: (v) => v === '3' },
          options: {
            controller: {
              mask: 'percentage',
              maskOptions: {
                rightAlign: false,
                autoUnmask: true,
              },
            },
          },
          schema: yup.string().when('x', {
            is: '3',
            then: yup.string().required(),
          }),
        },
      ],
    },
    {
      cols: [
        {
          key: 'color',
          type: 'option',
          schema: yup.string(),
        },
        {
          key: 'asd',
          schema: yup.string().required(),
        },
        {
          key: 'file',
          type: 'file',
          options: {
            controller: {
              props: {
                multiple: true,
              },
            },
          },
          schema: yup.mixed().requiredFile(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'wysiwyg',
          type: 'wysiwyg',
          schema: yup.string().required(),
        },
      ],
    },
  ];

  const onRequestSuccess = (data) => {
    console.log(data);
  };

  const onRequestError = (err) => {
    console.log(err);
  };

  const TOKEN =
    '1cd111f4535a04eef5b6fcf655f7e7efdb45251d298c21d3478e0bb49b481b1271ecb46a4758f34f3c97636da32e4ff64fa2c47ac80cb368230728ee18c8fa84d96d07fa3ee3af569ee4fc0d0842bb9f498bf8821c5384ba5b6e0ada3036d064f6d75130b858fd0a65f58d6eb447f36d760f1c53c7d6521dbfa679cea5';
  const PROFILE_ID = 8;

  return (
    <Row>
      <Col xs="12">
        <ProfileForm
          paths={{
            profileJson: 'https://qors.io/service/app/data/profile.json', // get request only, no params
            parameters: 'https://qors.io/service/Parameters/list',
            list: 'https://qors.io/service/profile_parameter_points/list',
            listParams: { profile_id: PROFILE_ID },
            create: 'https://qors.io/service/profile_parameter_points/insert',
            createParams: { profile_id: PROFILE_ID },
            delete: 'https://qors.io/service/profile_parameter_points/delete',
            // update: 'https://qors.io/service/profile_parameter_points/update',
          }}
          params={{ token: TOKEN }}
        />
      </Col>
      <Col xs="12" className="d-none">
        <SmartForm
          fields={fields}
          requestUrl="/test/update"
          requestParams={{ id }}
          defaultValues={{ x: '1' }}
          onRequestSuccess={onRequestSuccess}
          onRequestError={onRequestError}
          onSubmit={(data) => {
            console.log(data);
          }}
        />
      </Col>
    </Row>
  );
}

export default Form;
