import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Card, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { PropertyContext } from '../context/PropertyContext';
import { Routes as AppRoutes } from '../router/routeMapping';

function DropdownFooter() {
  return (
    <Dropdown.Item
      as={Link}
      to={AppRoutes.protected.PROPERTY_CREATE.path}
      className="text-center"
    >
      <FormattedMessage id="app.common.addNewProperty" />
    </Dropdown.Item>
  );
}

function NavbarPropertySelect({ variant, isMobile, ...props }) {
  const { properties, activeProperty, updateActiveProperty } =
    useContext(PropertyContext) || {};

  const onChange = (v) => {
    const founded = properties.find((item) => item.id === v.id);
    updateActiveProperty(founded);
  };

  return (
    <div {...props}>
      {activeProperty && (
        <Dropdown>
          {isMobile ? (
            <Dropdown.Toggle
              variant="light"
              size="sm"
              className="w-100 mt-3"
              /* onClick={() => {
                setShowOnMobile(true);
              }} */
            >
              <span className="d-inline-block text-truncate text-start w-100">
                {activeProperty?.title}
              </span>
            </Dropdown.Toggle>
          ) : (
            <Dropdown.Toggle variant="light">
              <span
                className="d-inline-block text-truncate text-start"
                style={{ width: '14rem' }}
              >
                {activeProperty?.title}
              </span>
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu
            renderOnMount
            rootCloseEvent="mousedown"
            className={`dropdown-card navbar-dropdown-menu-borderless ${
              isMobile ? 'w-100' : ''
            }`}
          >
            {/* <div className="d-flex justify-content-end d-lg-none mb-2">
              <Button
                variant=""
                size="sm"
                className="btn-icon me-2"
                onClick={() => {
                  setShowOnMobile(false);
                }}
              >
                <i className="bi-x-lg" />
              </Button>
            </div> */}
            <div className="card-body-height">
              {properties.map((item, index) => (
                <Dropdown.Item
                  as="div"
                  key={item.id}
                  active={activeProperty.id === item.id}
                  className={`m-0 rounded-0 ${index === 0 && 'rounded-top'}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="link"
                      className="flex-grow-1 me-2 text-start p-1"
                      style={{ maxWidth: '16rem' }}
                      onClick={() => {
                        if (activeProperty.id === item.id) {
                          return;
                        }
                        onChange(item);
                      }}
                    >
                      <h5 className="text-inherit mb-0">
                        {item.title}
                        {activeProperty.id === item.id && (
                          <Badge
                            bg="soft-success"
                            className="ms-1 text-success"
                          >
                            <FormattedMessage id="app.common.active" />
                          </Badge>
                        )}
                      </h5>
                      <span className="d-inline-block text-body small text-truncate w-100">
                        {item.fullAddress}
                      </span>
                    </Button>
                    <div>
                      <Button
                        as={Link}
                        size="xs"
                        to={AppRoutes.protected.PROPERTY_EDIT.path.replace(
                          ':id',
                          kebabCase(item.title)
                        )}
                      >
                        <i className="bi-pencil me-1" />
                        <FormattedMessage id="app.common.edit" />
                      </Button>
                    </div>
                  </div>
                </Dropdown.Item>
              ))}
            </div>
            <Card.Footer className="p-3">
              <Dropdown.Item as={DropdownFooter} />
            </Card.Footer>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

NavbarPropertySelect.propTypes = {
  variant: PropTypes.oneOf(['primary', 'light']),
  isMobile: PropTypes.bool,
};

NavbarPropertySelect.defaultProps = {
  variant: null,
  isMobile: false,
};

export default NavbarPropertySelect;
