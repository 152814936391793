import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  ListGroup,
  Button,
  Badge,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import SimpleBar from 'simplebar-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { startCase, toLower } from 'lodash';
import { Nouislider, RequestLoading, Tooltip } from '../../../../../components';

function ListItem({ item, onMouseEnter, onMouseLeave, onChange, checked }) {
  return (
    <ListGroup.Item
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      as="label"
      action
      htmlFor={`property_checkbox_${item.place_id}`}
    >
      <div className="d-flex">
        <div className="me-2">
          <input
            className="form-check-input"
            type="checkbox"
            value={item.place_id}
            name="property_checkbox"
            id={`property_checkbox_${item.place_id}`}
            checked={checked}
            onChange={onChange}
          />
        </div>
        <div>
          <div className="d-flex align-items-center h5 text-inherit mb-1">
            {item.mapIndex && (
              <Badge pill bg={checked ? 'success' : 'danger'} className="me-1">
                {item.mapIndex}
              </Badge>
            )}
            {startCase(toLower(item.name))}
          </div>
          <div className="d-block fs-6 text-body">{item.formatted_address}</div>
        </div>
      </div>
    </ListGroup.Item>
  );
}

ListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

const PropertyList = forwardRef(
  (
    {
      height,
      data,
      onChange,
      value,
      onMouseEnter,
      onMouseLeave,
      onNext,
      onPrev,
      hasMore,
      onLoadMore,
      max,
      radius,
      isLoading,
      isNextPageLoading,
    },
    ref
  ) => {
    const handleItemSelect = (e) => {
      const foundedProperty = data.find(
        (item) => item.place_id.toString() === e.target.value.toString()
      );

      if (!foundedProperty) {
        return;
      }

      const added = value.find(
        (item) => item.place_id.toString() === e.target.value.toString()
      );

      if (added) {
        const nValue = value.filter(
          (item) => item.place_id.toString() !== added.place_id.toString()
        );
        onChange([...nValue]);
        return;
      }

      if (value.length + 1 > max) {
        return;
      }

      onChange([...value, foundedProperty]);
    };

    const selectAll = () => {
      onChange([...data]);
    };

    const removeAll = () => {
      onChange([]);
    };

    return (
      <Card className="h-100">
        {radius && (
          <Card.Header>
            <Row className="align-items-center gx-0">
              <Col>
                <h6 className="mb-0 pe-2">
                  <FormattedMessage id="app.common.radius" />:
                </h6>
              </Col>
              <Col xs="auto">
                <div className="range-slider" style={{ width: 200 }}>
                  <Nouislider
                    className="range-slider-ui"
                    range={{
                      min: radius.min,
                      max: radius.max,
                    }}
                    start={radius.value}
                    step={radius.step}
                    tooltips={[radius.formatter(true)]}
                    format={radius.formatter()}
                    onChange={(val) => {
                      radius.onChange(val[0]);
                    }}
                    disabled={isLoading || isNextPageLoading}
                  />
                </div>
              </Col>
            </Row>
          </Card.Header>
        )}
        {isLoading ? (
          <div className="h-100">
            <RequestLoading loading={isLoading} />
          </div>
        ) : (
          <div className="h-100">
            {data.length > 0 ? (
              <>
                <Card.Header className="card-header-content-between align-items-center">
                  <h6 className="mb-0 pe-2">
                    <FormattedPlural
                      value={value.length}
                      one={
                        <FormattedMessage id="app.common.oneCompetitorSelected" />
                      }
                      other={
                        <FormattedMessage
                          id="app.common.nCompetitorsSelected"
                          values={{ n: value.length }}
                        />
                      }
                    />
                  </h6>
                  {max ? (
                    <Tooltip
                      placement="right"
                      content={
                        <FormattedMessage id="app.helpers.propertySelect.competitorsSelect.maxCompetitorsInfo" />
                      }
                    >
                      <h6 className="mb-0">
                        <span
                          className={`${
                            value.length < max ? 'text-success' : 'text-danger'
                          }`}
                        >
                          (
                          <FormattedMessage
                            id="app.common.maxN"
                            values={{ n: max }}
                          />
                          )
                        </span>
                        <i className="bi-question-circle ms-1" />
                      </h6>
                    </Tooltip>
                  ) : (
                    <div>
                      {data.length === value.length ? (
                        <Button variant="danger" size="xs" onClick={removeAll}>
                          <i className="bi-x-lg me-1" />
                          <FormattedMessage id="app.common.removeAll" />
                        </Button>
                      ) : (
                        <Button size="xs" onClick={selectAll}>
                          <i className="bi-check2 me-1" />
                          <FormattedMessage id="app.common.selectAll" />
                        </Button>
                      )}
                    </div>
                  )}
                </Card.Header>
                <Card.Body className="p-0 h-100">
                  <SimpleBar
                    ref={ref}
                    scrollableNodeProps={{ id: 'scroll-node' }}
                    style={{ height }}
                  >
                    <InfiniteScroll
                      scrollableTarget="scroll-node"
                      dataLength={data.length}
                      hasMore={hasMore}
                      next={onLoadMore}
                    >
                      <ListGroup variant="flush">
                        {data.map((item) => (
                          <ListItem
                            key={item.place_id}
                            item={item}
                            onMouseEnter={() => {
                              onMouseEnter(item);
                            }}
                            onMouseLeave={() => {
                              onMouseLeave();
                            }}
                            onChange={handleItemSelect}
                            checked={
                              !!value.find((p) => p.place_id === item.place_id)
                            }
                          />
                        ))}
                        {isNextPageLoading && (
                          <ListGroup.Item className="text-center p-3">
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                                className="me-1"
                              />
                              <FormattedMessage id="app.common.loading" />
                            </>
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                    </InfiniteScroll>
                  </SimpleBar>
                </Card.Body>
              </>
            ) : (
              <Card.Body>
                <h5 className="mb-0">
                  <FormattedMessage id="app.common.noCompetitorsFound" />
                </h5>
              </Card.Body>
            )}
          </div>
        )}
        <Card.Footer className="d-flex justify-content-between">
          <Button
            variant="ghost-primary"
            onClick={onPrev}
            disabled={isLoading || isNextPageLoading}
          >
            <i className="bi-chevron-left me-1" />
            <FormattedMessage id="app.common.previousStep" />
          </Button>
          <Button onClick={onNext} disabled={isLoading || isNextPageLoading}>
            <FormattedMessage id="app.common.continue" />
          </Button>
        </Card.Footer>
      </Card>
    );
  }
);

PropertyList.propTypes = {
  height: PropTypes.number,
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  max: PropTypes.number,
  radius: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    formatter: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
};

PropertyList.defaultProps = {
  height: 353,
  data: [],
  onChange: () => {},
  value: [],
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onNext: () => {},
  onPrev: () => {},
  hasMore: false,
  onLoadMore: () => {},
  max: undefined,
  radius: undefined,
  isLoading: false,
  isNextPageLoading: false,
};

export default PropertyList;
