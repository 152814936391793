import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { kebabCase } from 'lodash';
import { PageHeader, RequestLoading, RequestResult } from '../../components';
import {
  RecentReviews,
  TopKeywords,
  RatingSummary,
} from './ReputationManager/partials';
import { ScoreCard } from './SeoManager/partials';
import { useAxiosQuery } from '../../hooks';
import { PropertyContext } from '../../context/PropertyContext';
import Utils from '../../utils';
import { Routes as AppRoutes } from '../../router/routeMapping';

function ScoreCardWrapper() {
  const { activeProperty } = useContext(PropertyContext);
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/detail/getData',
    preventFetch: !activeProperty?.web,
    requestType: 'get',
    params: { url: Utils.String.trimUrl(activeProperty?.web) },
  });

  return (
    <>
      {(apiLoading || apiError || !activeProperty?.web) && (
        <Card className="h-100">
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.seoScore" />
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <RequestLoading loading={apiLoading} size="lg" margin="5" />
            <RequestResult type="error" message={apiError} />
            <RequestResult
              type="secondary"
              image
              title="emptyState.noWebsite.title"
              message={
                !activeProperty?.web ? 'emptyState.noWebsite.message' : null
              }
              action={{
                type: 'button',
                variant: 'primary',
                link: AppRoutes.protected.PROPERTY_EDIT.children.PROPERTY_EDIT_SETTINGS.path.replace(
                  ':id',
                  kebabCase(activeProperty.title)
                ),
                title: 'emptyState.noWebsite.action.title',
              }}
            />
          </Card.Body>
        </Card>
      )}
      {!apiLoading && !apiError && apiData && (
        <ScoreCard value={Number(apiData?.point)} />
      )}
    </>
  );
}

function Overview() {
  return (
    <div className="content container">
      <PageHeader title="dashboard" />
      <Row>
        <Col lg="8" className="mb-3 mb-lg-5">
          <RatingSummary />
        </Col>
        <Col lg="4" className="mb-3 mb-lg-5">
          <ScoreCardWrapper />
        </Col>
        <Col lg="6" className="mb-3 mb-lg-5">
          <RecentReviews />
        </Col>
        <Col lg="6" className="mb-3 mb-lg-5">
          <TopKeywords />
        </Col>
      </Row>
    </div>
  );
}

export default Overview;
