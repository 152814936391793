import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, InputGroup, ListGroup, Card } from 'react-bootstrap';
// import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import SimpleBar from 'simplebar-react';
import { useAxiosQuery } from '../../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../../components';

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

function SelectYourPropertyStep({ onNext, onLoading, value, onChange }) {
  const { formatMessage } = useIntl();
  const [apiData, setApiData] = useState([]);
  const [titleQuery, setTitleQuery] = useState('');

  const {
    isLoading: apiLoading,
    error: apiError,
    refetch: apiFetch,
  } = useAxiosQuery({
    url: 'https://maps.googleapis.com/maps/api/place/autocomplete/json',
    requestType: 'get',
    preventFetch: true,
    params: {
      key: mapsApiKey,
      types: ['establishment'],
      input: titleQuery,
    },
    onSuccess: (data) => {
      if (data.status === 'OK') {
        setApiData(data.predictions);
      }
    },
  });

  const resetData = () => {
    setTitleQuery('');
    setApiData([]);
  };

  const onSearch = debounce((title) => {
    if (title === '') {
      resetData();
      return;
    }
    if (title?.length > 2) {
      apiFetch();
    }
  }, 100);

  const handleOnSelect = (item) => {
    onChange(item);
    onNext(item);
  };

  useEffect(() => {
    onLoading(apiLoading);
  }, [onLoading, apiLoading]);

  return (
    <Row className="justify-content-lg-center py-10">
      <Col lg="8">
        <div className="position-relative">
          <RequestResult type="error" message={apiError} className="mb-3" />
          <div>
            <InputGroup className="input-group-merge">
              <InputGroup.Text className="input-group-prepend">
                <i className="bi-search" />
              </InputGroup.Text>
              <Form.Control
                placeholder={formatMessage({
                  id: 'app.common.searchProperties',
                })}
                value={titleQuery}
                onChange={(e) => {
                  setTitleQuery(e.target.value);
                  onSearch(e.target.value);
                }}
              />
              {titleQuery.length > 0 && (
                <InputGroup.Text
                  as="button"
                  className="input-group-append"
                  onClick={resetData}
                >
                  <i className="bi-x-lg" />
                </InputGroup.Text>
              )}
            </InputGroup>
          </div>
          <div className="mt-3">
            <RequestLoading loading={apiLoading} size="lg" margin="5" />
            {!apiLoading && !apiError && apiData?.length > 0 && (
              <Card>
                <Card.Body className="p-0">
                  <SimpleBar style={{ maxHeight: 500 }}>
                    <ListGroup variant="flush">
                      {apiData.map((item) => (
                        <ListGroup.Item
                          key={item.place_id}
                          onClick={() => {
                            handleOnSelect(item);
                          }}
                          action
                        >
                          <div className="d-flex w-100 align-items-center justify-content-between">
                            <div>
                              <div className="d-block h5 text-inherit mb-1">
                                {item.structured_formatting?.main_text}
                              </div>
                              <div className="d-block fs-6 text-body">
                                {item.structured_formatting?.secondary_text}
                              </div>
                            </div>
                            {item.place_id === value?.place_id && (
                              <div className="ms-2">
                                <span className="icon icon-xs icon-primary icon-circle">
                                  <i className="bi-check-lg" />
                                </span>
                              </div>
                            )}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </SimpleBar>
                </Card.Body>
              </Card>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}

SelectYourPropertyStep.propTypes = {
  onNext: PropTypes.func,
  onLoading: PropTypes.func,
  value: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};

SelectYourPropertyStep.defaultProps = {
  onNext: () => {},
  onLoading: () => {},
  value: undefined,
  onChange: () => {},
};

export default SelectYourPropertyStep;
