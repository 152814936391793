import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { startCase, toLower } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ShowMoreText, SmartWizard } from '../../../../components';
import {
  WelcomeStep,
  SelectYourPropertyStep,
  SelectYourCompetitorsStep,
  BrandingStep,
  KeywordsStep,
  FinalSaveModal,
} from './partials';
import { AuthContext } from '../../../../context/AuthContext';
import { useBlockerConfirm, useConfirmModal } from '../../../../hooks';
import { PropertyContext } from '../../../../context/PropertyContext';
import { protectedAreaBasePath } from '../../../../router/routeMapping';

function PropertyWizard() {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const wizardRef = useRef();
  const modalRef = useRef();
  const [wizardData, setWizardData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { isActive, onConfirm, resetConfirmation } = useBlockerConfirm(isDirty);
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });

  const {
    pack: {
      usage: { max_competitor: maxCompetitor, max_keywords: maxKeywords },
    },
  } = useContext(AuthContext);

  const { properties, addProperty } = useContext(PropertyContext);

  const onNext = () => {
    wizardRef.current.next();
  };

  const onPrev = () => {
    wizardRef.current.prev();
  };

  const onStepClick = (step) => {
    const wData = { ...wizardData };

    let startIndex = 0;
    if (properties.length === 0) {
      startIndex = 1;
    }
    if (Number(step) < startIndex + 1) {
      delete wData.myProperty;
    }

    if (Number(step) < startIndex + 2) {
      delete wData.myCompetitors;
    }

    if (Number(step) < startIndex + 3) {
      delete wData.branding;
    }

    if (Number(step) < startIndex + 4) {
      delete wData.keywords;
    }

    setWizardData(wData);
  };

  const onKeywordsChange = useCallback((data) => {
    setWizardData((prevData) => ({
      ...prevData,
      keywords: data,
    }));
  }, []);

  const onFormComplete = () => {
    modalRef.current.fetch();
  };

  const onFormSuccess = (data) => {
    setIsDirty(false);
    modalRef.current.close();
    addProperty(data);
    navigate(protectedAreaBasePath, { replace: true });
  };

  const steps = [
    {
      titleKey: 'selectYourProperty',
      size: 12,
      info: wizardData.myProperty?.structured_formatting ? (
        <Card>
          <Card.Body className="p-2 small">
            <div className="text-dark">
              <b>{wizardData.myProperty.structured_formatting.main_text}</b>
            </div>
            {wizardData.myProperty.structured_formatting.secondary_text && (
              <div className="text-muted">
                <ShowMoreText
                  text={
                    wizardData.myProperty.structured_formatting.secondary_text
                  }
                  hideButton
                />
              </div>
            )}
          </Card.Body>
        </Card>
      ) : null,
      Component: (
        <SelectYourPropertyStep
          onLoading={(state) => setLoading(state)}
          onNext={onNext}
          onPrev={onPrev}
          value={wizardData.myProperty || undefined}
          onChange={(data) => {
            setWizardData(() => ({ myProperty: data }));
            setIsDirty(true);
          }}
        />
      ),
    },
    {
      titleKey: 'selectYourCompetitors',
      size: 12,
      info: wizardData.myCompetitors ? (
        <Card>
          <Card.Body className="p-2 small">
            <div className="text-dark">
              <b>
                {`${formatMessage({ id: 'app.common.selectedCompetitors' })}: ${
                  wizardData.myCompetitors.length
                } (${formatMessage(
                  { id: 'app.common.maxN' },
                  { n: maxCompetitor }
                )})`}
              </b>
            </div>
            <div className="text-muted">
              {wizardData.myCompetitors.length > 0 ? (
                <ShowMoreText
                  text={wizardData.myCompetitors
                    .map((item) => `${startCase(toLower(item.name))}`)
                    .join(', ')}
                  hideButton
                />
              ) : (
                <FormattedMessage id="app.common.noCompetitors" />
              )}
            </div>
          </Card.Body>
        </Card>
      ) : null,
      Component: (
        <SelectYourCompetitorsStep
          onLoading={(state) => setLoading(state)}
          placeId={wizardData.myProperty?.place_id}
          types={wizardData.myProperty?.types}
          onNext={(data) => {
            setWizardData((prevData) => ({
              ...prevData,
              myProperty: { ...prevData.myProperty },
              myCompetitors: prevData.myCompetitors || [],
              tempBranding: {
                website: data.website,
                name: prevData.myProperty.structured_formatting.main_text,
              },
            }));
            onNext();
          }}
          onPrev={onPrev}
          value={wizardData.myCompetitors || []}
          onChange={(data) => {
            setWizardData((prevData) => ({ ...prevData, myCompetitors: data }));
          }}
          max={maxCompetitor}
        />
      ),
    },
    {
      titleKey: 'enterBusinessInformation',
      size: 12,
      info: wizardData.branding ? (
        <Card>
          <Card.Body className="p-2 small">
            <div className="text-dark">
              <b>
                <span className="ms-1">{wizardData.branding.name}</span>
              </b>
            </div>
            <div className="text-muted">
              <span className="ms-1">
                {wizardData.branding.website || (
                  <FormattedMessage id="app.common.noWebsite" />
                )}
              </span>
            </div>
          </Card.Body>
        </Card>
      ) : null,
      Component: (
        <BrandingStep
          onLoading={(state) => setLoading(state)}
          // onNext={onNext}
          onPrev={onPrev}
          initialName={wizardData.tempBranding?.name}
          initialWebsite={wizardData.tempBranding?.website}
          // value={wizardData.branding || undefined}
          onNext={(data) => {
            setWizardData((prevData) => ({
              ...prevData,
              branding: {
                ...data,
              },
            }));
            onNext();
          }}
        />
      ),
    },
    {
      titleKey: 'selectKeywords',
      size: 12,
      info: wizardData.keywords ? (
        <Card>
          <Card.Body className="p-2 small">
            <div className="text-dark">
              <b>
                {`${formatMessage({ id: 'app.common.selectedKeywords' })}: ${
                  wizardData.keywords.length
                } (${formatMessage(
                  { id: 'app.common.maxN' },
                  { n: maxCompetitor }
                )})`}
              </b>
            </div>
            <div className="text-muted">
              {wizardData.keywords.length > 0 ? (
                <ShowMoreText
                  text={wizardData.keywords
                    .map((item) => `${startCase(toLower(item.long_tail))}`)
                    .join(', ')}
                  hideButton
                />
              ) : (
                <FormattedMessage id="app.common.noKeywords" />
              )}
            </div>
          </Card.Body>
        </Card>
      ) : null,
      Component: (
        <KeywordsStep
          onLoading={(state) => setLoading(state)}
          placeId={wizardData.myProperty?.place_id}
          placeTypes={wizardData.myProperty?.types}
          max={maxKeywords}
          onChange={onKeywordsChange}
          onNext={(data) => {
            setWizardData((prevData) => ({
              ...prevData,
              keywords: data,
            }));
            onFormComplete();
          }}
          onPrev={onPrev}
        />
      ),
    },
  ];

  if (properties.length === 0) {
    steps.unshift({
      titleKey: 'welcome',
      size: 12,
      Component: <WelcomeStep onNext={onNext} />,
    });
  }

  useEffect(() => {
    const showConfirm = async () => {
      const isSuccess = await confirm({
        title: 'unsavedChanges',
        confirmLabel: 'leavePage',
        message: <FormattedMessage id="app.common.unsavedChangesMessage" />,
      });
      if (isSuccess) {
        onConfirm();
      } else {
        resetConfirmation();
      }
    };
    if (isActive) {
      showConfirm();
    }
  }, [isActive, confirm, onConfirm, resetConfirmation]);

  return (
    <div className="content container">
      <SmartWizard
        ref={wizardRef}
        steps={steps}
        withStepInfo
        renderAll={false}
        disabled={loading}
        onStepClick={onStepClick}
      />
      <FinalSaveModal
        ref={modalRef}
        onSuccess={onFormSuccess}
        params={{}}
        wizardData={wizardData}
      />
    </div>
  );
}

export default PropertyWizard;
