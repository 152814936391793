import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { SmartImage } from '../../../../components';
import Illustration from '../../../../assets/images/svg/illustrations/oc-chatting.svg';
import IllustrationLight from '../../../../assets/images/svg/illustrations-light/oc-chatting.svg';
import { Routes as AppRoutes } from '../../../../router/routeMapping';

function TabPaneItemEmpty({ item, property }) {
  const getSettingsLink = (val) => (
    <Link
      to={AppRoutes.protected.PROPERTY_EDIT.path.replace(
        ':id',
        kebabCase(property.title)
      )}
      className="link-primary"
    >
      {val}
    </Link>
  );

  return (
    <div className="py-10">
      <Row className="justify-content-sm-center text-center">
        <Col sm="6" md="4">
          <SmartImage
            className="img-fluid mb-5"
            src={Illustration}
            darkSrc={IllustrationLight}
            style={{ height: 120 }}
          />
        </Col>
      </Row>
      <Row className="justify-content-sm-center text-center">
        <Col sm="8" md="6">
          <h2 className="mb-4">
            <span className="display-5 me-2">
              <i className={item.icon} />
            </span>
            {item.title}
          </h2>
          <p className="mb-5">
            <FormattedMessage
              id="warnings.social.message"
              values={{
                platform: item.title,
                a: getSettingsLink,
              }}
            />
          </p>
          <Button as="a" href={item.url} target="_blank" rel="nofollow">
            <FormattedMessage
              id="warnings.social.goToPlatformLink"
              values={{
                platform: item.title,
              }}
            />
          </Button>
        </Col>
      </Row>
    </div>
  );
}

TabPaneItemEmpty.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TabPaneItemEmpty;
