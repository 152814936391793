import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import SmartImage from './SmartImage';
import Illustration from '../assets/images/svg/illustrations/oc-work-balance.svg';
import IllustrationLight from '../assets/images/svg/illustrations-light/oc-work-balance.svg';
import Utils from '../utils';

const renewInfoLink = process.env.REACT_APP_RENEW_SUBSCRIPTION_INFO_LINK || '';
const renewLink = process.env.REACT_APP_RENEW_SUBSCRIPTION_LINK || '';

let renewInfoLinkIsExternal = false;
let renewLinkIsExternal = false;

if (Utils.String.checkUrlProtocol(renewInfoLink)) {
  renewInfoLinkIsExternal = true;
}

if (Utils.String.checkUrlProtocol(renewLink)) {
  renewLinkIsExternal = true;
}

function PackageExpiredInfo() {
  return (
    <div className="py-10">
      <Row className="justify-content-sm-center text-center">
        <Col sm="6" md="4">
          <SmartImage
            className="img-fluid mb-5"
            src={Illustration}
            darkSrc={IllustrationLight}
          />
        </Col>
      </Row>
      <Row className="justify-content-sm-center text-center">
        <Col sm="8" md="6">
          <h1>
            <FormattedMessage id="warnings.packageExpiredInfo.title" />
          </h1>
          <p>
            <FormattedMessage id="warnings.packageExpiredInfo.message" />
            {renewInfoLink && (
              <Link
                className="link ms-1"
                to={renewInfoLink}
                target={renewInfoLinkIsExternal ? '_blank' : undefined}
                rel={
                  renewInfoLinkIsExternal ? 'nofollow noreferrer' : undefined
                }
              >
                <FormattedMessage id="app.common.learnMore" />
                <i className="bi-chevron-right ms-1" />
              </Link>
            )}
          </p>
          {renewLink && (
            <Button
              as={Link}
              to={renewLink}
              target={renewLinkIsExternal ? '_blank' : undefined}
              rel={renewLinkIsExternal ? 'nofollow noreferrer' : undefined}
            >
              <FormattedMessage id="app.common.renewYourSubscription" />
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default PackageExpiredInfo;
