import Brands from './brands';

export const Sites = Object.keys(Brands)
  .filter((key) => !key.includes('yahoo'))
  .reduce(
    (obj, key) =>
      Object.assign(obj, {
        [key]: Brands[key],
      }),
    {}
  );

export const Status = {
  0: { id: 0, labelKey: 'unread', color: 'secondary' },
  1: { id: 1, labelKey: 'read', color: 'primary' },
  2: { id: 2, labelKey: 'respond', color: 'success' },
};

export const Types = {
  Positive: {
    id: 'Positive',
    labelKey: 'positive',
    color: 'success',
    hexColor: '#00c9a7',
    icon: 'bi-emoji-smile',
  },
  Neutral: {
    id: 'Neutral',
    labelKey: 'neutral',
    color: 'primary',
    hexColor: '#377dff',
    icon: 'bi-emoji-neutral',
  },
  Negative: {
    id: 'Negative',
    labelKey: 'negative',
    color: 'danger',
    hexColor: '#ed4c78',
    icon: 'bi-emoji-frown',
  },
};
