import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
  Image,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import { QRCodeSVG } from 'qrcode.react';
import { capitalize, lowerCase } from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { toBlob } from 'html-to-image';
import { PropertyContext } from '../../../context/PropertyContext';
import { BrandLogo, Rating, RequestResult } from '../../../components';
import { ReactComponent as NFC } from '../../../assets/images/svg/components/nfc.svg';
import Utils from '../../../utils';

const PRINT_AREA_WIDTH = 148;
const PRINT_AREA_HEIGHT = 210;
const PRINT_AREA_ASPECT_RATIO = Math.floor(
  (PRINT_AREA_HEIGHT / PRINT_AREA_WIDTH) * 100
);

const DARK_BACKGROUND_COLOR = '#17181a';
const LIGHT_BACKGROUND_COLOR = '#FFFFFF';

const DARK_TEXT_COLOR = '#FFFFFF';
const LIGHT_TEXT_COLOR = '#000000';

const pageStyle = `
@media print {
  body {-webkit-print-color-adjust: exact;}
  #printDiv {border-raidus:0;}
}
@page {
  size: ${PRINT_AREA_WIDTH}mm ${PRINT_AREA_HEIGHT}mm;
  margin: 0mm !important;
}`;

const types = [
  {
    labelKey: 'review',
    id: 'PlatformsReview',
    urlKey: 'site_url',
    headingKey: 'reviewUsOn',
    titleKey: 'review.title',
    subtitleKey: 'review.subtitle',
    commentKey: 'review.comment',
  },
  {
    labelKey: 'social',
    id: 'PlatformsSocial',
    urlKey: 'url',
    headingKey: 'followUsOn',
    titleKey: 'social.title',
    subtitleKey: 'social.subtitle',
    commentKey: 'social.comment',
  },
];

function ReviewGenerator() {
  const { activeProperty } = useContext(PropertyContext);

  const { formatMessage } = useIntl();

  // activeProperty.PlatformsReview = [];
  const [activeBrand, setActiveBrand] = useState();
  const [activeType, setActiveType] = useState(types[0]);
  const [activePlatform, setActivePlatform] = useState();
  const [nfcIsEnabled, setNfcIsEnabled] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [comment, setComment] = useState('');
  const [titleIsDirty, setTitleIsDirty] = useState(false);
  const [subtitleIsDirty, setSubtitleIsDirty] = useState(false);
  const [commentIsDirty, setCommentIsDirty] = useState(false);

  const printComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle,
  });

  const handleDownload = async () => {
    const blob = await toBlob(printComponentRef.current);
    const filename = `${formatMessage({
      id: `app.common.${activeType.headingKey}`,
    })} ${activePlatform.site}.png`;
    Utils.Dom.fileDownloadFromData(blob, filename);
  };

  const onChangeBrand = (value) => {
    setActiveBrand(value);
  };

  const onChangePlatform = (value) => {
    setActivePlatform(value);
  };

  const onChangeType = (value) => {
    const aType = types.find((item) => item.id === value);
    setActiveType(aType);
    if (activeProperty[aType.id]?.length > 0) {
      const aPlatform = activeProperty[aType.id][0];
      onChangePlatform(aPlatform);
    } else {
      onChangePlatform(undefined);
    }
  };

  const resetText = useCallback(
    (type) => {
      if (type === 'title') {
        setTitle(
          formatMessage({
            id: `app.helpers.reputationManager.reviewGenerator.defaults.${activeType.titleKey}`,
          })
        );
      } else if (type === 'subtitle') {
        setSubtitle(
          formatMessage({
            id: `app.helpers.reputationManager.reviewGenerator.defaults.${activeType.subtitleKey}`,
          })
        );
      } else if (type === 'comment') {
        setComment(
          formatMessage({
            id: `app.helpers.reputationManager.reviewGenerator.defaults.${activeType.commentKey}`,
          })
        );
      }
    },
    [activeType, formatMessage]
  );

  useEffect(() => {
    if (!titleIsDirty) {
      resetText('title');
    }
    if (!subtitleIsDirty) {
      resetText('subtitle');
    }
    if (!commentIsDirty) {
      resetText('comment');
    }
  }, [resetText, titleIsDirty, subtitleIsDirty, commentIsDirty]);

  useEffect(() => {
    setActivePlatform(
      activeProperty[types[0].id]?.length > 0
        ? activeProperty[types[0].id][0]
        : undefined
    );

    setActiveBrand(
      activeProperty.brands?.length > 0 ? activeProperty.brands[0] : undefined
    );
  }, [activeProperty]);

  return (
    <Row>
      <Col lg="9" className="mb-5 mb-lg-0">
        <Card className="card-lg">
          <Card.Body>
            <Row>
              <Col lg="6">
                <Form>
                  <Row>
                    <Col xs="12" className="mb-4">
                      <Form.Label>
                        <FormattedMessage id="app.common.brands" />
                      </Form.Label>
                      <Select
                        options={activeProperty.brands}
                        getOptionLabel={(option) =>
                          `${capitalize(option.name)}`
                        }
                        getOptionValue={(option) => `${option.id}`}
                        className="react-select-custom-container"
                        classNamePrefix="react-select-custom"
                        isSearchable={false}
                        id={`${activeBrand?.id}_select`}
                        value={activeBrand}
                        onChange={onChangeBrand}
                      />
                    </Col>
                    <Col xs="12" className="mb-4">
                      <Form.Label>
                        <FormattedMessage id="app.common.type" />
                      </Form.Label>
                      <InputGroup className="input-group-sm-vertical">
                        {types.map(({ id, labelKey }) => (
                          <Form.Control key={id} as="label">
                            <Form.Check
                              label={
                                <FormattedMessage
                                  id={`app.common.${labelKey}`}
                                />
                              }
                              type="radio"
                              name="type"
                              id={id}
                              value={id}
                              onChange={(e) => {
                                onChangeType(e.target.value);
                              }}
                              checked={id === activeType.id}
                            />
                          </Form.Control>
                        ))}
                      </InputGroup>
                    </Col>
                    {activeProperty[activeType.id]?.length > 0 ? (
                      <>
                        <Col xs="12" className="mb-4">
                          <Form.Label htmlFor={`${activeType.id}_select`}>
                            <FormattedMessage
                              id="app.common.xPlatforms"
                              values={{
                                x: (
                                  <FormattedMessage
                                    id={`app.common.${activeType.labelKey}`}
                                  />
                                ),
                              }}
                            />
                          </Form.Label>
                          <Select
                            options={activeProperty[activeType.id]}
                            getOptionLabel={(option) =>
                              `${capitalize(option.site)}`
                            }
                            getOptionValue={(option) => `${option.site}`}
                            className="react-select-custom-container"
                            classNamePrefix="react-select-custom"
                            isSearchable={false}
                            id={`${activeType.id}_select`}
                            value={activePlatform}
                            onChange={onChangePlatform}
                          />
                        </Col>
                        <Col xs="12" className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Form.Label htmlFor="title">
                              <FormattedMessage id="app.common.title" />
                            </Form.Label>
                            {titleIsDirty && (
                              <Button
                                variant="link"
                                size="xs"
                                onClick={() => {
                                  resetText('title');
                                  setTitleIsDirty(false);
                                }}
                              >
                                <FormattedMessage id="app.common.returnToDefault" />
                              </Button>
                            )}
                          </div>
                          <InputGroup className="input-group-merge">
                            <Form.Control
                              as="textarea"
                              id="title"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                                setTitleIsDirty(true);
                              }}
                              style={{ resize: 'none' }}
                            />
                            {title.length > 0 && (
                              <InputGroup.Text
                                as="button"
                                className="input-group-append"
                                onClick={() => {
                                  setTitle('');
                                  setTitleIsDirty(true);
                                }}
                              >
                                <i className="bi-x-lg" />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col xs="12" className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Form.Label htmlFor="subtitle">
                              <FormattedMessage id="app.common.subtitle" />
                            </Form.Label>
                            {subtitleIsDirty && (
                              <Button
                                variant="link"
                                size="xs"
                                onClick={() => {
                                  resetText('subtitle');
                                  setSubtitleIsDirty(false);
                                }}
                              >
                                <FormattedMessage id="app.common.returnToDefault" />
                              </Button>
                            )}
                          </div>
                          <InputGroup className="input-group-merge">
                            <Form.Control
                              as="textarea"
                              id="subtitle"
                              value={subtitle}
                              onChange={(e) => {
                                setSubtitle(e.target.value);
                                setSubtitleIsDirty(true);
                              }}
                              style={{ resize: 'none' }}
                            />
                            {subtitle.length > 0 && (
                              <InputGroup.Text
                                as="button"
                                className="input-group-append"
                                onClick={() => {
                                  setSubtitle('');
                                  setSubtitleIsDirty(true);
                                }}
                              >
                                <i className="bi-x-lg" />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col xs="12" className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Form.Label htmlFor="comment">
                              <FormattedMessage id="app.common.comment" />
                            </Form.Label>
                            {commentIsDirty && (
                              <Button
                                variant="link"
                                size="xs"
                                onClick={() => {
                                  resetText('comment');
                                  setCommentIsDirty(false);
                                }}
                              >
                                <FormattedMessage id="app.common.returnToDefault" />
                              </Button>
                            )}
                          </div>
                          <InputGroup className="input-group-merge">
                            <Form.Control
                              as="textarea"
                              id="comment"
                              value={comment}
                              onChange={(e) => {
                                setComment(e.target.value);
                                setCommentIsDirty(true);
                              }}
                              style={{ resize: 'none' }}
                            />
                            {comment.length > 0 && (
                              <InputGroup.Text
                                as="button"
                                className="input-group-append"
                                onClick={() => {
                                  setComment('');
                                  setCommentIsDirty(true);
                                }}
                              >
                                <i className="bi-x-lg" />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col xs="12" className="mb-4">
                          <Form.Check
                            type="switch"
                            id="nfc-switch"
                            label={
                              <FormattedMessage id="app.common.showNfcLogo" />
                            }
                            className="d-flex align-items-center"
                            checked={nfcIsEnabled}
                            onChange={(e) => {
                              setNfcIsEnabled(e.target.checked);
                            }}
                          />
                        </Col>
                        <Col xs="12">
                          <Form.Check
                            type="switch"
                            id="theme-switch"
                            label={
                              <FormattedMessage id="app.common.darkVersion" />
                            }
                            className="d-flex align-items-center"
                            checked={isDark}
                            onChange={(e) => {
                              setIsDark(e.target.checked);
                            }}
                          />
                        </Col>
                      </>
                    ) : (
                      <Col xs="12">
                        <RequestResult
                          type="secondary"
                          message={formatMessage(
                            { id: 'app.common.noXPlatformsProvided' },
                            {
                              x: lowerCase(
                                formatMessage({
                                  id: `app.common.${activeType.labelKey}`,
                                })
                              ),
                            }
                          )}
                        />
                      </Col>
                    )}
                  </Row>
                </Form>
              </Col>
              {activePlatform && (
                <Col lg="6" className="mt-3 mt-lg-0">
                  <div className="d-flex align-items-center justify-content-center rounded overflow-hidden">
                    <div
                      ref={printComponentRef}
                      id="printDiv"
                      className="position-relative"
                      style={{
                        width: '100%',
                        paddingTop: `${PRINT_AREA_ASPECT_RATIO}%`,
                        background: isDark
                          ? DARK_BACKGROUND_COLOR
                          : LIGHT_BACKGROUND_COLOR,
                        color: isDark ? DARK_TEXT_COLOR : LIGHT_TEXT_COLOR,
                      }}
                    >
                      <div className="position-absolute top-0 start-0 bottom-0 end-0">
                        <div className="d-flex flex-column align-items-center justify-content-between h-100 p-5">
                          <div className="mt-3 w-100 text-center">
                            {activeBrand ? (
                              <div className="w-100 text-center">
                                {activeBrand?.logo_url ? (
                                  <Image
                                    src={activeBrand.logo_url}
                                    style={{ height: 140 }}
                                  />
                                ) : (
                                  <h1 className="text-reset text-center">
                                    {activeBrand.name || activeProperty.title}
                                  </h1>
                                )}
                              </div>
                            ) : (
                              <h1 className="text-reset text-center">
                                {activeProperty.title}
                              </h1>
                            )}
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center flex-fill">
                            {title && (
                              <h5
                                className="mt-3 text-reset text-center"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: title.replace(/\n/g, '<br />'),
                                }}
                                style={{ fontSize: '140%' }}
                              />
                            )}

                            {subtitle && (
                              <h6
                                className="mt-3 text-reset text-center"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: subtitle.replace(/\n/g, '<br />'),
                                }}
                                style={{ fontSize: '100%' }}
                              />
                            )}

                            <div className="d-flex mt-3 mb-3">
                              {nfcIsEnabled && (
                                <div
                                  className="me-4"
                                  style={{ width: PRINT_AREA_WIDTH }}
                                >
                                  <NFC
                                    className="w-100"
                                    style={{
                                      fill: isDark
                                        ? DARK_TEXT_COLOR
                                        : LIGHT_TEXT_COLOR,
                                    }}
                                  />
                                </div>
                              )}
                              <div>
                                <QRCodeSVG
                                  value={activePlatform[activeType.urlKey]}
                                  size={PRINT_AREA_WIDTH}
                                />
                              </div>
                            </div>
                            {comment && (
                              <h6
                                className="mt-3 text-reset text-center"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: comment.replace(/\n/g, '<br />'),
                                }}
                                style={{ fontSize: '100%' }}
                              />
                            )}
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <h4
                              className="mt-3 text-reset text-center"
                              style={{ fontSize: '120%' }}
                            >
                              <FormattedMessage
                                id={`app.common.${activeType.headingKey}`}
                              />
                            </h4>
                            <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                              <BrandLogo
                                brand={activePlatform.site}
                                forceFill={isDark ? 'dark' : 'default'}
                                size={PRINT_AREA_WIDTH * 0.5}
                              />
                              {activeType.id === 'PlatformsReview' && (
                                <Rating
                                  initVal={5}
                                  className="mt-2"
                                  iconClassName="fs-1"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col lg="3">
        <div
          className="sticky-lg-top d-grid gap-2 gap-sm-3 mb-2 mb-sm-3"
          style={{ top: 20 }}
        >
          <Button onClick={handlePrint} disabled={!activePlatform}>
            <i className="bi-printer me-1" />
            <FormattedMessage id="app.common.print" />
          </Button>
          <Button
            variant="white"
            onClick={handleDownload}
            disabled={!activePlatform}
          >
            <i className="bi-download me-1" />
            <FormattedMessage id="app.common.download" />
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default ReviewGenerator;
