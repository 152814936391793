import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Button, Modal, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';

function BlacklistCard({ url }) {
  const [showModal, setShowModal] = useState(false);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url,
    requestType: 'get',
    preventFetch: !showModal,
  });

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCheck = () => {
    setShowModal(true);
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <Row className="align-items-center gx-2">
            <Col>
              <Card.Subtitle className="mb-2">
                <FormattedMessage id="app.common.blacklist" />
              </Card.Subtitle>
              <Button variant="primary" size="xs" onClick={handleCheck}>
                <FormattedMessage id="app.common.check" />
              </Button>
            </Col>
            <Col xs="auto">
              <span className="icon icon-circle icon-primary">
                <i className="fs-4 bi-search" />
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={closeModal} scrollable size="lg">
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="app.common.blacklist" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RequestLoading loading={apiLoading} />
          <RequestResult type="error" message={apiError} />

          {!apiLoading && !apiError && apiData && (
            <ListGroup>
              {apiData?.dns?.map((item, index) => (
                <ListGroup.Item
                  key={`dns_${index.toString()}`}
                  className="d-flex flex-column flex-sm-row"
                >
                  <div className="me-auto">
                    <i className="bi-globe text-muted me-2" />
                    {item[0]}
                  </div>
                  {item[1] === 2 ? (
                    <div className="d-flex align-items-center mt-2 mt-sm-0">
                      <span className="legend-indicator bg-success" />
                      <FormattedMessage id="app.common.notListed" />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center mt-2 mt-sm-0">
                      <span className="legend-indicator bg-success" />
                      <FormattedMessage id="app.common.blacklisted" />
                    </div>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={closeModal} variant="white">
            <FormattedMessage id="app.common.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
BlacklistCard.propTypes = {
  url: PropTypes.string.isRequired,
};

export default BlacklistCard;
