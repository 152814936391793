import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Row,
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { withMask } from 'use-mask-input';
import {
  GoogleMap,
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../../../components';
import { useAxiosQuery } from '../../../../hooks';
import Constants from '../../../../constants';

/*
Citylabel [demand_score], [hotness_score]
ZIP
State
*/

const HEIGHT = 500;
const MIN_SEARCH = 5;

function ZipStep({ onNext, onPrev, max }) {
  const [selectedZips, setSelectedZips] = useState([]);

  const mapRef = useRef();

  const { formatMessage } = useIntl();
  const [search, setSearch] = useState('');

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: 'https://qors.io/service/bestplaces/list',
    preventFetch: search.length < MIN_SEARCH,
    params: {
      token:
        'd0c35fa92d936c3a8e256d381acc2018ab39b7b7b307ffb2a386237832cc70536851cf6bcc51e490701ae75c4e41950d3cf35ed3add89a28a9874ada48fb03a7a1003190b17ad4ec053ac42d14db41a7fe7531bfe3e22265142555d6f3131dfe0796769ed88ad957a133dc1b2813b13c5dbd828fe58faafb3762e5e790',
      page: 1,
      quantity: 999999,
      zip: search,
    },
    select: (data) => {
      if (data?.length > 0) {
        return data.map(({ latitude, longitude, ...rest }) => ({
          lat: Number(latitude),
          lng: Number(longitude),
          ...rest,
        }));
      }
      return [];
    },
  });

  const handleItemSelect = (e) => {
    const foundedZip = apiData?.find(
      (item) => item.id.toString() === e.target.value.toString()
    );

    if (!foundedZip) {
      return;
    }

    const added = selectedZips.find(
      (item) => item.id.toString() === e.target.value.toString()
    );

    if (added) {
      const nValue = selectedZips.filter(
        (item) => item.id.toString() !== added.id.toString()
      );
      setSelectedZips([...nValue]);
      return;
    }

    if (selectedZips.length + 1 > max) {
      return;
    }

    setSelectedZips([...selectedZips, foundedZip]);
  };

  const mapMarks = useMemo(() => {
    let fromApiData = [];
    let fromSelected = [];

    if (apiData?.length > 0) {
      fromApiData = apiData
        .filter((item) => selectedZips.find((sItem) => sItem.id !== item.id))
        .map((item) => ({
          ...item,
          color: Constants.Colors.Theme.Danger,
          selected: false,
        }));
    }

    if (selectedZips.length > 0) {
      fromSelected = selectedZips.map((item) => ({
        ...item,
        color: Constants.Colors.Theme.Success,
        selected: true,
      }));
    }

    return [...fromApiData, ...fromSelected];
  }, [apiData, selectedZips]);

  return (
    <Row className="justify-content-sm-center py-10">
      <Col sm="12" className="mb-3">
        <Card>
          <Card.Header className="card-header-content-between align-items-center">
            <div className="d-flex align-items-center pe-2">
              <h6 className="mb-0 ">
                <FormattedPlural
                  value={selectedZips.length}
                  one={<FormattedMessage id="app.common.oneZipSelected" />}
                  other={
                    <FormattedMessage
                      id="app.common.nZipsSelected"
                      values={{ n: selectedZips.length }}
                    />
                  }
                />
              </h6>
              {selectedZips.length > 0 && (
                <Button
                  variant="danger"
                  size="xs"
                  className="rounded-pill ms-2"
                  onClick={() => {
                    setSelectedZips([]);
                  }}
                >
                  <i className="bi-trash me-1" />
                  <FormattedMessage id="app.common.removeAll" />
                </Button>
              )}
            </div>
            {max && (
              <Tooltip
                placement="left"
                content={
                  <FormattedMessage id="app.helpers.zipsSelect.maxZipsInfo" />
                }
              >
                <h6 className="mb-0">
                  <span
                    className={`${
                      selectedZips.length < max ? 'text-success' : 'text-danger'
                    }`}
                  >
                    (
                    <FormattedMessage
                      id="app.common.maxN"
                      values={{ n: max }}
                    />
                    )
                  </span>
                  <i className="bi-question-circle ms-1" />
                </h6>
              </Tooltip>
            )}
          </Card.Header>
          {selectedZips.length > 0 ? (
            <Card.Body>
              {selectedZips.map((item, index) => (
                <Badge
                  pill
                  key={`zip_${index.toString()}`}
                  className="me-3 mb-3 position-relative fs-6 fw-normal"
                  as="div"
                  onMouseEnter={() => {
                    mapRef.current?.focusToItem(item);
                  }}
                  onMouseLeave={() => {
                    mapRef.current?.unfocus();
                  }}
                >
                  {item.citylabel}, <b>{item.zip}</b>, {item.state_code}
                  <Badge bg="dark" className="fw-normal ms-2">
                    <i className="bi-graph-up me-1" />
                    {Math.round(item.demand_score)}
                  </Badge>
                  <Badge bg="dark" className="fw-normal ms-1">
                    <i className="bi-fire me-1" />
                    {Math.round(item.hotness_score)}
                  </Badge>
                  <div className="position-absolute top-0 start-100 translate-middle">
                    <Button
                      variant="danger"
                      size="xs"
                      className="btn-icon rounded-pill"
                      onClick={() => {
                        setSelectedZips((prevData) => [
                          ...prevData.filter((nItem) => nItem.id !== item.id),
                        ]);
                      }}
                    >
                      <i className="bi-x-lg" />
                    </Button>
                  </div>
                </Badge>
              ))}
            </Card.Body>
          ) : (
            <Card.Body className="text-center">
              <span className="text-muted small">
                <FormattedMessage id="app.common.noZipSelected" />
              </span>
            </Card.Body>
          )}
          <Card.Footer className="d-flex justify-content-between">
            <Button variant="ghost-primary" onClick={onPrev}>
              <i className="bi-chevron-left me-1" />
              <FormattedMessage id="app.common.previousStep" />
            </Button>
            <Button
              onClick={() => {
                onNext(selectedZips);
              }}
            >
              <FormattedMessage id="app.common.saveSelectionsAndCreateYourAccount" />
            </Button>
          </Card.Footer>
        </Card>
      </Col>
      <Col lg="3" className="mb-3 mb-lg-0">
        <Card className="h-100">
          <Card.Header>
            <InputGroup className="input-group-merge input-group-hover-light">
              <InputGroup.Text className="input-group-prepend bg-transparent border-end-0">
                <i className="bi-search" />
              </InputGroup.Text>
              <Form.Control
                type="search"
                size="sm"
                placeholder={formatMessage({
                  id: 'app.common.search',
                })}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                ref={withMask('99999', {
                  autoUnmask: true,
                })}
              />
            </InputGroup>
          </Card.Header>
          {search.length >= MIN_SEARCH ? (
            <>
              {(apiLoading ||
                apiError ||
                (!apiLoading && !apiError && apiData?.length === 0)) && (
                <Card.Body>
                  <RequestLoading loading={apiLoading} size="lg" margin="5" />
                  <RequestResult type="error" message={apiError} />

                  {!apiLoading && !apiError && apiData?.length === 0 && (
                    <RequestResult
                      type="secondary"
                      message="app.common.noData"
                    />
                  )}
                </Card.Body>
              )}
              {!apiLoading && !apiError && apiData?.length > 0 && (
                <Card.Body className="p-0 h-100">
                  <SimpleBar style={{ height: HEIGHT }}>
                    <ListGroup variant="flush">
                      {apiData?.map((item) => (
                        <ListGroup.Item
                          key={item.id}
                          onMouseEnter={() => {
                            mapRef.current?.focusToItem(item);
                          }}
                          onMouseLeave={() => {
                            mapRef.current?.unfocus();
                          }}
                          as="label"
                          action
                          htmlFor={`zip_checkbox_${item.id}`}
                        >
                          <div className="d-flex">
                            <div className="me-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item.id}
                                name="zip_checkbox"
                                id={`zip_checkbox_${item.id}`}
                                disabled={
                                  !selectedZips.find(
                                    (nItem) => nItem.id === item.id
                                  ) && selectedZips.length === max
                                }
                                checked={
                                  !!selectedZips.find(
                                    (nItem) => nItem.id === item.id
                                  )
                                }
                                onChange={handleItemSelect}
                              />
                            </div>
                            <div>
                              <div className="d-flex align-items-center h5 text-inherit mb-1">
                                {item.citylabel}
                                <Badge bg="dark" className="fw-normal ms-2">
                                  <i className="bi-graph-up me-1" />
                                  {Math.round(item.demand_score)}
                                </Badge>
                                <Badge bg="dark" className="fw-normal ms-1">
                                  <i className="bi-fire me-1" />
                                  {Math.round(item.hotness_score)}
                                </Badge>
                              </div>
                              <div className="d-block fs-6 text-body">
                                <b>{item.zip}</b>, {item.state_code}
                              </div>
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </SimpleBar>
                </Card.Body>
              )}
            </>
          ) : (
            <Card.Body>
              <div className="h-100 d-flex align-items-center justify-content-center p-5 p-lg-0">
                <RequestResult
                  type="secondary"
                  title="warnings.typeSearch.title"
                  message="warnings.typeSearch.message"
                  image={{ height: 100 }}
                />
              </div>
            </Card.Body>
          )}
        </Card>
      </Col>
      <Col lg="9">
        <GoogleMap
          ref={mapRef}
          height={HEIGHT + 72}
          initialMarks={mapMarks}
          initialCenter={mapMarks.length > 0 ? mapMarks[0] : undefined}
        />
      </Col>
    </Row>
  );
}

ZipStep.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  max: PropTypes.number,
};

ZipStep.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  max: undefined,
};

export default ZipStep;
