export const publicAreaBasePath = '/';
export const protectedAreaBasePath = '/dashboard';
export const authBasePath = '/auth';

/**
 * @description Define supported routes
 */
export const Routes = {
  public: {
    NOT_FOUND: { title: 'notFound', path: '/404', componentName: 'NotFound' },
  },
  auth: {
    LOGIN: {
      title: 'login',
      path: `${authBasePath}/login`,
      componentName: 'Login',
    },
    FORGOT_PASSWORD: {
      title: 'forgotPassword',
      path: `${authBasePath}/forgot-password`,
      componentName: 'ForgotPassword',
    },
    SIGN_UP: {
      title: 'signUp',
      path: `${authBasePath}/sign-up`,
      componentName: 'SignUp',
    },
    VERIFY_EMAIL: {
      title: 'verifyEmail',
      path: `${authBasePath}/verify-email`,
      componentName: 'VerifyEmail',
    },
    VERIFY_CODE: {
      title: 'verifyCode',
      path: `${authBasePath}/verify-code`,
      componentName: 'VerifyCode',
    },
  },
  protected: {
    OVERVIEW: {
      title: 'dashboard',
      icon: 'bi-house-door',
      path: `${protectedAreaBasePath}/overview`,
      componentName: 'Overview',
    },
    ACCOUNT_SETUP: {
      title: 'accountSetup',
      icon: 'bi-house-door',
      path: `${protectedAreaBasePath}/account-setup`,
      componentName: 'AccountSetup',
      hidden: true,
    },
    ACCOUNT: {
      title: 'account',
      icon: 'bi-person',
      path: `${protectedAreaBasePath}/account`,
      componentName: 'Account',
      hidden: true,
      children: {
        ACCOUNT_BILLING: {
          title: 'billing',
          path: `${protectedAreaBasePath}/account/billing`,
          componentName: 'AccountBilling',
        },
      },
    },
    USER: {
      title: 'user',
      icon: 'bi-person',
      path: `${protectedAreaBasePath}/user`,
      componentName: 'User',
      hidden: true,
      children: {
        USER_SETTINGS: {
          title: 'settings',
          path: `${protectedAreaBasePath}/user/settings`,
          componentName: 'UserSettings',
        },
      },
    },
    PROPERTY_FIRST_CREATE: {
      title: 'createFirstProperty',
      icon: 'bi-building-add',
      path: `${protectedAreaBasePath}/property/create-first-property`,
      componentName: 'CreateFirstProperty',
      hidden: true,
    },
    PROPERTY_CREATE: {
      title: 'createProperty',
      icon: 'bi-building-add',
      path: `${protectedAreaBasePath}/property/create`,
      componentName: 'CreateProperty',
      hidden: true,
    },
    PROPERTY_EDIT: {
      title: 'properties',
      icon: 'bi-buildings',
      path: `${protectedAreaBasePath}/property/edit/:id`,
      componentName: 'EditProperty',
      hidden: true,
      children: {
        PROPERTY_EDIT_SETTINGS: {
          title: 'settings',
          path: `${protectedAreaBasePath}/property/edit/:id/settings`,
          componentName: 'EditPropertySettings',
        },
        PROPERTY_EDIT_COMPETITORS: {
          title: 'competitors',
          path: `${protectedAreaBasePath}/property/edit/:id/competitors`,
          componentName: 'EditPropertyCompetitors',
        },
        PROPERTY_EDIT_BRANDS: {
          title: 'brands',
          path: `${protectedAreaBasePath}/property/edit/:id/brands`,
          componentName: 'EditPropertyBrands',
        },
      },
    },
    REPUTATION_MANAGER: {
      title: 'reputationManager',
      icon: 'bi-star',
      path: `${protectedAreaBasePath}/reputation-manager`,
      componentName: 'ReputationManager',
      children: {
        REPUTATION_MANAGER_SUMMARY: {
          title: 'summary',
          path: `${protectedAreaBasePath}/reputation-manager/summary`,
          componentName: 'ReputationManagerSummary',
        },
        REPUTATION_MANAGER_REVIEWS: {
          title: 'reviews',
          path: `${protectedAreaBasePath}/reputation-manager/reviews`,
          componentName: 'ReputationManagerReviews',
        },
        /* REPUTATION_MANAGER_RATINGS: {
          title: 'ratings',
          path: `${protectedAreaBasePath}/reputation-manager/ratings`,
          componentName: 'ReputationManagerRatings',
        }, */
        REPUTATION_MANAGER_KEYWORDS: {
          title: 'keywords',
          path: `${protectedAreaBasePath}/reputation-manager/keywords`,
          componentName: 'ReputationManagerKeywords',
        },
        REPUTATION_MANAGER_COMPETITORS_REPORT: {
          title: 'competitorsReport',
          path: `${protectedAreaBasePath}/reputation-manager/competitors-report`,
          componentName: 'ReputationManagerCompetitorsReport',
        },
        REPUTATION_MANAGER_PREFERENCES: {
          title: 'preferences',
          path: `${protectedAreaBasePath}/reputation-manager/preferences`,
          componentName: 'ReputationManagerPreferences',
        },
        REPUTATION_MANAGER_REVIEW_GENERATOR: {
          title: 'reviewGenerator',
          path: `${protectedAreaBasePath}/reputation-manager/review-generator`,
          componentName: 'ReputationManagerReviewGenerator',
        },
      },
    },
    SEO_MANAGER: {
      title: 'seoManager',
      icon: 'bi-bullseye',
      path: `${protectedAreaBasePath}/seo-manager`,
      componentName: 'SeoManager',
      children: {
        SEO_MANAGER_OVERVIEW: {
          title: 'overview',
          path: `${protectedAreaBasePath}/seo-manager/overview`,
          componentName: 'SeoManagerOverview',
        },
        SEO_MANAGER_VISIBILITY: {
          title: 'visibility',
          path: `${protectedAreaBasePath}/seo-manager/visibility`,
          componentName: 'SeoManagerVisibility',
        },
        SEO_MANAGER_BACKLINKS: {
          title: 'backlinks',
          path: `${protectedAreaBasePath}/seo-manager/backlinks`,
          componentName: 'SeoManagerBacklinks',
        },
        SEO_MANAGER_RECOMMENDATIONS: {
          title: 'recommendations',
          path: `${protectedAreaBasePath}/seo-manager/recommendations`,
          componentName: 'SeoManagerRecommendations',
        },
        SEO_MANAGER_GOOGLE_ANALYTICS: {
          title: 'googleAnalytics',
          path: `${protectedAreaBasePath}/seo-manager/google-analytics`,
          componentName: 'SeoManagerGoogleAnalytics',
        },
        SEO_MANAGER_GOOGLE_SEARCH_CONSOLE: {
          title: 'googleSearchConsole',
          path: `${protectedAreaBasePath}/seo-manager/google-search-console`,
          componentName: 'SeoManagerGoogleSearchConsole',
        },
      },
    },
    SOCIAL_MANAGER: {
      title: 'socialManager',
      icon: 'bi-people',
      path: `${protectedAreaBasePath}/social-manager`,
      componentName: 'SocialManager',
    },
    EVENT_CALENDAR: {
      title: 'eventCalendar',
      icon: 'bi-calendar-event',
      path: `${protectedAreaBasePath}/event-calendar`,
      componentName: 'EventCalendar',
    },
    RATE_MANAGER: {
      title: 'rateManager',
      icon: 'bi-cash-coin',
      path: `${protectedAreaBasePath}/rate-manager`,
      componentName: 'RateManager',
    },
    LOCAL_MANAGER: {
      title: 'localManager',
      icon: 'bi-pin-map',
      path: `${protectedAreaBasePath}/local-manager`,
      componentName: 'LocalManager',
      children: {
        LOCAL_MANAGER_SERP_CHECKER: {
          title: 'serpChecker',
          path: `${protectedAreaBasePath}/local-manager/serp-checker`,
          componentName: 'LocalManagerSerpChecker',
        },
        LOCAL_MANAGER_RANKING_HEATMAP: {
          title: 'rankingHeatmap',
          path: `${protectedAreaBasePath}/local-manager/ranking-heatmap`,
          componentName: 'LocalManagerRankingHeatmap',
        },
        LOCAL_MANAGER_COMPETITOR_AD_MONITOR: {
          title: 'competitorAdMonitor',
          path: `${protectedAreaBasePath}/local-manager/competitor-ad-monitor`,
          componentName: 'LocalManagerCompetitorAdMonitor',
        },
      },
    },
    LISTING_MANAGER: {
      title: 'listingManager',
      icon: 'bi-list-check',
      path: `${protectedAreaBasePath}/listing-manager`,
      componentName: 'ListingManager',
    },
    MESSAGES: {
      title: 'messages',
      icon: 'bi-chat',
      path: `${protectedAreaBasePath}/messages`,
      componentName: 'Messages',
    },
    MESSAGES_DETAIL: {
      title: 'messages',
      icon: 'bi-chat',
      path: `${protectedAreaBasePath}/messages/:id`,
      componentName: 'Messages',
      hidden: true,
    },
    NOTIFICATIONS: {
      title: 'notifications',
      icon: 'bi-bell',
      path: `${protectedAreaBasePath}/notifications`,
      componentName: 'Notifications',
      hidden: true,
    },
    GENERIC_DETAIL: {
      title: 'genericDetail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/generic-detail/:id`,
      componentName: 'GenericDetail',
      hidden: true,
      children: {
        TABLE: {
          title: 'table',
          path: `${protectedAreaBasePath}/generic-detail/:id/table`,
          componentName: 'GenericDetailTable',
        },
        GALLERY: {
          title: 'gallery',
          path: `${protectedAreaBasePath}/generic-detail/:id/gallery`,
          componentName: 'GenericDetailGallery',
        },
        CHART: {
          title: 'chart',
          path: `${protectedAreaBasePath}/generic-detail/:id/chart`,
          componentName: 'GenericDetailChart',
        },
        TIMELINE: {
          title: 'timeline',
          path: `${protectedAreaBasePath}/generic-detail/:id/timeline`,
          componentName: 'GenericDetailTimeline',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/generic-detail/:id/files`,
          componentName: 'GenericDetailFiles',
        },
        FORM: {
          title: 'form',
          path: `${protectedAreaBasePath}/generic-detail/:id/form`,
          componentName: 'GenericDetailForm',
        },
        SERVER_FORM: {
          title: 'serverForm',
          path: `${protectedAreaBasePath}/generic-detail/:id/server-form`,
          componentName: 'GenericDetailServerForm',
        },
      },
    },
  },
};
