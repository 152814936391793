import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, ListGroup, Badge, Button } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { startCase, toLower } from 'lodash';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { useAxiosQuery } from '../../../../../hooks';
import {
  RequestLoading,
  RequestResult,
  Tooltip,
} from '../../../../../components';
import KeywordTable from './KeywordTable';

const HEIGHT = 500;

function KeywordsStep({
  onLoading,
  placeId,
  placeTypes,
  max,
  onNext,
  onPrev,
  onChange,
}) {
  const tableRef = useRef();
  const [activeGroup, setActiveGroup] = useState();
  const [selectedKeywords, setSelectedKeywords] = useState({});
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: `/keyword_placetype/get_by_place_type`,
    preventFetch: !placeId,
    params: { id: placeId, place_type: placeTypes },
    onSuccess: (data) => {
      if (Object.keys(data)?.length > 0) {
        setActiveGroup(Object.keys(data)[0]);
      }
    },
  });

  const tableData = useMemo(
    () => apiData && activeGroup && apiData[activeGroup], // uniqBy(apiData[activeGroup], 'id'),
    [apiData, activeGroup]
  );

  const flatSelectedKeywords = useMemo(
    () => Object.values(selectedKeywords).flatMap((arr) => arr),
    [selectedKeywords]
  );

  const handleSelectKeywords = useCallback(
    (rowSelection) => {
      const selectedItems = tableData.filter((item) =>
        Object.keys(rowSelection).includes(`${item.id}`)
      );
      setSelectedKeywords((prevData) =>
        /* const cur = Object.values(prevData).flatMap((arr) => arr).length;
        if (cur >= max) {
          return prevData;
        } */
        ({
          ...prevData,
          [activeGroup]: selectedItems,
        })
      );
    },
    [tableData, activeGroup]
  );

  useEffect(() => {
    onLoading(apiLoading);
  }, [onLoading, apiLoading]);

  useEffect(() => {
    onChange(flatSelectedKeywords);
  }, [onChange, flatSelectedKeywords]);

  return (
    <Row className="justify-content-sm-center py-10">
      {!apiLoading && !apiError && apiData && (
        <Col sm="12" className="mb-3">
          <Card>
            <Card.Header className="card-header-content-between align-items-center">
              <div className="d-flex align-items-center pe-2">
                <h6 className="mb-0 ">
                  <FormattedPlural
                    value={flatSelectedKeywords.length}
                    one={
                      <FormattedMessage id="app.common.oneKeywordSelected" />
                    }
                    other={
                      <FormattedMessage
                        id="app.common.nKeywordsSelected"
                        values={{ n: flatSelectedKeywords.length }}
                      />
                    }
                  />
                </h6>
                {flatSelectedKeywords.length > 0 && (
                  <Button
                    variant="danger"
                    size="xs"
                    className="rounded-pill ms-2"
                    onClick={() => {
                      tableRef.current?.resetRowSelection();
                      setSelectedKeywords({});
                    }}
                  >
                    <i className="bi-trash me-1" />
                    <FormattedMessage id="app.common.removeAll" />
                  </Button>
                )}
              </div>
              {max && (
                <Tooltip
                  placement="left"
                  content={
                    <FormattedMessage id="app.helpers.propertySelect.keywordsSelect.maxKeywordsInfo" />
                  }
                >
                  <h6 className="mb-0">
                    <span
                      className={`${
                        flatSelectedKeywords.length < max
                          ? 'text-success'
                          : 'text-danger'
                      }`}
                    >
                      (
                      <FormattedMessage
                        id="app.common.maxN"
                        values={{ n: max }}
                      />
                      )
                    </span>
                    <i className="bi-question-circle ms-1" />
                  </h6>
                </Tooltip>
              )}
            </Card.Header>
            {flatSelectedKeywords.length > 0 ? (
              <Card.Body>
                {Object.keys(selectedKeywords).map((key) => (
                  <React.Fragment key={key}>
                    {selectedKeywords[key].map((item, index) => (
                      <Badge
                        pill
                        key={`keyword_${index.toString()}`}
                        className="me-3 mb-3 position-relative fs-6 fw-normal"
                        as="div"
                      >
                        {item.long_tail}
                        <Badge bg="dark" className="fw-normal ms-2">
                          {item.keyword}
                        </Badge>
                        <div className="position-absolute top-0 start-100 translate-middle">
                          <Button
                            variant="danger"
                            size="xs"
                            className="btn-icon rounded-pill"
                            onClick={() => {
                              const selectedItems = selectedKeywords[
                                key
                              ].filter((nItem) => nItem.id !== item.id);
                              setSelectedKeywords((prevData) => ({
                                ...prevData,
                                [key]: selectedItems,
                              }));
                              tableRef.current?.removeRowSelection(item.id);
                            }}
                          >
                            <i className="bi-x-lg" />
                          </Button>
                        </div>
                      </Badge>
                    ))}
                  </React.Fragment>
                ))}
              </Card.Body>
            ) : (
              <Card.Body className="text-center">
                <span className="text-muted small">
                  <FormattedMessage id="app.common.noKeywordSelected" />
                </span>
              </Card.Body>
            )}
            <Card.Footer className="d-flex justify-content-between">
              <Button variant="ghost-primary" onClick={onPrev}>
                <i className="bi-chevron-left me-1" />
                <FormattedMessage id="app.common.previousStep" />
              </Button>
              <Button
                onClick={() => {
                  onNext(flatSelectedKeywords);
                }}
              >
                <FormattedMessage id="app.common.saveSelectionsAndCreateYourProperty" />
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      )}
      <Col xs="12">
        <RequestLoading loading={apiLoading} size="lg" margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading &&
          !apiError &&
          apiData &&
          Object.keys(apiData).length === 0 && (
            <RequestResult type="secondary" message="app.common.noData" />
          )}
      </Col>

      {!apiLoading && !apiError && apiData && Object.keys(apiData).length > 0 && (
        <>
          <Col lg="3" className="mb-3 mb-lg-0">
            <Card className="h-100">
              <Card.Header>
                <Card.Title as="h4" bsPrefix="card-header-title">
                  <FormattedMessage id="app.common.keywordGroups" />
                </Card.Title>
              </Card.Header>
              <Card.Body className="p-0 h-100">
                <SimpleBar style={{ height: HEIGHT }}>
                  <ListGroup variant="flush">
                    {Object.keys(apiData).map((key, kIndex) => (
                      <ListGroup.Item
                        key={`section_${kIndex.toString()}`}
                        active={activeGroup === key}
                        className={`rounded-0 ${
                          activeGroup === key ? '' : 'text-body'
                        }`}
                        onClick={() => {
                          setActiveGroup(key);
                        }}
                        action
                      >
                        {startCase(toLower(key))}
                        {selectedKeywords[key]?.length > 0 && (
                          <Badge bg="dark" pill className="ms-1">
                            {selectedKeywords[key].length}
                          </Badge>
                        )}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </SimpleBar>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="9">
            {activeGroup && (
              <Card className="h-100">
                <Card.Header>
                  <Card.Title as="h4" bsPrefix="card-header-title">
                    {startCase(toLower(activeGroup))}
                  </Card.Title>
                </Card.Header>
                <Card.Body className="p-0 h-100">
                  <KeywordTable
                    ref={tableRef}
                    data={tableData}
                    height={HEIGHT}
                    rowIdKey="id"
                    isSelectAllAllowed={!max}
                    max={max}
                    defaultValue={selectedKeywords[activeGroup]?.reduce(
                      (acc, cur) => {
                        acc[cur.id] = true;
                        return acc;
                      },
                      {}
                    )}
                    onChange={handleSelectKeywords}
                  />
                </Card.Body>
              </Card>
            )}
          </Col>
        </>
      )}
    </Row>
  );
}

KeywordsStep.propTypes = {
  onLoading: PropTypes.func,
  placeId: PropTypes.string,
  placeTypes: PropTypes.arrayOf(PropTypes.string),
  max: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onChange: PropTypes.func,
};

KeywordsStep.defaultProps = {
  onLoading: () => {},
  placeId: undefined,
  placeTypes: [],
  max: undefined,
  onNext: () => {},
  onPrev: () => {},
  onChange: () => {},
};

export default KeywordsStep;
