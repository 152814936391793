import React, { useContext, useState } from 'react';
import { Row, Col, Card, Table, Button, Badge } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import moment from 'moment';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { useAxiosQuery } from '../../../hooks';
import { PropertyContext } from '../../../context/PropertyContext';
import {
  DateRangePicker,
  RequestLoading,
  RequestResult,
} from '../../../components';
import Constants from '../../../constants';

const columns = [
  {
    titleKey: 'location',
    id: 'location_name',
    align: 'text-start',
  },
  {
    titleKey: 'date',
    id: 'date',
  },
];

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

function CompetitorAdMonitor() {
  const { formatMessage } = useIntl();
  const { activeProperty } = useContext(PropertyContext);
  const [date, setDate] = useState(initialDates);
  const [activeItem, setActiveItem] = useState();

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/localrank/list',
    preventFetch: !activeProperty?.id || !date,
    params: {
      id: activeProperty?.id,
      date,
    },
    onSuccess: (data) => {
      if (data.length > 0) {
        setActiveItem(data[0]);
      }
    },
  });

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
  };

  const columnParser = (id, section, row) => {
    const value = section ? row[section][id] : row[id];

    let result;
    switch (id) {
      case 'date':
        result = moment(value).format(Constants.DateFormats.APP.Moment.Common);
        break;

      case 'location_name':
        result = <div>{value || `${row.lat}, ${row.lng}`}</div>;
        break;

      default:
        result = value;
    }

    return result;
  };

  return (
    <div>
      <Row className="mb-5">
        <Col xs="12" md="auto" className="mb-3">
          <div
            className="position-relative w-100 w-md-auto"
            style={{ minWidth: 260, maxWidth: '100%' }}
          >
            <ReactSelect
              options={!apiLoading && !apiError && apiData ? apiData : []}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              value={activeItem}
              className="react-select-custom-container w-100"
              classNamePrefix="react-select-custom"
              isLoading={apiLoading}
              isDisabled={apiLoading || apiError}
              onChange={setActiveItem}
            />
          </div>
        </Col>
        <Col xs="12" md="auto" className="mb-3 ms-md-auto">
          <DateRangePicker
            className="w-100"
            type="button"
            initialSettings={{
              initialDates: {
                startDate: initialDates[0],
                endDate: initialDates[1],
              },
            }}
            value={date}
            onChange={handleChangeDate}
            disabled={apiLoading}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError} />
          {!apiLoading && !apiError && apiData?.length === 0 && (
            <RequestResult
              type="secondary"
              title="warnings.emptyList.title"
              message="warnings.emptyList.message"
              image
            />
          )}
          {!apiLoading && !apiError && apiData?.length > 0 && activeItem && (
            <Row>
              <Col
                xs="12"
                className="d-flex flex-column flex-md-row align-items-start align-items-md-center mb-3"
              >
                <h2 className="h4 mb-md-0">{activeItem.name} </h2>
                {activeItem.web_site && (
                  <Button
                    variant="secondary"
                    size="xs"
                    as={Link}
                    to={activeItem.web_site}
                    target="_blank"
                    rel="noreferrer nofollow"
                    className="ms-md-2"
                  >
                    <i className="bi-box-arrow-up-right me-1" />
                    {activeItem.web_site}
                  </Button>
                )}
              </Col>
              {activeItem.keywords.length === 0 ? (
                <RequestResult type="secondary" message="app.common.noData" />
              ) : (
                <Col xs="12">
                  <Card>
                    {activeItem.keywords.map((item, kIndex) => (
                      <div
                        key={`keyword_${kIndex.toString()}`}
                        className={
                          kIndex < activeItem.keywords.length - 1
                            ? 'mb-3 mb-lg-5'
                            : ''
                        }
                      >
                        <Card.Header>
                          <Card.Title bsPrefix="card-header-title" as="h4">
                            {capitalize(item.keyword)}
                            <Badge className="ms-1">
                              <span className="me-1">
                                {formatMessage({
                                  id: 'app.common.costPerClick',
                                })?.replace(/[^A-Z]/g, '')}
                                :
                              </span>
                              <FormattedNumber
                                value={1}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                                currency="USD"
                              />
                            </Badge>
                          </Card.Title>
                        </Card.Header>
                        <Table
                          responsive
                          borderless
                          className="table-thead-bordered table-nowrap table-align-middle card-table"
                        >
                          <thead className="thead-light">
                            <tr>
                              {columns.map(({ id, titleKey, align }) => (
                                <th key={id} className={align || 'text-end'}>
                                  <FormattedMessage
                                    id={`app.common.${titleKey}`}
                                  />
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {item.locations.map((row, rowIndex) => (
                              <tr key={`row_${rowIndex.toString()}`}>
                                {columns.map(
                                  (
                                    { id, section, disableNullControl, align },
                                    index
                                  ) => (
                                    <td
                                      key={`row_${rowIndex.toString()}_col_${id}_${index.toString()}`}
                                      className={align || 'text-end'}
                                    >
                                      {columnParser(
                                        id,
                                        section,
                                        row,
                                        false,
                                        disableNullControl
                                      )}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    ))}
                  </Card>
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default CompetitorAdMonitor;
