import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, ListGroup, Image } from 'react-bootstrap';
import placeholderImg from '../../../../assets/images/placeholder.png';

function PropertyListCard({ title, data }) {
  const { formatMessage } = useIntl();

  const onImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const getContent = (item) => {
    switch (item.type) {
      case 'link':
        return item.value ? (
          <a href={item.value} target="_blank" rel="noreferrer">
            {item.value}
          </a>
        ) : (
          formatMessage({ id: 'app.common.n/a' })
        );

      case 'image':
        return (
          <Image
            src={item.value || placeholderImg}
            style={{
              objectFit: 'contain',
              width: '100%',
            }}
            rounded
            onError={onImageError}
          />
        );

      case 'email':
        return item.value ? (
          <a href={`mailto:${item.value}`}>{item.value}</a>
        ) : (
          formatMessage({ id: 'app.common.n/a' })
        );

      case 'bool':
        return item.value === 1
          ? formatMessage({ id: 'app.common.yes' })
          : formatMessage({ id: 'app.common.no' });

      default:
        return item.value &&
          item.value !== 'Not Available' &&
          item.value !== '0'
          ? item.value
          : formatMessage({ id: 'app.common.n/a' });
    }
  };

  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title bsPrefix="card-header-title" as="h4">
          {title}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <ListGroup variant="flush">
          {data.map((item) => (
            <ListGroup.Item key={item.key} className="list-group-item">
              <div className="fw-bold mb-2">
                {item.value && item.value !== 'Not Available' ? (
                  <i className="bi-check-circle me-2 text-success" />
                ) : (
                  <i className="bi-x-circle me-2 text-danger" />
                )}
                <FormattedMessage
                  id={`app.common.${item.title}`}
                  defaultMessage={item.title}
                />
              </div>
              {getContent(item)}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

PropertyListCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.oneOf(['text', 'link', 'email', 'image', 'bool']),
    })
  ).isRequired,
};

export default PropertyListCard;
