import React, { useState, useRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
// import { useWindowScroll, useWindowSize } from 'react-use';
import {
  Col,
  Row,
  Button,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Popover,
} from 'react-bootstrap';
import ReactSelect, { components } from 'react-select';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import trLocale from '@fullcalendar/core/locales/tr';
import moment from 'moment';
import {
  PageHeader,
  Nouislider,
  GoogleMap,
  ShowMoreText,
  RequestResult,
} from '../../../components';
import { LangContext } from '../../../context/LangContext';
import { PropertyContext } from '../../../context/PropertyContext';
import Constants from '../../../constants';
import { useAxiosQuery } from '../../../hooks';
import Utils from '../../../utils';

const locales = {
  tr: trLocale,
};

/* const UpdatingPopover = forwardRef(
  ({ popper, children, show: _, ...props }, ref) => {
    useEffect(() => {
      console.log('updating!');
      popper.scheduleUpdate();
    }, [children, popper]);

    return (
      <Popover ref={ref} body {...props}>
        {children}
      </Popover>
    );
  }
);

UpdatingPopover.propTypes = {
  popper: PropTypes.any.isRequired, //eslint-disable-line
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
}; */

const MIN_RADIUS = 25;
const MAX_RADIUS = 300;

function Option({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...props
}) {
  return (
    <components.Option
      {...props}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
    >
      <div className="d-flex justify-content-between">
        {children}
        {isSelected && <i className="bi bi-check2" />}
      </div>
    </components.Option>
  );
}

Option.propTypes = {
  getStyles: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  innerProps: PropTypes.any.isRequired,
};

function ValueContainer({ children, ...props }) {
  return (
    <components.ValueContainer {...props}>
      {Array.isArray(children[0]) && children[0].length > 0 ? (
        <div className="position-absolute text-nowrap">
          <FormattedPlural
            value={children[0].length}
            one={<FormattedMessage id="app.common.oneItemSelected" />}
            other={
              <FormattedMessage
                id="app.common.nItemsSelected"
                values={{ n: children[0].length }}
              />
            }
          />
        </div>
      ) : (
        children[0]
      )}
      {children[1]}
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.any),
    ])
  ),
};

ValueContainer.defaultProps = {
  children: [],
};

function CategorySelect({ onChange, startDate, endDate }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/events/listCategories',
    preventFetch: !startDate && !endDate,
    params: {
      quantity: 9999,
      dates: [
        moment(startDate).format(Constants.DateFormats.API),
        moment(endDate).format(Constants.DateFormats.API),
      ],
    },
  });

  return (
    <div
      className="position-relative"
      style={{
        zIndex: 12,
      }}
    >
      <ReactSelect
        options={!apiLoading && !apiError && apiData ? apiData : []}
        getOptionLabel={(option) => `${option.title}`}
        getOptionValue={(option) => `${option.id}`}
        /* value={[options.find((opt) => opt.value === currentLanguage)]}
      onChange={({ value }) => {
        console.log(value);
      }} */
        // formatOptionLabel={formatOptionLabel}
        className="react-select-custom-container"
        classNamePrefix="react-select-custom"
        components={{ ValueContainer, Option }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable
        isMulti
        isSearchable={false}
        isLoading={apiLoading}
        isDisabled={apiLoading || apiError}
        onChange={onChange}
      />
    </div>
  );
}

CategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

CategorySelect.defaultProps = {
  startDate: null,
  endDate: null,
};

function EventCalendar() {
  const { formatMessage } = useIntl();

  const calendarRef = useRef();

  const views = [
    {
      label: formatMessage({ id: 'app.common.month' }),
      value: 'dayGridMonth',
    },
    {
      label: formatMessage({ id: 'app.common.week' }),
      value: 'timeGridWeek',
    },
    {
      label: formatMessage({ id: 'app.common.day' }),
      value: 'timeGridDay',
    },
    {
      label: formatMessage({ id: 'app.common.list' }),
      value: 'listWeek',
    },
  ];
  const [activeView, setActiveView] = useState(views[0]);

  // const windowScroll = useWindowScroll();
  // const windowSize = useWindowSize();

  const [showEventDetail, setShowEventDetail] = useState(false);
  const [targetEvent, setTargetEvent] = useState(null);
  const [activeEventData, setActiveEventData] = useState(null);

  const [currentMonthTitle, setCurrentMonthTitle] = useState('');

  const { activeProperty } = useContext(PropertyContext);
  const { currentLanguage } = useContext(LangContext);

  const [currentStartDate, setCurrentStartDate] = useState();
  const [currentEndDate, setCurrentEndDate] = useState();
  const [currentRadius, setCurrentRadius] = useState(MIN_RADIUS);
  const [currentCategories, setCurrentCategories] = useState([]);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/events/list',
    preventFetch: !currentStartDate && !currentEndDate,
    params: {
      quantity: 500,
      startDate: [
        moment(currentStartDate).format(Constants.DateFormats.API),
        moment(currentEndDate).format(Constants.DateFormats.API),
      ],
      latitude: activeProperty?.latitude,
      longitude: activeProperty?.longitude,
      radius: currentRadius,
      category: currentCategories,
    },
    select: (data) => {
      const formatted = data.map((item) => {
        const color = Utils.Color.getShadeHexColorFromHex(
          Constants.Colors.Palette[0],
          160
        );

        /* console.log(item);

        console.log(
          moment(`${item.startDate}T${item.startTime}${item.startTimeFormat}`)
        );

        console.log(
          moment(
            `${item.startDate} ${item.startTime} ${item.startTimeFormat}`
          ).format('DD/MM/yyyy hh:mm a')
        ); */

        return {
          id: item.id,
          title: item.title,
          start: `${item.startDate} ${item.startTime}`,
          end: `${item.endDate} ${item.endTime}`,
          // className: 'bg-primary',
          backgroundColor: color,
          // textColor: Constants.Colors.Palette[0],
          eventDescription: item.description,
          eventLocation: item.locationName,
          eventAddress: item.address,
          eventCategory: item.category,
          eventDistance: item.distance,
          eventLatitude: item.latitude || 34.1415271,
          eventLongitude: item.longitude || -118.3723111,
          // allDay: true,
          // guestsField: ['David Harrison'],
          // image: './assets/svg/brands/pdf-icon.svg',
        };
      });

      return formatted;
    },
  });

  const calendarWrapperRef = useCallback((node) => {
    if (node !== null) {
      const calendarElem = node.querySelector('.fc');
      if (calendarElem) {
        calendarElem.classList?.add('fullcalendar-custom');
      }
    }
  }, []);

  const handleEventDetail = (eventClickInfo) => {
    setActiveEventData(eventClickInfo.event);
    setTargetEvent(eventClickInfo.el);
    setShowEventDetail(true);
  };

  /* const getAvatars = (members) => {
    if (members?.length > 0) {
      return (
        <ul className="list-unstyled mb-0">
          <li className="d-flex align-items-center mb-2">
            <span>
              {members[0].src ? (
                <img
                  className="avatar avatar-xs avatar-circle me-2"
                  alt={members[0]}
                  src={members[0].src}
                />
              ) : (
                <div className="avatar avatar-xs avatar-soft-primary avatar-circle me-2">
                  <span className="avatar-initials">
                    {members[0].charAt(0)}
                  </span>
                </div>
              )}
            </span>
          </li>
        </ul>
      );
    }
    return <FormattedMessage id="app.common.n/a" />;
  }; */

  const renderEventContent = (eventInfo) => (
    <div>
      <div className="fc-event-time">
        {eventInfo.view.type === 'timeGridWeek' && !eventInfo.event.allDay
          ? `${moment(eventInfo.event.start).format('HH:mm')}-${moment(
              eventInfo.event.end
            ).format('HH:mm')}`
          : ''}
      </div>
      <div className="d-flex">
        {eventInfo.event.extendedProps.image ? (
          <img
            className="avatar avatar-xs me-2"
            src={eventInfo.event.extendedProps.image}
            alt="Description"
          />
        ) : (
          ''
        )}
        <span
          // className="fc-event-title fc-sticky"
          className="fc-event-title"
          style={eventInfo.textColor ? { color: eventInfo.textColor } : {}}
        >
          {eventInfo.event.title}
        </span>
      </div>
    </div>
  );

  const sliderFormatter = (tooltip) => {
    // const formatter = (v) => tooltip ? getHumanReadableMinutes(Math.round(v)) : Math.round(v);

    const formatter = (v) =>
      tooltip
        ? `${Math.round(v)} ${formatMessage({ id: 'app.common.miles' })}`
        : Math.round(v);

    return {
      to(value) {
        return formatter(value);
      },
      from(value) {
        return formatter(value);
      },
    };
  };

  /* useEffect(() => {
    setShowEventDetail(false);
  }, [windowScroll, windowSize]); */

  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="eventCalendar"
        breadcrumbData={{
          current: 'eventCalendar',
        }}
      >
        <Row className="align-items-sm-center">
          <Col sm="auto" className="mb-2 mb-sm-0">
            <Row className="align-items-center gx-0">
              <Col>
                <span className="text-secondary me-2">
                  <FormattedMessage id="app.common.radius" />:
                </span>
              </Col>
              <Col xs="auto">
                <div className="range-slider" style={{ width: 200 }}>
                  <Nouislider
                    className="range-slider-ui"
                    range={{
                      min: MIN_RADIUS,
                      '20%': [100, 10],
                      '70%': [200, 25],
                      max: MAX_RADIUS,
                    }}
                    start={currentRadius}
                    step={5}
                    tooltips={[sliderFormatter(true)]}
                    format={sliderFormatter()}
                    onChange={(val) => {
                      setCurrentRadius(val[0]);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm="auto" className="mb-2 mb-sm-0">
            <Row className="align-items-center gx-0">
              <Col>
                <span className="text-secondary me-2">
                  <FormattedMessage id="app.common.categories" />:
                </span>
              </Col>
              <Col xs="auto">
                <div style={{ width: 200 }}>
                  <CategorySelect
                    onChange={(val) => {
                      setCurrentCategories(val.map((item) => item.title));
                    }}
                    startDate={currentStartDate}
                    endDate={currentEndDate}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageHeader>

      <div className="position-relative">
        {apiLoading && (
          <div>
            <div
              className="position-absolute bg-dark opacity-75 rounded"
              style={{
                left: '-15px',
                right: '-15px',
                top: '-15px',
                bottom: '-15px',
                zIndex: 10,
              }}
            />
            <div
              className="position-absolute w-100 h-100 d-flex justify-content-center"
              style={{
                zIndex: 11,
              }}
            >
              <div className="w-50 text-center mt-5">
                <Spinner animation="border" variant="white" />
              </div>
            </div>
          </div>
        )}

        <RequestResult type="error" message={apiError} className="mb-5" />

        <Row className="align-items-sm-center mb-4">
          <Col lg="5" className="mb-2 mb-lg-0">
            <div className="d-flex align-items-center">
              <Button
                variant="white"
                className="me-3"
                onClick={() => {
                  calendarRef.current.getApi().today();
                }}
              >
                <FormattedMessage id="app.common.today" />
              </Button>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <FormattedMessage id="app.common.previousMonth" />
                  </Tooltip>
                }
              >
                <Button
                  variant="ghost-secondary"
                  size="sm"
                  className="btn-icon btn-no-focus rounded-circle me-1"
                  onClick={() => {
                    calendarRef.current.getApi().prev();
                  }}
                >
                  <i className="bi-chevron-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <FormattedMessage id="app.common.nextMonth" />
                  </Tooltip>
                }
              >
                <Button
                  variant="ghost-secondary"
                  size="sm"
                  className="btn-icon btn-no-focus rounded-circle ms-1"
                  onClick={() => {
                    calendarRef.current.getApi().next();
                  }}
                >
                  <i className="bi-chevron-right" />
                </Button>
              </OverlayTrigger>
              <div className="ms-3">
                <h4 className="h3 mb-0">{currentMonthTitle}</h4>
              </div>
            </div>
          </Col>
          <Col lg="7">
            <div className="d-sm-flex align-items-sm-center justify-content-end">
              <div className="d-flex align-items-center">
                <div
                  className="position-relative"
                  style={{
                    zIndex: 9,
                  }}
                >
                  <ReactSelect
                    options={views}
                    className="react-select-custom-container"
                    classNamePrefix="react-select-custom"
                    isClearable={false}
                    isSearchable={false}
                    value={activeView}
                    onChange={(option) => {
                      setActiveView(option);
                      calendarRef.current.getApi().changeView(option.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div ref={calendarWrapperRef} id="fc-wrapper">
          <FullCalendar
            locale={currentLanguage !== 'en' && locales[currentLanguage]}
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
            views={{
              timeGridDay: {
                type: 'timeGrid',
                duration: { days: 1 },
              },
            }}
            headerToolbar={false}
            defaultAllDay={false}
            editable
            contentHeight="auto"
            dayMaxEventRows={2}
            displayEventTime
            eventDisplay="block"
            eventContent={renderEventContent}
            datesSet={(dateSet) => {
              setCurrentStartDate(dateSet.start);
              setCurrentEndDate(dateSet.end);
              setCurrentMonthTitle(dateSet.view.title);
            }}
            eventClick={(eventClickInfo) => {
              setShowEventDetail(false);
              setTimeout(() => {
                handleEventDetail(eventClickInfo);
              }, 100);
            }}
            events={apiData}
          />
        </div>

        <Overlay
          show={showEventDetail}
          onHide={() => {
            setShowEventDetail(false);
            setActiveEventData(null);
          }}
          target={targetEvent}
          placement="auto"
          flip
          rootClose
          container={() => document.getElementById('fc-wrapper')}
        >
          <Popover
            id="popover-contained"
            className="fullcalendar-event-popover"
            style={{
              zIndex: 99,
            }}
          >
            <Popover.Body>
              {activeEventData && (
                <>
                  <h3 className="mb-4">{activeEventData.title}</h3>

                  <div className="d-flex mb-4">
                    <i className="bi bi-clock nav-icon" />
                    <div className="flex-grow-1">
                      <span className="d-block text-dark mb-2">
                        {`${
                          activeEventData.start
                            ? moment(activeEventData.start).format(
                                Constants.DateFormats.APP.Moment.CommonWithTime
                              )
                            : ''
                        } ${
                          activeEventData.end
                            ? `- ${moment(activeEventData.end).format(
                                Constants.DateFormats.APP.Moment.CommonWithTime
                              )}`
                            : ''
                        }`}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex mb-4">
                    <i className="bi bi-tag nav-icon" />
                    <div className="flex-grow-1">
                      <span className="d-block text-dark">
                        {activeEventData.extendedProps.eventCategory ? (
                          activeEventData.extendedProps.eventCategory
                        ) : (
                          <FormattedMessage id="app.common.n/a" />
                        )}
                      </span>
                    </div>
                  </div>

                  {activeEventData.extendedProps.eventDescription && (
                    <div className="d-flex mb-4">
                      <i className="bi bi-text-left nav-icon" />
                      <div className="flex-grow-1">
                        <span className="d-block text-dark">
                          <ShowMoreText
                            text={
                              activeEventData.extendedProps.eventDescription
                            }
                            maxLine={2}
                            basedOn="words"
                            hideButton
                          />
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="d-flex mb-4">
                    <i className="bi bi-pin-map nav-icon" />
                    <div className="flex-grow-1">
                      <span className="d-block text-dark">
                        {activeEventData.extendedProps.eventLocation}
                        {` (${Math.round(
                          activeEventData.extendedProps.eventDistance
                        )} ${formatMessage({ id: 'app.common.miles' })})`}
                        <br />
                        {activeEventData.extendedProps.eventAddress || (
                          <FormattedMessage id="app.common.n/a" />
                        )}
                      </span>
                    </div>
                  </div>

                  {activeEventData.extendedProps.eventLatitude &&
                    activeEventData.extendedProps.eventLongitude && (
                      <div className="d-flex align-items-center mb-4">
                        <GoogleMap
                          initialMarks={[
                            {
                              lat: Number(
                                activeEventData.extendedProps.eventLatitude
                              ),
                              lng: Number(
                                activeEventData.extendedProps.eventLongitude
                              ),
                            },
                          ]}
                        />
                      </div>
                    )}

                  <div className="d-flex justify-content-end">
                    <Button
                      variant="white"
                      size="sm"
                      className="me-2"
                      onClick={() => {
                        setShowEventDetail(false);
                        setActiveEventData(null);
                      }}
                    >
                      <FormattedMessage id="app.common.close" />
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        setShowEventDetail(false);
                        setActiveEventData(null);
                      }}
                    >
                      <i className="bi bi-calendar2-plus me-2" />
                      <FormattedMessage id="app.common.addToMyAgenda" />
                    </Button>
                  </div>
                </>
              )}
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </div>
  );
}

export default EventCalendar;
