import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useAxiosInfiniteQuery } from '../../../../../hooks';
import { GoogleMap, RequestResult } from '../../../../../components';
import PropertyList from './PropertyList';
import Constants from '../../../../../constants';

const RADIUS_MIN = 1;
const RADIUS_MAX = 30;
const RADIUS_STEP = 1;
const RADIUS_DEFAULT = 10;
const MILES_TO_METERS = 1609.344;

function SelectYourCompetitorsStep({
  onNext,
  onPrev,
  onLoading,
  placeId,
  types,
  value,
  onChange,
  max,
}) {
  const { formatMessage } = useIntl();
  const mapRef = useRef();
  const [radius, setRadius] = useState(RADIUS_DEFAULT);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useAxiosInfiniteQuery({
    url: '/places/get_nearby',
    preventFetch: !placeId || !radius,
    params: {
      placeid: placeId,
      types,
      radius: radius * MILES_TO_METERS,
    },
    cursor: 'next_page_token',
    cursorDefault: undefined,
    getNextPageParam: (lastPage) => lastPage.next_page_token,
    select: (data) => {
      let iconIndex = 0;
      const nResult = [];
      data.pages.forEach((page) =>
        page.res.forEach(({ lat, lng, place_id: placeid, ...rest }) => {
          if (placeid !== placeId) {
            iconIndex += 1;
          }

          nResult.push({
            lat: Number(lat),
            lng: Number(lng),
            color:
              placeid === placeId ? Constants.Colors.Theme.Primary : undefined,
            iconText: placeid === placeId ? '&#9733;' : `${iconIndex}`,
            mapIndex: placeid !== placeId && iconIndex,
            place_id: placeid,
            ...rest,
          });
        })
      );

      return nResult;
    },
  });

  const getMyProperty = useCallback(
    (data) => data?.find((item) => item.place_id === placeId),
    [placeId]
  );

  const getCompetitors = useCallback(
    (data) => (data ? data.filter((item) => item.place_id !== placeId) : []),
    [placeId]
  );

  const getInitialMarks = useCallback(
    (data) => {
      if (!data) {
        return [];
      }
      const r = [
        ...getCompetitors(data).map((item) =>
          value.find((sItem) => sItem.place_id === item.place_id)
            ? { ...item, color: Constants.Colors.Theme.Success, selected: true }
            : { ...item, color: Constants.Colors.Theme.Danger, selected: false }
        ),
      ];

      const myProp = getMyProperty(data);
      if (myProp) {
        r.unshift(myProp);
      }

      return r;
    },
    [getCompetitors, getMyProperty, value]
  );

  const sliderFormatter = (tooltip) => {
    const formatter = (v) =>
      tooltip
        ? `${Math.round(v)} ${formatMessage({ id: 'app.common.miles' })}`
        : Math.round(v);

    return {
      to(v) {
        return formatter(v);
      },
      from(v) {
        return formatter(v);
      },
    };
  };

  const handleRadiusChange = (r) => {
    onChange([]);
    setRadius(r);
  };

  useEffect(() => {
    onLoading(apiLoading);
  }, [onLoading, apiLoading]);

  return (
    <Row className="py-10">
      <Col xs="12">
        <RequestResult type="error" message={apiError} className="mb-5" />

        <Row>
          <Col lg="4" className="mb-3 mb-lg-0">
            <PropertyList
              data={getCompetitors(apiData)}
              multiple
              value={value}
              onChange={onChange}
              onMouseEnter={(item) => {
                mapRef.current?.focusToItem(item);
              }}
              onMouseLeave={() => {
                mapRef.current?.unfocus();
              }}
              onPrev={onPrev}
              onNext={() => {
                onNext(getMyProperty(apiData));
              }}
              hasMore={hasNextPage}
              onLoadMore={() => {
                fetchNextPage();
              }}
              max={max}
              radius={{
                min: RADIUS_MIN,
                max: RADIUS_MAX,
                step: RADIUS_STEP,
                value: radius,
                onChange: handleRadiusChange,
                formatter: sliderFormatter,
              }}
              isLoading={apiLoading}
              isNextPageLoading={isFetchingNextPage}
            />
          </Col>
          <Col lg="8">
            <GoogleMap
              ref={mapRef}
              height={536}
              // initialZoom={16 - Math.log(radius / 500) / Math.log(2)}
              // initialZoom={14}
              initialCenter={getInitialMarks(apiData)?.[0]}
              initialMarks={getInitialMarks(apiData)}
              withCircle={radius * MILES_TO_METERS}
              circleOnChange={(r) => {
                let fr = Math.round(r / MILES_TO_METERS);
                if (fr < RADIUS_MIN) {
                  fr = RADIUS_MIN;
                } else if (fr > RADIUS_MAX) {
                  fr = RADIUS_MAX;
                }
                handleRadiusChange(fr);
              }}
              isLoading={apiLoading}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

SelectYourCompetitorsStep.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onLoading: PropTypes.func,
  placeId: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChange: PropTypes.func,
  max: PropTypes.number,
};

SelectYourCompetitorsStep.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  onLoading: () => {},
  placeId: undefined,
  types: [],
  value: [],
  onChange: () => {},
  max: undefined,
};

export default SelectYourCompetitorsStep;
