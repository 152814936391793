import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Table, Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { findAll } from 'highlight-words-core';
import { capitalize, lowerCase } from 'lodash';
import { useAxiosQuery } from '../../../hooks';
import { PropertyContext } from '../../../context/PropertyContext';
import {
  CountryFlag,
  DateRangePicker,
  Rating,
  RequestLoading,
  RequestResult,
  ShowMoreText,
} from '../../../components';
import Constants from '../../../constants';
import { ThemeContext } from '../../../context/ThemeContext';
import Utils from '../../../utils';
import { LineChart } from '../../../components/Charts';
import { languages } from '../../../context/LangContext';

const columns = [
  {
    id: 'val',
    align: 'text-start',
  },
  {
    id: 'type',
  },
  { id: 'direction' },
  { id: 'mention' },
];

const options = {
  scales: {
    x: {
      display: false,
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: Constants.DateFormats.APP.Moment.Common,
        },
      },
    },
    y: { display: false },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: { displayColors: false },
  },
};

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

function Chart({ loading, word, propertyId, date }) {
  const { theme } = useContext(ThemeContext);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/avg_word_usage',
    preventFetch: !propertyId || !date || !word,
    params: {
      word,
      id: propertyId,
      date,
    },
  });

  const getDataset = (data) => {
    const dates = Utils.Date.getDaysBetweenDates(date[0], date[1]).map((item) =>
      moment(item).format(Constants.DateFormats.API)
    );

    return {
      labels: dates,
      datasets: [
        {
          data: data.list.map((x) => x.toFixed(2)),
          label: word,
          ...Utils.Chart.getLineChartOptions(0, theme, {
            backgroundColor: 'transparent',
            usePointStyle: false,
            borderColor: (context) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 400);
              gradient.addColorStop(0, Utils.Chart.successColor);
              gradient.addColorStop(0.6, Utils.Chart.dangerColor);
              return gradient;
            },
          }),
        },
      ],
    };
  };

  if (loading) {
    return (
      <Card.Body>
        <RequestLoading loading={loading} margin="5" />
      </Card.Body>
    );
  }

  return (
    <Card.Body>
      <RequestLoading loading={apiLoading} margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && apiData?.list.length === 0 && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {!apiLoading && !apiError && apiData?.list.length > 0 && (
        <div className="d-flex flex-column">
          <div className="text-success small">
            <FormattedMessage id="app.common.positive" />
          </div>
          <hr className="my-3" />
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <LineChart
              data={getDataset(apiData)}
              options={options}
              height="15rem"
            />
          </div>
          <hr className="my-3" />
          <div className="text-danger small">
            <FormattedMessage id="app.common.negative" />
          </div>
        </div>
      )}
    </Card.Body>
  );
}

Chart.propTypes = {
  loading: PropTypes.bool,
  word: PropTypes.string,
  propertyId: PropTypes.number,
  date: PropTypes.arrayOf(PropTypes.string),
};

Chart.defaultProps = {
  loading: false,
  word: '',
  propertyId: null,
  date: null,
};

function Sentiment({ loading, data, word }) {
  if (loading) {
    return (
      <Card.Body>
        <RequestLoading loading={loading} margin="5" />
      </Card.Body>
    );
  }

  const mentionDiff =
    data && Object.keys(data).length > 0
      ? data[1].nums.mention - data[0].nums.mention
      : 0;
  let mentionDir = null;
  let mentionColor = null;
  if (mentionDiff > 0) {
    mentionDir = 'up';
    mentionColor = 'success';
  } else if (mentionDiff < 0) {
    mentionDir = 'down';
    mentionColor = 'danger';
  }

  return (
    <Card.Body>
      {data && !Object.keys(data).length === 0 && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {data && Object.keys(data).length > 0 && (
        <div>
          <div className="row col-lg-divider gx-lg-6">
            <div className="col-lg-6">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">
                    <FormattedMessage id="app.common.sentiment" />
                  </h6>
                  <h1 className="card-title">{capitalize(word)}</h1>

                  <div className="d-flex align-items-center">
                    {Object.values(Constants.Review.Types).map(
                      (item, index) => (
                        <Badge
                          key={item.id}
                          bg={`soft-${item.color}`}
                          className={`text-${item.color} ${
                            index < Object.keys(Constants.Review.Types).length
                              ? 'me-2'
                              : ''
                          }`}
                        >
                          <i className={`${item.icon} me-1`} />
                          <span className="me-1">
                            <FormattedMessage
                              id={`app.common.${item.labelKey}`}
                            />
                          </span>
                          {`${Math.round(
                            (100 * data[1].nums[lowerCase(item.id)]) /
                              data[1].nums.total
                          )}%`}
                        </Badge>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">
                    <FormattedMessage id="app.common.mentions" />
                  </h6>
                  <div className="d-flex align-items-end">
                    <h1 className="card-title me-1 mb-0">
                      {data[1].nums.mention}
                    </h1>
                    {mentionDiff !== 0 ? (
                      <span
                        className={mentionColor ? `text-${mentionColor}` : ''}
                      >
                        {mentionDir && (
                          <i className={`bi-arrow-${mentionDir}-short`} />
                        )}
                        {Math.abs(mentionDiff)}
                      </span>
                    ) : (
                      <i className="bi-arrow-left-right small" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">
                    <FormattedMessage id="app.common.reviews" />
                  </h6>
                  <div className="d-flex align-items-end">
                    <h1 className="card-title me-1 mb-0">
                      {data[1].nums.total}
                    </h1>
                    <span className="mx-2">/</span>
                    <span>{`${data[1].nums.avg.toFixed(2)}%`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card.Body>
  );
}

Sentiment.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
  word: PropTypes.string,
};

Sentiment.defaultProps = {
  loading: false,
  data: null,
  word: '',
};

function Reviews({ loading, data, word }) {
  const getHighlightedText = (sText, fullText) => {
    const chunks = findAll({
      searchWords: Array.isArray(sText) ? sText : [sText],
      textToHighlight: fullText,
    });

    const highlightedText = chunks
      .map((chunk) => {
        const { end, highlight, start } = chunk;
        const t = fullText.substr(start, end - start);
        if (highlight) {
          return `<mark class="bg-primary text-white rounded px-2">${t}</mark>`;
        }
        return t;
      })
      .join('');

    return highlightedText;
  };

  if (loading) {
    return (
      <Card.Body>
        <RequestLoading loading={loading} margin="5" />
      </Card.Body>
    );
  }

  return (
    <Card.Body>
      {data?.length === 0 && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {data?.length > 0 && (
        <ul className="list-unstyled list-py-3 mb-0">
          {data.map((item, index) => (
            <li key={`${index.toString()}`}>
              <div className="d-flex gap-3 align-items-center">
                {/* <BrandIcon brand={item.site} /> */}
                <Rating initVal={item.rating} />
                <div>
                  <div className="fs-6 text-body mb-0">
                    <ShowMoreText
                      text={getHighlightedText(word, item.comment)}
                      maxLine={2}
                      basedOn="words"
                    />
                  </div>
                  <div>
                    <span className="h6">
                      {item.lang && Object.keys(languages)[0] !== item.lang && (
                        <CountryFlag
                          countryCode={item.lang}
                          svg
                          className="me-2"
                        />
                      )}
                      {item.title}
                    </span>
                    <span className="text-muted small">
                      ,{' '}
                      {moment(item.date).format(
                        Constants.DateFormats.APP.Moment.Common
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Card.Body>
  );
}

Reviews.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  word: PropTypes.string,
};

Reviews.defaultProps = {
  loading: false,
  data: null,
  word: '',
};

function Keywords() {
  const { activeProperty } = useContext(PropertyContext);

  const [date, setDate] = useState(initialDates);
  const [activeItem, setActiveItem] = useState();

  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/sentiment_most_used',
    preventFetch: !activeProperty?.id && !date,
    params: {
      id: activeProperty?.id,
      date,
    },
    onSuccess: (data) => {
      if (data?.reviews?.[0]?.val) {
        setActiveItem(data.reviews[0]);
      }
    },
  });

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
  };

  const columnParser = (id, section, row) => {
    const value = section ? row[section][id] : row[id];

    const isPositive = row.nums[1].nums.positive >= row.nums[1].nums.negative;
    const selector = isPositive ? 'positive' : 'negative';
    const isHigher = isPositive
      ? row.nums[1].nums[selector] >= row.nums[0].nums[selector]
      : row.nums[1].nums[selector] < row.nums[0].nums[selector];

    const mentionDiff = row.nums[1].nums.mention - row.nums[0].nums.mention;
    let mentionDir = null;
    let mentionColor = null;
    if (mentionDiff > 0) {
      mentionDir = 'up';
      mentionColor = 'success';
    } else if (mentionDiff < 0) {
      mentionDir = 'down';
      mentionColor = 'danger';
    }

    let result;
    switch (id) {
      case 'val':
        result = <span className="text-capitalize">{value}</span>;
        break;

      case 'type':
        result = (
          <Badge
            bg={`soft-${
              Constants.Review.Types[isPositive ? 'Positive' : 'Negative'].color
            }`}
            className={`text-${
              Constants.Review.Types[isPositive ? 'Positive' : 'Negative'].color
            }`}
          >
            <i
              className={`${
                Constants.Review.Types[isPositive ? 'Positive' : 'Negative']
                  .icon
              } me-1`}
            />
            <FormattedMessage
              id={`app.common.${
                Constants.Review.Types[isPositive ? 'Positive' : 'Negative']
                  .labelKey
              }`}
            />
          </Badge>
        );
        break;

      case 'direction':
        result = isHigher ? (
          <span className="text-success">
            <i className="bi-graph-up-arrow" />
          </span>
        ) : (
          <span className="text-danger">
            <i className="bi-graph-down-arrow" />
          </span>
        );
        break;

      case 'mention':
        result = (
          <div className="d-flex flex-column">
            <span>
              <strong>{row.nums[1].nums.mention}</strong>
            </span>
            {mentionDiff !== 0 ? (
              <span className={mentionColor ? `text-${mentionColor}` : ''}>
                {mentionDir && <i className={`bi-arrow-${mentionDir}-short`} />}
                {Math.abs(mentionDiff)}
              </span>
            ) : (
              <i className="bi-arrow-left-right small" />
            )}
          </div>
        );
        break;

      default:
        result = value;
        break;
    }

    return result;
  };

  return (
    <Row>
      <Col xl="6" className="mb-3 mb-xl-5">
        <Card className="h-100">
          <Card.Header className="card-header-content-between">
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.keywords" />
            </Card.Title>
            <DateRangePicker
              type="button"
              initialSettings={{
                initialDates: {
                  startDate: initialDates[0],
                  endDate: initialDates[1],
                },
              }}
              value={date}
              onChange={handleChangeDate}
              disabled={apiLoading}
            />
          </Card.Header>
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          {!apiLoading && apiError && (
            <Card.Body>
              <RequestResult type="error" message={apiError} />
            </Card.Body>
          )}

          {!apiLoading && !apiError && !apiData?.reviews && (
            <Card.Body>
              <RequestResult type="secondary" message="app.common.noData" />
            </Card.Body>
          )}

          {!apiLoading && !apiError && apiData?.reviews && (
            <Table
              responsive
              hover
              className="table-thead-bordered table-nowrap table-align-middle card-table"
            >
              <tbody>
                {apiData.reviews?.map((row, rowIndex) => (
                  <tr
                    key={`row_${rowIndex.toString()}`}
                    onClick={() => {
                      setActiveItem(row);
                    }}
                    role="button"
                    className={row.val === activeItem.val ? 'bg-light' : ''}
                  >
                    {columns.map(({ id, section, align }, index) => (
                      <td
                        key={`row_${rowIndex.toString()}_col_${id}_${index.toString()}`}
                        className={align || 'text-end'}
                      >
                        {columnParser(id, section, row)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </Col>
      <Col xl="6">
        <Row className="h-100">
          <Col xs="12" className="mb-3 mb-lg-5">
            <Card className="h-100">
              <Sentiment
                loading={apiLoading}
                word={activeItem?.val}
                data={activeItem?.nums}
              />
            </Card>
          </Col>
          <Col xs="12" className="mb-3 mb-lg-5">
            <Card className="h-100">
              <Card.Header>
                <FormattedMessage
                  id="app.common.sentimentOverTimeForWord"
                  values={{ word: capitalize(activeItem?.val) }}
                />
              </Card.Header>
              <Chart
                loading={apiLoading}
                word={activeItem?.val}
                date={date}
                propertyId={activeProperty?.id}
              />
            </Card>
          </Col>
          <Col xs="12" className="mb-3 mb-lg-5">
            <Card className="h-100">
              <Card.Header>
                <FormattedMessage id="app.common.recentReviews" />
              </Card.Header>
              <Reviews
                loading={apiLoading}
                word={activeItem?.val}
                data={activeItem?.nums?.last5}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Keywords;
