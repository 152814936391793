import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { chunk } from 'lodash';
import { useAxiosQuery } from '../../../../../hooks';
import Utils from '../../../../../utils';
import {
  FormError,
  RequestLoading,
  RequestResult,
  SmartForm,
  Tooltip,
} from '../../../../../components';
import { yup } from '../../../../../lib';
import Constants from '../../../../../constants';

const SearchBox = forwardRef(
  ({ loading, disabled, defaultValue, onChange }, ref) => {
    const { formatMessage } = useIntl();
    const {
      register,
      handleSubmit,
      getValues,
      reset,
      formState: { errors, isSubmitted },
      getFieldState,
    } = useForm({
      defaultValues: { website: defaultValue },
      resolver: yupResolver(
        yup
          .object({
            // website: yup.string().required().url(),
            website: yup.string().customUrl().nullable(),
          })
          .required()
      ),
    });

    const handleOnSubmit = ({ website }) => {
      onChange(website);
    };

    useImperativeHandle(ref, () => ({
      reset: (values, options) => reset(values, options),
      isValid: async () =>
        handleSubmit(() => {})().then(() => !getFieldState('website')?.error),
      getValue: () => getValues().website,
    }));

    return (
      <Form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
        <Form.Label htmlFor="website">
          <FormattedMessage id="app.common.website" />
        </Form.Label>

        <Form.Control
          disabled={disabled}
          isInvalid={isSubmitted && !!errors.website}
          isValid={isSubmitted && !errors.website}
          id="website"
          name="website"
          placeholder={formatMessage({ id: 'app.common.enterUrl' })}
          autoComplete="off"
          {...register('website')}
        />
        <FormError error={errors.website} className="mb-3 mb-sm-0" />

        <Card.Header className="d-flex justify-content-center">
          <div>
            <Button
              type="submit"
              variant="white"
              size="sm"
              disabled={disabled}
              className="me-2"
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-1" />
                  <FormattedMessage id="app.common.loading" />
                </>
              ) : (
                <FormattedMessage id="app.common.fillTheFormWithWebsiteData" />
              )}
            </Button>
            <Tooltip
              placement="right"
              content={
                <FormattedMessage id="app.helpers.propertySelect.businessInfo.fillForm" />
              }
            >
              <i className="bi-question-circle text-body ms-1" />
            </Tooltip>
          </div>
        </Card.Header>
      </Form>
    );
  }
);

SearchBox.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

SearchBox.defaultProps = {
  loading: false,
  disabled: false,
  defaultValue: undefined,
  onChange: () => {},
};

let socialArray = Object.keys(Constants.Social.Sites).map((key) => ({
  key,
  apiKey: key,
  schema: yup.string().nullable().customUrl(),
}));
socialArray = Utils.Array.padArrayToMultiple(
  socialArray,
  3,
  {
    key: 'dummy',
    type: 'hidden',
  },
  'key'
);
const socialFields = chunk(socialArray, 3).map((item) => ({
  cols: [...item],
}));

function Branding({
  initialName,
  initialWebsite,
  onNext,
  onPrev,
  onLoading,
  // value,
  // onChange,
}) {
  const { formatMessage } = useIntl();
  const formRef = useRef();
  const searchBoxRef = useRef();

  const [website, setWebsite] = useState(initialWebsite);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/basicDetail/', // `/detail/getData`,
    preventFetch: !website,
    requestType: 'get',
    params: { url: Utils.String.trimUrl(website) },
  });

  const fields = [
    {
      cols: [
        {
          key: 'name',
          apiKey: 'name',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'description',
          apiKey: 'description',
          type: 'textarea',
          schema: yup.string(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'logo',
          type: 'image',
          schema: yup.mixed(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'firstColor',
          apiKey: 'color_main',
          type: 'color',
          schema: yup.string(),
        },
        {
          key: 'secondColor',
          apiKey: 'color_subheading',
          type: 'color',
          schema: yup.string(),
        },
        {
          key: 'thirdColor',
          apiKey: 'color_description',
          type: 'color',
          schema: yup.string(),
        },
      ],
    },
    {
      info: {
        message: formatMessage({ id: 'app.common.socialAccounts' }),
        className: 'h5 mt-4 mb-4 pb-3 border-bottom',
      },
    },
    ...socialFields,
  ];

  const getSocialDefaults = useCallback(() => {
    const newObj = Object.entries(Constants.Social.Sites).reduce(
      (obj, [key]) => {
        const nObj = obj;
        if (apiData?.[key]) {
          nObj[key] = apiData[key];
        }
        return nObj;
      },
      {}
    );

    return newObj;
  }, [apiData]);

  const getDefaultValues = useCallback(
    () => ({
      name: initialName || '',
      description: apiData?.description || '',
      logo: apiData?.logo || '',
      firstColor: apiData?.colors?.[0]?.hex || '#000000',
      secondColor: apiData?.colors?.[1]?.hex || '#000000',
      thirdColor: apiData?.colors?.[2]?.hex || '#000000',
      ...getSocialDefaults(),
    }),
    [apiData, initialName, getSocialDefaults]
  );

  const onSubmit = async (data) => {
    const isWebsiteValid = await searchBoxRef.current.isValid();
    if (!isWebsiteValid) {
      return;
    }

    onNext({ website: searchBoxRef.current.getValue(), ...data });
    setTimeout(() => {
      formRef?.current?.reset(undefined, { keepValues: true });
      searchBoxRef?.current?.reset(undefined, { keepValues: true });
    }, 100);
  };

  useEffect(() => {
    onLoading(apiLoading);
  }, [onLoading, apiLoading]);

  return (
    <Row className="justify-content-lg-center py-10">
      <Col lg="8">
        <Card>
          <Card.Body>
            <Row>
              <Col xs="12" className="mb-3 mb-lg-5">
                <SearchBox
                  ref={searchBoxRef}
                  loading={apiLoading}
                  disabled={apiLoading}
                  defaultValue={website}
                  onChange={(nValue) => {
                    setWebsite('');
                    setWebsite(nValue);
                  }}
                />
              </Col>
              <Col xs="12">
                <>
                  <RequestLoading loading={apiLoading} size="lg" margin="5" />
                  {apiError && (
                    <RequestResult
                      type="secondary"
                      message={formatMessage({
                        id: 'app.helpers.propertySelect.businessInfo.fillFormError',
                      })}
                      dismissible
                      className="mb-5"
                    />
                  )}

                  {!apiLoading && (
                    <SmartForm
                      ref={formRef}
                      fields={fields}
                      onSubmit={onSubmit}
                      defaultValues={getDefaultValues()}
                      isWizard
                      onWizardPrev={onPrev}
                      submitButtonText="continue"
                      footerAlign="between"
                    />
                  )}
                </>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

Branding.propTypes = {
  initialName: PropTypes.string,
  initialWebsite: PropTypes.string,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onLoading: PropTypes.func,
  // value: PropTypes.objectOf(PropTypes.any),
  // onChange: PropTypes.func,
};

Branding.defaultProps = {
  initialName: '',
  initialWebsite: '',
  onNext: () => {},
  onPrev: () => {},
  onLoading: () => {},
  // value: undefined,
  // onChange: () => {},
};

export default Branding;
