import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Card,
  Col,
  ListGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import ReactSelect from 'react-select';
import { useAxiosQuery } from '../../../hooks';
import { GoogleMap, RequestLoading, RequestResult } from '../../../components';
import Constants from '../../../constants';
import { PropertyContext } from '../../../context/PropertyContext';
import NoDataIllustration from '../../../assets/images/svg/illustrations/oc-work-balance.svg';
import NoDataIllustrationLight from '../../../assets/images/svg/illustrations-light/oc-work-balance.svg';

const getMapPin = (originalRank) => {
  const rank = originalRank;
  const palette = Constants.Colors.RatePalette;
  let colorItem = palette[palette.length - 1];
  if (rank > 0) {
    const colorIndex = rank - 1;
    colorItem =
      colorIndex > palette.length - 1
        ? palette[palette.length - 1]
        : palette[colorIndex];
  }

  const { color, textColor } = colorItem;
  let svgString = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24.0233 41.1847C20.6947 38.3378 10 28.5994 10 21C10 12 16 6 25 6C34 6 40 13.5 40 21C40 27.3634 29.2018 38.0462 25.929 41.1378C25.3952 41.6421 24.5813 41.662 24.0233 41.1847ZM30 19C30 21.7614 27.7614 24 25 24C22.2386 24 20 21.7614 20 19C20 16.2386 22.2386 14 25 14C27.7614 14 30 16.2386 30 19Z"
                      fill="${color}"
                    />
                    <text x="52%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="${textColor}" style="font-family:Arial; font-weight:bold;">${rank}</text>
                  </svg>`;

  if (rank === 1) {
    svgString = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="${color}"
                    d="M24,4.6l6.3,12.8l14.4,1.9l-10.4,9.9l2.6,14L24,36.7l-12.7,6.7l2.6-14L3.3,19.4l14.4-1.9L24,4.6z"
                  />
                  <text x="50%" y="55%" dominant-baseline="middle" text-anchor="middle" fill="${textColor}" style="font-family:Arial; font-weight:bold;">${rank}</text>
                </svg>`;
  } else if (rank === 0) {
    svgString = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <polygon
                    fill="${color}"
                    points="24,35.7 15,44.7 3.3,33 12.3,24 3.3,15 15,3.3 24,12.3 33,3.3 44.7,15 35.7,24 44.7,33 33,44.7 	" 	
		              />              
                  <text x="50%" y="55%" dominant-baseline="middle" text-anchor="middle" fill="${textColor}" style="font-family:Arial; font-weight:bold; font-size:13px">20+</text>
                </svg>`;
  }

  return { svgString, width: 48, height: 48 };
};

function Map({ searchTerm, id }) {
  const [showModal, setShowModal] = useState(false);
  const [activeItem, setActiveItem] = useState();

  const {
    isLoading: apiLoading,
    error: apiError,
    data: apiData,
  } = useAxiosQuery({
    url: '/geoGrid/detail',
    preventFetch: !id,
    params: { local_report_id: id },
    select: (data) =>
      data.map(({ lat, lng, ...rest }) => ({
        lat: Number(lat),
        lng: Number(lng),
        customIcon: getMapPin(rest.ranking),
        ...rest,
      })),
  });

  const closeModal = () => {
    setActiveItem(null);
    setShowModal(false);
  };

  const getListItems = (data) => {
    let result;
    try {
      result = JSON.parse(data.result);
    } catch (error) {
      result = undefined;
    }

    return result?.data || [];
  };

  const markerOnClick = useCallback(({ item }) => {
    setActiveItem(item);
    setShowModal(true);
  }, []);

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && !apiData && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {!apiLoading && !apiError && apiData && (
        <div className="position-relative">
          <GoogleMap
            height={800}
            initialMarks={apiData || []}
            initialCenter={apiData?.[0]}
            markerOnClick={markerOnClick}
          />
        </div>
      )}
      <Modal show={showModal} onHide={closeModal} scrollable size="lg">
        <Modal.Header closeButton>
          <div className="d-flex flex-column flex-lg-row w-100 align-items-start justify-content-between">
            <Modal.Title as="h5">
              <FormattedMessage
                id="app.common.resultsForKey"
                values={{ key: searchTerm.trim() }}
              />
            </Modal.Title>
            {activeItem?.location_name && (
              <Badge bg="info" className="mt-2 mt-lg-0">
                <i className="bi-pin-map me-1" />
                {activeItem.location_name}
              </Badge>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          {activeItem && (
            <ListGroup>
              {getListItems(activeItem).map((item, index) => (
                <ListGroup.Item
                  key={`subItem_${index.toString()}`}
                  className="d-flex flex-column flex-sm-row"
                >
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <div
                          className="mt-n2"
                          style={{ transform: 'scale(0.8)' }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: getMapPin(item.ranking).svgString,
                          }}
                        />
                      </div>

                      <div className="flex-grow-1 ms-2 w-100">
                        <Row>
                          <Col xs="12" lg={item.sponsored ? '8' : '12'}>
                            <div className="d-flex">
                              <h5 className="mb-0">{item.name}</h5>
                              {activeItem.ranking === index + 1 && (
                                <Badge className="ms-2">
                                  <FormattedMessage id="app.common.myProperty" />
                                </Badge>
                              )}
                            </div>
                          </Col>

                          {!!item.sponsored && (
                            <Col
                              xs="12"
                              lg="4"
                              className="text-lg-end mt-2 mt-lg-0"
                            >
                              <Badge
                                pill
                                bg="soft-primary"
                                className="text-primary"
                              >
                                <i className="bi-star-fill me-1" />
                                <FormattedMessage id="app.common.sponsored" />
                              </Badge>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                    <div className="d-flex flex-column w-100 mt-2">
                      {item.other_details.length > 0 && (
                        <ul className="ps-3">
                          {item.other_details.map((detail, detailIndex) => (
                            <li
                              key={`subItem_detail_${detailIndex.toString()}`}
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: detail,
                              }}
                            />
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={closeModal} variant="white">
            <FormattedMessage id="app.common.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

Map.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

function RankingHeatmap() {
  const { activeProperty } = useContext(PropertyContext);
  const [activeDateIndex, setActiveDateIndex] = useState(0);
  const [activeDate, setActiveDate] = useState();
  const [activeItem, setActiveItem] = useState();

  const {
    isLoading: apiLoading,
    error: apiError,
    data: apiData,
  } = useAxiosQuery({
    url: '/geoGrid/list',
    preventFetch: !activeProperty?.id,
    params: {
      PropertyID: activeProperty?.id,
    },
    select: (data) => {
      const unsorted = _(data)
        .groupBy((x) => x.date)
        .value();
      const sortedKeys = Object.keys(unsorted).sort((a, b) =>
        b.localeCompare(a)
      );
      const sorted = {};
      sortedKeys.forEach((key) => {
        sorted[key] = unsorted[key];
      });
      return sorted;
    },
    onSuccess: (data) => {
      const actDate = Object.keys(data)?.[0] || undefined;
      setActiveDate(actDate);
      setActiveItem(data[actDate]?.[0]);
    },
  });

  return (
    <div>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && Object.keys(apiData).length === 0 && (
        <RequestResult
          type="secondary"
          title="emptyState.rankingHeatmap.title"
          message="emptyState.rankingHeatmap.message"
          image={{
            src: NoDataIllustration,
            darkSrc: NoDataIllustrationLight,
          }}
        />
      )}
      {!apiLoading && !apiError && apiData?.[activeDate] && (
        <Row>
          <Col xs="12" className="mb-3 mb-lg-5">
            <Card className="h-100">
              <Card.Header>
                <Row className="justify-content-between align-items-center flex-grow-1">
                  <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
                    <Card.Title bsPrefix="card-header-title" as="h4">
                      <FormattedMessage id="app.common.searchTerms" />
                    </Card.Title>
                  </Col>
                  <Col xs="auto">
                    <Row className="align-items-sm-center">
                      <Col sm="auto" className="mb-2 mb-md-0">
                        <div className="d-flex align-items-center">
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            className="btn-icon"
                            onClick={() => {
                              let nextIndex = activeDateIndex - 1;
                              if (nextIndex < 0) {
                                nextIndex = Object.keys(apiData).length - 1;
                              }
                              setActiveDateIndex(nextIndex);
                              setActiveDate(Object.keys(apiData)[nextIndex]);
                              setActiveItem(
                                apiData[Object.keys(apiData)[nextIndex]]?.[0]
                              );
                            }}
                          >
                            <i className="bi-chevron-left" />
                          </Button>
                          <ReactSelect
                            options={Object.keys(apiData).map((key) => ({
                              label: moment(key).format(
                                Constants.DateFormats.APP.Moment.Common
                              ),
                              value: key,
                            }))}
                            value={{
                              label: moment(activeDate).format(
                                Constants.DateFormats.APP.Moment.Common
                              ),
                              value: activeDate,
                            }}
                            onChange={(opt) => {
                              const nextIndex = Object.keys(apiData).indexOf(
                                opt.value
                              );
                              setActiveDateIndex(nextIndex);
                              setActiveDate(opt.value);
                              setActiveItem(apiData[opt.value]?.[0]);
                            }}
                            className="react-select-custom-container mx-1"
                            classNamePrefix="react-select-custom"
                            isClearable={false}
                            isSearchable={false}
                          />
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            className="btn-icon"
                            onClick={() => {
                              let nextIndex = activeDateIndex + 1;
                              if (nextIndex > Object.keys(apiData).length - 1) {
                                nextIndex = 0;
                              }
                              setActiveDateIndex(nextIndex);
                              setActiveDate(Object.keys(apiData)[nextIndex]);
                              setActiveItem(
                                apiData[Object.keys(apiData)[nextIndex]]?.[0]
                              );
                            }}
                          >
                            <i className="bi-chevron-right" />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {apiData[activeDate].map((item, index) => {
                  const nullValue = 99999;
                  const rate = item.avg_rate || nullValue;

                  const palette = Constants.Colors.RatePalette;
                  const colorIndex = Math.floor(rate) - 1;
                  const colorItem =
                    colorIndex > palette.length - 1
                      ? palette[palette.length - 1]
                      : palette[colorIndex];

                  const { color, textColor } = colorItem;

                  return (
                    <Button
                      key={`keyword_${index.toString()}`}
                      variant=""
                      size="sm"
                      className="me-2 mb-2 rounded-pill py-1 position-relative"
                      style={{
                        backgroundColor: color,
                        borderColor: color,
                        color: textColor,
                      }}
                      onClick={() => {
                        setActiveItem(item);
                      }}
                    >
                      {item.keyword}
                      <Badge bg="dark" className="fw-normal ms-2">
                        {rate === nullValue ? (
                          <FormattedMessage id="app.common.n/a" />
                        ) : (
                          rate.toFixed(2)
                        )}
                      </Badge>
                      {activeItem?.id === item.id && (
                        <div className="position-absolute top-0 start-100 translate-middle">
                          <Badge bg="dark" pill>
                            <i className="bi-check-lg" />
                          </Badge>
                        </div>
                      )}
                    </Button>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
          {activeItem && (
            <Col xs="12">
              <Map searchTerm={activeItem.keyword} id={activeItem.id} />
            </Col>
          )}
        </Row>
      )}
    </div>
  );
}

export default RankingHeatmap;
