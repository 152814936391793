import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';

const AccessContext = createContext();

function AccessProvider({
  children,
  forcePath,
  forcePathMessage,
  returnPath,
  allowedPaths,
  hasAccess,
}) {
  const location = useLocation();

  const isAllowedPath = () => {
    let allowed = false;
    if (!hasAccess) {
      const founded = [...allowedPaths, forcePath].find(
        (path) => path === location.pathname
      );

      if (founded) {
        allowed = true;
      }
    } else if (location.pathname !== forcePath) {
      allowed = true;
    }

    return allowed;
  };

  const value = useMemo(
    () => ({
      hasAccess,
    }),
    [hasAccess]
  );

  return (
    <AccessContext.Provider value={value}>
      <>
        {forcePath !== location.pathname && !hasAccess && forcePathMessage && (
          <Alert variant="danger" className="rounded-0 mb-0">
            <div className="container d-flex align-items-center my-3">
              <div className="flex-shrink-0">
                <i className="bi-exclamation-triangle-fill fs-1" />
              </div>
              <div className="flex-grow-1 ms-2">{forcePathMessage}</div>
            </div>
          </Alert>
        )}
        {isAllowedPath() && children}
        {forcePath &&
          forcePath === location.pathname &&
          hasAccess &&
          returnPath && <Navigate to={returnPath} replace />}
        {forcePath &&
          forcePath !== location.pathname &&
          !hasAccess &&
          !isAllowedPath() && <Navigate to={forcePath} replace />}
      </>
    </AccessContext.Provider>
  );
}

AccessProvider.propTypes = {
  children: PropTypes.node.isRequired,
  forcePath: PropTypes.string,
  forcePathMessage: PropTypes.node,
  returnPath: PropTypes.string,
  allowedPaths: PropTypes.arrayOf(PropTypes.string.isRequired),
  hasAccess: PropTypes.bool,
};

AccessProvider.defaultProps = {
  forcePath: null,
  forcePathMessage: null,
  returnPath: null,
  allowedPaths: [],
  hasAccess: false,
};

export { AccessContext, AccessProvider };
