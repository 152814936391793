import React from 'react';
import { Row, Col, Card, Button, ProgressBar } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';
import Utils from '../../../../utils';

function BillingOverview() {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/xtransactions/list',
  });

  return (
    <>
      {(apiLoading || apiError || (!apiLoading && !apiError && !apiData)) && (
        <Card.Body>
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError} />

          {!apiLoading && !apiError && !apiData && (
            <RequestResult type="secondary" message="app.common.noData" />
          )}
        </Card.Body>
      )}

      {!apiLoading && !apiError && apiData && (
        <>
          <Card.Body>
            <Row>
              <Col md className="mb-4 mb-md-0">
                <div className="mb-4">
                  <Card.Subtitle>Your plan (billed yearly):</Card.Subtitle>
                  <h3>Starter - April 2020</h3>
                </div>
                <div>
                  <Card.Subtitle>
                    <FormattedMessage id="app.common.totalPerYear" />
                  </Card.Subtitle>
                  <h1 className="text-primary">
                    <span className="me-1">
                      <FormattedNumber
                        value={264}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                      />
                    </span>
                    USD
                  </h1>
                </div>
              </Col>
              <Col md="auto">
                <div className="d-grid d-sm-flex gap-3">
                  <Button variant="white">
                    <FormattedMessage id="app.common.cancelSubscription" />
                  </Button>
                  <Button className="w-100 w-sm-auto">
                    <FormattedMessage id="app.common.updatePlan" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
          <hr className="my-3" />
          <Card.Body>
            <Row className="align-items-center flex-grow-1 mb-2">
              <Col>
                <Card.Title bsPrefix="card-header-title" as="h4">
                  Storage usage
                </Card.Title>
              </Col>
              <Col xs="auto">
                <span className="text-dark fw-semi-bold me-1">
                  {Utils.String.humanFileSize(4270000000, true, 2)}
                </span>
                used of {Utils.String.humanFileSize(6000000000, true, 0)}
              </Col>
            </Row>
            <ProgressBar className="rounded-pill mb-3">
              <ProgressBar now={33} />
              <ProgressBar now={25} className="opacity-50" />
            </ProgressBar>
            <ul className="list-inline list-px-2">
              <li className="list-inline-item">
                <span className="legend-indicator bg-primary" />
                Personal usage space
              </li>
              <li className="list-inline-item">
                <span className="legend-indicator bg-primary opacity-50" />
                Shared space
              </li>
              <li className="list-inline-item">
                <span className="legend-indicator" />
                Unused space
              </li>
            </ul>
          </Card.Body>
        </>
      )}
    </>
  );
}

export default BillingOverview;
