import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  ListGroup,
  Form,
  Button,
  Dropdown,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { useClickAway } from 'react-use';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  seoCards,
  domainCards,
  propertyListOpenGraph,
  propertyListGeneralInfo,
  propertyListSearchEngine,
  propertyListDomain,
} from './References';
import {
  BlacklistCard,
  MozrankCard,
  GoogleSpeedCard,
  SeoCard,
  PropertyListCard,
  BacklinkCard,
  BrokenLinksCard,
  ScoreCard,
  Cloudflare,
} from './partials';
import { PropertyContext } from '../../../context/PropertyContext';
import { useAxiosQuery } from '../../../hooks';
import { RequestLoading, RequestResult, FormError } from '../../../components';
import Constants from '../../../constants';
import { yup } from '../../../lib';
import Utils from '../../../utils';

function SearchBox({
  loading,
  disabled,
  defaultValue,
  onChange,
  own,
  competitorGroups,
}) {
  // const [inputValue, setInputValue] = useState(defaultValue);
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
  const ref = useRef();
  useClickAway(ref, () => {
    setDropdownIsVisible(false);
  });

  const { formatMessage } = useIntl();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { url: defaultValue },
    resolver: yupResolver(
      yup
        .object({
          // url: yup.string().required().url(),
          url: yup.string().customUrl().required(),
        })
        .required()
    ),
  });

  const handleOnSubmit = ({ url }) => {
    onChange(url);
    setDropdownIsVisible(false);
  };

  const handleFillUrl = (url) => {
    setValue('url', url);
    clearErrors();
    handleOnSubmit({ url });
  };

  const urlFieldValue = watch('url');

  return (
    <div ref={ref}>
      <Form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
        <InputGroup
          className={`input-group-merge ${
            isSubmitted && !!errors.url ? 'is-invalid' : ''
          } ${isSubmitted && !errors.url ? 'is-valid' : ''}`}
        >
          <div className="input-group-prepend input-group-text">
            <i className="bi-search" />
          </div>
          <Form.Control
            disabled={disabled}
            isInvalid={isSubmitted && !!errors.url}
            isValid={isSubmitted && !errors.url}
            id="url"
            placeholder={formatMessage({ id: 'app.common.enterUrl' })}
            onFocus={() => {
              setDropdownIsVisible(true);
            }}
            autoComplete="off"
            {...register('url')}
          />
          <div className="input-group-append input-group-append-last-sm-down-none">
            <Button
              type="submit"
              disabled={disabled}
              className="d-none d-sm-inline-block"
            >
              <div>
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-1" />
                    <FormattedMessage id="app.common.loading" />
                  </>
                ) : (
                  <FormattedMessage id="app.common.analyze" />
                )}
              </div>
            </Button>
          </div>
        </InputGroup>
        <FormError error={errors.url} className="mb-3 mb-sm-0" />
        <Button
          type="submit"
          disabled={disabled}
          className="w-100 d-sm-none mt-2"
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" className="me-1" />
              <FormattedMessage id="app.common.loading" />
            </>
          ) : (
            <FormattedMessage id="app.common.analyze" />
          )}
        </Button>
        <Dropdown
          show={(own || competitorGroups.length > 0) && dropdownIsVisible}
        >
          <Dropdown.Menu className="dropdown-card navbar-dropdown-menu-borderless w-100 dropdown-menu-form-search">
            <div className="card-body-height">
              {own && (
                <>
                  <Dropdown.Header className="text-primary">
                    <FormattedMessage id="app.common.myProperty" />
                  </Dropdown.Header>
                  <Dropdown.Item
                    onClick={() => {
                      if (!own.url) {
                        return;
                      }
                      handleFillUrl(own.url);
                    }}
                    disabled={!own.url}
                    active={own.url && urlFieldValue === own.url}
                    className="text-body"
                  >
                    <strong className="text-dark">{own.title}</strong>
                    <br />
                    {own.address} <br />
                    {own.url || (
                      <span className="text-danger">
                        <FormattedMessage id="app.common.noUrlProvided" />
                      </span>
                    )}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              {competitorGroups.map(
                (group, groupIndex) =>
                  group.items.length > 0 && (
                    <React.Fragment key={`comp_group_${groupIndex.toString()}`}>
                      <Dropdown.Header
                        style={group.color ? { color: group.color } : {}}
                      >
                        {group.title}
                      </Dropdown.Header>
                      {group.items.map((item, index) => (
                        <Dropdown.Item
                          key={`comp_group_${groupIndex.toString()}_${index.toString()}`}
                          onClick={() => {
                            if (!item.url) {
                              return;
                            }
                            handleFillUrl(item.url);
                          }}
                          disabled={!item.url}
                          active={item.url && urlFieldValue === item.url}
                          className="text-body"
                        >
                          <strong className="text-dark">{item.title}</strong>
                          <br />
                          {item.address} <br />
                          {item.url || (
                            <span className="text-danger">
                              <FormattedMessage id="app.common.noUrlProvided" />
                            </span>
                          )}
                        </Dropdown.Item>
                      ))}
                      {groupIndex < competitorGroups.length && (
                        <Dropdown.Divider />
                      )}
                    </React.Fragment>
                  )
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </Form>
    </div>
  );
}

const ListItemProps = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
  url: PropTypes.string,
};

const SectionItemProps = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(ListItemProps)),
};

SearchBox.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  own: PropTypes.shape(ListItemProps),
  competitorGroups: PropTypes.arrayOf(PropTypes.shape(SectionItemProps)),
};

SearchBox.defaultProps = {
  loading: false,
  disabled: false,
  defaultValue: undefined,
  onChange: () => {},
  own: undefined,
  competitorGroups: [],
};

function Overview() {
  const { formatMessage } = useIntl();
  const { activeProperty } = useContext(PropertyContext);
  const [url, setUrl] = useState(activeProperty?.web);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/detail/getData',
    preventFetch: !url,
    requestType: 'get',
    params: { url: Utils.String.trimUrl(url) },
  });

  useEffect(() => {
    setUrl(activeProperty?.web);
  }, [activeProperty?.web]);

  return (
    <Row>
      <Col xs="12" className="mb-3 mb-lg-5">
        <SearchBox
          key={url}
          loading={apiLoading}
          disabled={apiLoading}
          defaultValue={url}
          onChange={(value) => {
            setUrl(value);
          }}
          own={
            activeProperty
              ? {
                  title: activeProperty.title,
                  address: activeProperty.fullAddress,
                  url: activeProperty.web,
                }
              : undefined
          }
          competitorGroups={activeProperty.groups.map((group) => ({
            title: group.name,
            color: group.color,
            items: group.comps.map((hotel) => ({
              title: hotel.name,
              address: hotel.address,
              url: hotel.website,
            })),
          }))}
        />
      </Col>
      <Col xs="12">
        <>
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          {apiError && apiError.message === 'errors.api.cloudflare_active' && (
            <Cloudflare.WarningMessage />
          )}
          {apiError && apiError.message !== 'errors.api.cloudflare_active' && (
            <RequestResult type="secondary" message={apiError} />
          )}

          {!apiLoading && !apiError && apiData && (
            <>
              <Card className="mb-3 mb-lg-5">
                <Card.Body>
                  <Row className="col-lg-divider gx-lg-6">
                    {domainCards.map((item) => (
                      <Col lg="3" key={item.id}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <Card.Subtitle as="h6" className="mb-3">
                              <FormattedMessage
                                id={`app.common.${item.title}`}
                              />
                            </Card.Subtitle>
                            <Card.Title as="h3">
                              {apiData[item.id] ? (
                                moment(apiData[item.id]).format(
                                  Constants.DateFormats.APP.Moment.Common
                                )
                              ) : (
                                <FormattedMessage id="app.common.n/a" />
                              )}
                            </Card.Title>
                          </div>

                          <span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
                            <i className={item.icon} />
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
              <Row>
                <Col lg="4">
                  <Row className="h-100">
                    {apiData?.point !== '0' && (
                      <Col xs="12" className="mb-3 mb-lg-5">
                        <ScoreCard value={Number(apiData?.point)} />
                      </Col>
                    )}
                    <Col xs="12" className="mb-3 mb-lg-5">
                      <PropertyListCard
                        title={formatMessage({ id: 'app.common.openGraph' })}
                        data={propertyListOpenGraph.map((item) => ({
                          ...item,
                          value: apiData[item.key],
                        }))}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="8" className="mb-3 mb-lg-5">
                  <PropertyListCard
                    title={formatMessage({ id: 'app.common.generalInfo' })}
                    data={propertyListGeneralInfo.map((item) => ({
                      ...item,
                      value: apiData[item.key],
                    }))}
                  />
                </Col>
              </Row>
              <Row>
                {seoCards.map((item) => (
                  <Col
                    sm="6"
                    md="4"
                    xl="3"
                    className="mb-3 mb-lg-5"
                    key={item.id}
                  >
                    <SeoCard
                      title={formatMessage({ id: `app.common.${item.title}` })}
                      type={item.type}
                      data={apiData[item.id]}
                      flip={item.flip}
                    />
                  </Col>
                ))}
                <Col sm="6" md="4" xl="3" className="mb-3 mb-lg-5">
                  <BacklinkCard
                    url={`/detail/?url=${url}&getBacklinkCount=1`}
                  />
                </Col>
                <Col sm="6" md="4" xl="3" className="mb-3 mb-lg-5">
                  <BrokenLinksCard
                    url={`/detail/?url=${url}&getBrokenLinks=1`}
                  />
                </Col>
                <Col sm="6" md="4" xl="3" className="mb-3 mb-lg-5">
                  <BlacklistCard url={`/detail/?url=${url}&blacklist=1`} />
                </Col>
                <Col sm="6" md="4" xl="3" className="mb-3 mb-lg-5">
                  <MozrankCard url={`/detail/?url=${url}&mozrankApi=1`} />
                </Col>
                <Col sm="6" md="4" xl="3" className="mb-3 mb-lg-5">
                  <GoogleSpeedCard
                    url={`/detail/?url=${url}&pageSpeedChecker=1`}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3 mb-lg-5">
                  <h5 className="text-center text-body text-uppercase my-3">
                    <FormattedMessage id="app.common.socialMediaAccounts" />
                  </h5>
                  <ListGroup horizontal="md">
                    {Object.values(Constants.Social.Sites).map((social) => (
                      <ListGroup.Item
                        key={social.id}
                        className="flex-fill text-center"
                      >
                        {apiData[social.id] ? (
                          <a
                            href={apiData[social.id]}
                            target="_blank"
                            className="text-uppercase mb-1 fs-sm fw-bold text-decoration-none"
                            style={{ color: `${social.color}` }}
                            rel="noreferrer"
                          >
                            <i className={`${social.icon} me-2 mt-n1`} />
                            {social.title}
                            <i className="fi-check ms-2 mt-n1" />
                          </a>
                        ) : (
                          <div className="text-uppercase mb-1 fs-sm fw-bold text-muted">
                            <i className={`${social.icon} me-2 mt-n1`} />
                            {social.title}
                          </div>
                        )}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="mb-3 mb-lg-5">
                  <PropertyListCard
                    title={formatMessage({
                      id: 'app.common.searchEngineVerification',
                    })}
                    data={propertyListSearchEngine.map((item) => ({
                      ...item,
                      value: apiData.verify_searching_engine?.[item.key] || '',
                    }))}
                  />
                </Col>
                <Col md="6" className="mb-3 mb-lg-5">
                  <PropertyListCard
                    title={formatMessage({ id: 'app.common.domain' })}
                    data={propertyListDomain.map((item) => ({
                      ...item,
                      value: apiData[item.key],
                    }))}
                  />
                </Col>
                <Col md="6" className="mb-3 mb-lg-5">
                  <PropertyListCard
                    title={formatMessage({ id: 'app.common.emails' })}
                    data={Object.keys(apiData.mails).map((key, index) => ({
                      key: `email_${index + 1}`,
                      type: 'email',
                      title: `${formatMessage({ id: 'app.common.email' })} #${
                        index + 1
                      }`,
                      value: apiData.mails[key],
                    }))}
                  />
                </Col>
                <Col md="6" className="mb-3 mb-lg-5">
                  <PropertyListCard
                    title={formatMessage({ id: 'app.common.headings' })}
                    data={Object.keys(apiData.headings).map((key) => ({
                      key,
                      type: 'text',
                      title: key.toUpperCase(),
                      value: apiData.headings[key],
                    }))}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      </Col>
    </Row>
  );
}

export default Overview;
