import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Badge, ProgressBar } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Rating, RequestLoading, RequestResult } from '../../../../components';
import { PropertyContext } from '../../../../context/PropertyContext';
import { useAxiosQuery } from '../../../../hooks';
import { languages } from '../../../../context/LangContext';
import { DoughnutChart } from '../../../../components/Charts';
import Constants from '../../../../constants';
import { ThemeContext } from '../../../../context/ThemeContext';
import Utils from '../../../../utils';

const options = {
  rotation: -90,
  circumference: 180,
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      labels: {
        generateLabels: (chart) => {
          const { data } = chart;
          if (data.labels.length && data.datasets.length) {
            const {
              labels: { pointStyle },
            } = chart.legend.options;

            const total = chart.data.datasets[0].data.reduce(
              (acc, obj) => acc + obj,
              0
            );

            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);

              return {
                text: `${label} - ${
                  chart.data.datasets[0].data[i]
                } (${Math.round(
                  (chart.data.datasets[0].data[i] * 100) / total
                )}%)`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                pointStyle,
                hidden: !chart.getDataVisibility(i),
                index: i,
              };
            });
          }
          return [];
        },
      },
    },
    datalabels: {
      padding: {
        left: 8,
        right: 8,
        top: 4,
        bottom: 4,
      },
      display: 'auto',
      anchor: 'center',
      formatter: (value, ctx) => {
        const dataArr = ctx.chart.data.datasets[0].data;
        const total = dataArr.reduce((acc, obj) => acc + obj, 0);
        return `${value} (${Math.round((value * 100) / total)}%)`;
      },
    },
  },
};

function Suggestion({ rating }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/ai_suggestion',
    preventFetch: !rating,
    params: {
      language: languages[0],
      rating,
    },
  });

  return (
    <div className="mt-3">
      <RequestLoading loading={apiLoading} margin="5" size="xs" />
      <RequestResult type="error" message={apiError} className="fs-6 p-2" />
      {!apiLoading && !apiError && apiData?.reviewMessage && (
        <div className="d-flex align-items-center justify-content-center bg-primary rounded small text-white px-3 py-1">
          <i className="bi-lightbulb" />
          <span className="ms-3">{apiData.reviewMessage}</span>
        </div>
      )}
    </div>
  );
}

Suggestion.propTypes = {
  rating: PropTypes.number.isRequired,
};

function RatingSummary() {
  const { formatMessage } = useIntl();
  const { activeProperty } = useContext(PropertyContext);
  const { theme } = useContext(ThemeContext);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/average_rating',
    preventFetch: !activeProperty?.id,
    params: {
      id: activeProperty?.id,
    },
  });

  const getRateType = (v) => {
    let result = Constants.Review.Types.Neutral;
    switch (Math.round(v)) {
      case 1:
      case 2:
        result = Constants.Review.Types.Negative;
        break;

      case 4:
      case 5:
        result = Constants.Review.Types.Positive;
        break;

      case 3:
        result = Constants.Review.Types.Neutral;
        break;

      default:
        result = Constants.Review.Types.Neutral;
        break;
    }

    return result;
  };

  const getProgressVariant = (v) => {
    let bg = 'danger';
    if (v >= 70) {
      bg = 'success';
    } else if (v >= 50 && v < 70) {
      bg = 'info';
    } else if (v >= 30 && v < 50) {
      bg = 'info';
    }

    return bg;
  };

  const getData = (data) => {
    const datasetObj = {
      backgroundColor: [],
      data: [],
      borderWidth: 4,
      borderColor:
        theme === 'dark' ? Utils.Chart.darkBgColor : Utils.Chart.lightBgColor,
      datalabels: { color: [] },
    };

    const labels = [];
    let hasData = false;
    Object.keys(Constants.Review.Types).forEach((key) => {
      if (data[key.toLowerCase()] > 0) {
        hasData = true;
      }
      datasetObj.data.push(data[key.toLowerCase()]);
      datasetObj.datalabels.color.push(Constants.Review.Types[key].hexColor);
      datasetObj.backgroundColor.push(Constants.Review.Types[key].hexColor);
      const type = formatMessage({
        id: `app.common.${Constants.Review.Types[key].labelKey}`,
      });
      labels.push(`${type}`);
    });

    return { labels, datasets: hasData ? [datasetObj] : [] };
  };

  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.ratingSummary" />
        </Card.Title>
      </Card.Header>
      <Row>
        {(apiLoading || apiError) && (
          <Col xs="12">
            <Card.Body>
              <RequestLoading loading={apiLoading} margin="5" />
              <RequestResult type="error" message={apiError} />
            </Card.Body>
          </Col>
        )}
        {!apiLoading && !apiError && !apiData?.own && (
          <Col xs="12">
            <Card.Body>
              <RequestResult type="secondary" message="app.common.noData" />
            </Card.Body>
          </Col>
        )}
        {!apiLoading && !apiError && apiData?.own && (
          <>
            <Col lg="6">
              <Card.Body>
                <div className="h-100 d-flex flex-column justify-content-center align-items-center text-center">
                  <div className="position-relative w-100">
                    <DoughnutChart
                      data={getData(apiData.own.nums)}
                      options={options}
                      plugins={{ datalabels: false }}
                    />
                    <div className="chartjs-doughnut-custom-stat">
                      <small className="text-cap">
                        <FormattedMessage id="app.common.totalReviews" />
                      </small>
                      <span className="h1">{apiData.own.nums.total}</span>
                    </div>
                  </div>
                  {apiData.own.nums.avg && (
                    <Suggestion rating={apiData.own.nums.avg} />
                  )}
                </div>
                <hr className="d-lg-none" />
              </Card.Body>
            </Col>
            <Col lg="6">
              <Card.Body className="h-100">
                <div className="d-flex flex-column h-100">
                  <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                    <small className="text-cap">
                      <FormattedMessage id="app.common.averageRating" />
                    </small>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      {apiData.own.nums.avg ? (
                        <>
                          <span className="display-1 text-dark">
                            {apiData.own.nums.avg.toFixed(2)}
                          </span>
                          <Rating initVal={apiData.own.nums.avg} />
                        </>
                      ) : (
                        <FormattedMessage id="app.common.n/a" />
                      )}
                    </div>
                  </div>
                  {apiData.own.nums.RateSuggestion > 0 &&
                    apiData.own.nums.RateSuggestion <= 100 && (
                      <div className="d-flex align-items-center justify-content-center mt-3 small">
                        <FormattedMessage
                          id="app.helpers.reputationManager.overview.ratingSummary.rateSuggestion"
                          values={{
                            rate: (
                              <span className="me-1">
                                <strong>
                                  {apiData.own.nums.RateSuggestion}
                                </strong>
                              </span>
                            ),
                            stars: (
                              <Badge bg="secondary" className="mx-1">
                                <i className="bi-star-fill me-1" />
                                <FormattedMessage
                                  id="app.common.star.plural"
                                  values={{
                                    count: 5,
                                  }}
                                />
                              </Badge>
                            ),
                            for: (
                              <span className="text-success mx-1 text-nowrap">
                                <i className="bi-graph-up me-1" />
                                {apiData.own.nums.for}
                              </span>
                            ),
                          }}
                        />
                      </div>
                    )}
                  <hr />
                  <div className="d-flex align-items-center">
                    <small className="text-cap mb-0 me-4">
                      <FormattedMessage id="app.common.responseRate" />
                    </small>
                    <div className="d-flex align-items-center justify-content-end flex-grow-1">
                      <ProgressBar
                        className="table-progress"
                        variant={getProgressVariant(
                          apiData.own.nums.response_rate
                        )}
                        now={apiData.own.nums.response_rate}
                      />
                      <span className="mb-0 ms-2">
                        <small>{`${apiData.own.nums.response_rate.toFixed(
                          2
                        )}%`}</small>
                      </span>
                    </div>
                  </div>

                  {apiData.own.last5.length > 0 && (
                    <>
                      <hr />
                      <div className="d-flex align-items-center">
                        <small className="text-cap mb-0 me-4">
                          <FormattedMessage id="app.common.latestReviews" />
                        </small>
                        <div className="d-flex align-items-center justify-content-end flex-grow-1">
                          {apiData.own.last5.map((item, index) => {
                            const rate = getRateType(item);
                            return (
                              <Badge
                                key={`rate_${index.toString()}`}
                                pill
                                bg={rate.color}
                                className="ms-1"
                              >
                                <i className={`${rate.icon} me-1`} />
                                {Math.round(item)}
                              </Badge>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Card.Body>
            </Col>
          </>
        )}
      </Row>
    </Card>
  );
}

export default RatingSummary;
