import React, { useContext, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { useAxiosQuery } from '../../../../hooks';
import { PropertyContext } from '../../../../context/PropertyContext';
import {
  DateRangePicker,
  RequestLoading,
  RequestResult,
} from '../../../../components';
import { LineChart } from '../../../../components/Charts';
import { ThemeContext } from '../../../../context/ThemeContext';
import Utils from '../../../../utils';
import Constants from '../../../../constants';

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {},
};

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

function RatingBreakdown() {
  const { formatMessage } = useIntl();
  const { theme } = useContext(ThemeContext);
  const { activeProperty } = useContext(PropertyContext);
  /* const [activeGroupId, setActiveGroupId] = useState(
    activeProperty?.groups[0].id
  ); */
  const [date, setDate] = useState(initialDates);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/reputation_manager/breakdown',
    preventFetch: !activeProperty?.id, // || !activeGroupId,
    params: {
      id: activeProperty?.id,
      date,
      // compsetId: activeGroupId,
    },
    select: (data) =>
      data?.own?.[0]
        ? Object.keys(data.own[0])
            .reverse()
            .map((key) => ({
              key: Number(key),
              value: data.own[0][key],
            }))
        : [],
  });

  const getDataset = (data) => ({
    labels: data.map(
      (item) =>
        `${formatMessage(
          { id: 'app.common.star.plural' },
          { count: item.key }
        )}`
    ),
    datasets: [
      {
        data: data.map((item) => item.value),
        ...Utils.Chart.getLineChartOptions(0, theme, {
          fill: 'start',
          backgroundColor: (context) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, Constants.Colors.Palette[0]);
            gradient.addColorStop(
              1,
              Utils.Color.getRGBAColorFromHex(Constants.Colors.Palette[2], 0.1)
            );
            return gradient;
          },
          tension: 0.5,
        }),
      },
    ],
  });

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
  };

  /* const handleChangeGroup = (id) => {
    setActiveGroupId(id);
  };

  const renderActiveGroup = () => {
    const foundedGroup = activeProperty?.groups.find(
      (group) => Number(group.id) === Number(activeGroupId)
    );

    return (
      <>
        <span
          className="legend-indicator me-2"
          style={{ backgroundColor: foundedGroup?.color }}
        />
        {foundedGroup?.name}
      </>
    );
  }; */

  /* useEffect(() => {
    setActiveGroupId(activeProperty?.groups[0].id);
  }, [activeProperty]); */

  return (
    <Card className="h-100">
      <Card.Header>
        <Row className="justify-content-between align-items-center flex-grow-1">
          <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.ratingBreakdown" />
            </Card.Title>
          </Col>
          <Col xs="auto">
            <Row className="align-items-sm-center">
              <Col sm="auto" className="mb-2 mb-md-0">
                <DateRangePicker
                  type="button"
                  initialSettings={{
                    initialDates: {
                      startDate: initialDates[0],
                      endDate: initialDates[1],
                    },
                  }}
                  value={date}
                  onChange={handleChangeDate}
                  disabled={apiLoading}
                />
              </Col>
              {/* <Col sm="auto" className="mb-2 mb-md-0">
                <Dropdown align="end" onSelect={handleChangeGroup}>
                  <Dropdown.Toggle
                    as={Button}
                    size="sm"
                    variant="white"
                    disabled={apiLoading}
                  >
                    {renderActiveGroup()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="m-0" renderOnMount>
                    {activeProperty?.groups.map((group) => (
                      <Dropdown.Item
                        key={group.id}
                        eventKey={group.id}
                        className="d-flex align-items-center"
                      >
                        <span
                          className="legend-indicator me-2"
                          style={{ backgroundColor: group.color }}
                        />
                        {group.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                    </Col> */}
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData?.length === 0 && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && !apiError && apiData?.length > 0 && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <LineChart data={getDataset(apiData)} options={options} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default RatingBreakdown;
