import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';

function BacklinkCard({ url }) {
  const [apiData, setApiData] = useState(null);
  const [apiError, setApiError] = useState(false);

  const { isFetching: apiLoading, refetch } = useAxiosQuery({
    url,
    requestType: 'get',
    onSuccess: (data) => {
      if (data.backlink || data.backlink === 0) {
        setApiData(`${data.backlink}`);
      } else {
        setApiError(true);
      }
    },
  });

  const getCardColor = (data) => {
    let cl = 'secondary';
    if (Number(data) > 0) {
      cl = 'success';
    }
    return cl;
  };

  const getCardIcon = (data) => {
    let icon = 'bi-dash';
    if (Number(data) > 0) {
      icon = 'bi-check';
    }
    return icon;
  };

  const handleCheck = () => {
    setApiData(null);
    setApiError(false);
    refetch();
  };

  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="align-items-center gx-2">
          <Col>
            <Card.Subtitle className="mb-2">
              <FormattedMessage id="app.common.countOfBacklinks" />
            </Card.Subtitle>
            {apiLoading && !apiError && (
              <span className="text-dark">
                <FormattedMessage id="app.common.loading" />
              </span>
            )}
            {!apiLoading && apiError && (
              <>
                <span className="text-danger d-block mb-1">
                  <small>
                    <FormattedMessage id="app.common.somethingWentWrong" />
                  </small>
                </span>
                <Button variant="primary" size="xs" onClick={handleCheck}>
                  <FormattedMessage id="app.common.tryAgain" />
                </Button>
              </>
            )}
            {!apiLoading && !apiError && (apiData || apiData === 0) && (
              <span className="text-dark">{apiData}</span>
            )}
          </Col>
          <Col xs="auto">
            {!apiLoading && apiError && (
              <span className="icon icon-circle icon-danger">
                <i className="display-5 bi-exclamation" />
              </span>
            )}
            {apiLoading && !apiError && (
              <span className="icon icon-circle icon-secondary">
                <Spinner animation="border" size="sm" />
              </span>
            )}
            {!apiLoading && !apiError && (apiData || apiData === 0) && (
              <span
                className={`icon icon-circle icon-${getCardColor(apiData)}`}
              >
                <i className={`display-5 ${getCardIcon(apiData)}`} />
              </span>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
BacklinkCard.propTypes = {
  url: PropTypes.string.isRequired,
};

export default BacklinkCard;
