import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Image, Button, Row, Col, Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import {
  RequestLoading,
  RequestResult,
  Tooltip,
  ShowMoreText,
  DateRangePicker,
} from '../../../../components';
import { useAxiosQuery } from '../../../../hooks';
import { ThemeContext } from '../../../../context/ThemeContext';
import { LineChart } from '../../../../components/Charts';
import Utils from '../../../../utils';
import PlaceHolderImg from '../../../../assets/images/placeholder.png';
import Constants from '../../../../constants';
import { Routes as AppRoutes } from '../../../../router/routeMapping';

const imagePath = `${process.env.REACT_APP_BASE_URL || ''}/social/img`;

const options = {
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: Constants.DateFormats.APP.Moment.Common,
        },
      },
    },
    y: {
      min: 0,
      max: null,
      ticks: {
        precision: 0,
        stepSize: null,
        callback: (value) => value,
      },
    },
  },
};

const chartGroups = [
  {
    items: [
      {
        apiKey: 'total_posts',
        titleKey: 'totalPosts',
        icon: 'bi-send-fill',
      },
    ],
  },
  {
    items: [
      {
        apiKey: 'followers',
        titleKey: 'followers',
        icon: 'bi-person-check-fill',
      },
      { apiKey: 'following', titleKey: 'following', icon: 'bi-person-heart' },
    ],
  },
];

const stats = chartGroups.reduce(
  (result, { items }) => result.concat(items),
  []
);

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

function TabPaneItem({ item, property }) {
  const { theme } = useContext(ThemeContext);
  const [date, setDate] = useState(initialDates);
  const [activeGroupId, setActiveGroupId] = useState(property.groups[0].id);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/social/get_by_title',
    preventFetch: !property?.id || !activeGroupId,
    params: {
      date,
      compsetID: activeGroupId,
      property_id: property.id,
      platform: item.site,
    },
  });

  const getDataForDates = (dates, data, type) =>
    dates.map(
      (nDate) => data.find((nItem) => nItem.createAt === nDate)?.[type] || null
    );

  const getDataset = (data, type) => {
    const dates = Utils.Date.getDaysBetweenDates(date[0], date[1]).map(
      (nItem) => moment(nItem).format(Constants.DateFormats.API)
    );

    const compsData = [];
    Object.values(data.compe).forEach((nItem, index) => {
      if (item.stats) {
        compsData.push({
          data: getDataForDates(dates, nItem.stats, type),
          label: nItem.title,
          ...Utils.Chart.getLineChartOptions(index + 1, theme),
        });
      }
    });

    return {
      labels: dates,
      datasets: [
        {
          data: getDataForDates(dates, data.own.stats, type),
          label: data.own.title,
          ...Utils.Chart.getLineChartOptions(0, theme),
        },
        ...compsData,
      ],
    };
  };

  const onImageError = (e) => {
    e.target.src = PlaceHolderImg;
  };

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
  };

  const handleChangeGroup = (id) => {
    setActiveGroupId(id);
  };

  const renderActiveGroup = () => {
    const foundedGroup = property.groups.find(
      (group) => Number(group.id) === Number(activeGroupId)
    );

    return (
      <>
        <span
          className="legend-indicator me-2"
          style={{ backgroundColor: foundedGroup?.color }}
        />
        {foundedGroup.name}
      </>
    );
  };

  useEffect(() => {
    setActiveGroupId(property?.groups[0].id);
  }, [property]);

  return (
    <>
      <Row>
        <Col lg="8" xl="9" className="mb-3 mb-lg-5">
          <Card className=" h-100">
            <Card.Header className="card-header-content-between">
              <Card.Title as="h4" bsPrefix="card-header-title">
                <FormattedMessage id="app.common.profileInfo" />
              </Card.Title>
              <Button
                as={Link}
                size="xs"
                to={AppRoutes.protected.PROPERTY_EDIT.path.replace(
                  ':id',
                  kebabCase(property.title)
                )}
              >
                <i className="bi-pencil me-1" />
                <FormattedMessage id="app.common.edit" />
              </Button>
            </Card.Header>
            <Card.Body>
              <div className="d-flex flex-column flex-md-row">
                <Image
                  className="avatar avatar-circle avatar-xxl mb-3 me-0 mb-md-0 me-md-3"
                  src={`${imagePath}/${item.image}`}
                  onError={onImageError}
                  style={{ objectFit: 'cover' }}
                />

                <div>
                  <Card.Title as="h4" className="d-flex align-items-center">
                    {item.username}
                    {Boolean(item.verified) && (
                      <Tooltip
                        content={<FormattedMessage id="app.common.verified" />}
                      >
                        <i className="bi-patch-check-fill fs-4 text-primary ms-2" />
                      </Tooltip>
                    )}
                  </Card.Title>
                  {item.fullname && <Card.Text>{item.fullname}</Card.Text>}
                  <Card.Text as="div" className="text-muted mb-3">
                    {item.description ? (
                      <ShowMoreText
                        text={item.description}
                        maxLine={2}
                        basedOn="words"
                      />
                    ) : (
                      <FormattedMessage id="app.common.noDescription" />
                    )}
                  </Card.Text>
                  {item.url && (
                    <Button
                      as="a"
                      size="sm"
                      href={Utils.String.withHttps(item.url)}
                      target="_blank"
                    >
                      <i className="bi-box-arrow-up-right me-1" />
                      <FormattedMessage id="app.common.visitProfile" />
                    </Button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" xl="3" className="mb-3 mb-lg-5">
          <Card className="card-body mb-3 mb-lg-5 h-100">
            <div className="d-flex flex-column justify-content-between h-100">
              {stats.map((stat) => (
                <div className="d-flex mb-3" key={stat.apiKey}>
                  <div className="flex-grow-1">
                    <h6 className="card-subtitle mb-3">
                      <FormattedMessage id={`app.common.${stat.titleKey}`} />
                    </h6>
                    <h3 className="card-title">
                      {item.stats?.[stat.apiKey] || (
                        <FormattedMessage id="app.common.noData" />
                      )}
                    </h3>
                  </div>

                  <span className="icon icon-soft-secondary fs-3 icon-circle ms-3">
                    <i className={stat.icon} />
                  </span>
                </div>
              ))}
            </div>
          </Card>
        </Col>
      </Row>
      <Card className="mb-3 mb-lg-5">
        <Card.Header>
          <Row className="justify-content-between align-items-center flex-grow-1">
            <Col md className="mb-3 mb-md-0">
              <Card.Title bsPrefix="card-header-title" as="h4">
                <FormattedMessage id="app.common.profilestatistics" />
              </Card.Title>
            </Col>
            <Col xs="auto">
              <Row className="align-items-sm-center">
                <Col sm="auto" className="mb-2 mb-sm-0">
                  <Row className="align-items-center gx-0">
                    <Col>
                      <span className="text-secondary me-2">
                        <FormattedMessage id="app.common.date" />:
                      </span>
                    </Col>
                    <Col xs="auto">
                      <DateRangePicker
                        type="button"
                        initialSettings={{
                          initialDates: {
                            startDate: initialDates[0],
                            endDate: initialDates[1],
                          },
                        }}
                        value={date}
                        onChange={handleChangeDate}
                        disabled={apiLoading}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="auto" className="mb-2 mb-sm-0">
                  <Row className="align-items-center gx-0">
                    <Col>
                      <span className="text-secondary me-2">
                        <FormattedMessage id="app.common.compset" />:
                      </span>
                    </Col>
                    <Col xs="auto">
                      <Dropdown align="end" onSelect={handleChangeGroup}>
                        <Dropdown.Toggle
                          as={Button}
                          size="sm"
                          variant="white"
                          disabled={apiLoading}
                        >
                          {renderActiveGroup()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="m-0" renderOnMount>
                          {property.groups.map((group) => (
                            <Dropdown.Item
                              key={group.id}
                              eventKey={group.id}
                              className="d-flex align-items-center"
                            >
                              <span
                                className="legend-indicator me-2"
                                style={{ backgroundColor: group.color }}
                              />
                              {group.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>

        <RequestLoading loading={apiLoading} size="lg" margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading &&
          !apiError &&
          apiData &&
          chartGroups.map((chartGroup, groupIndex) => (
            <React.Fragment key={`${groupIndex.toString()}`}>
              <Row className="col-lg-divider">
                {chartGroup.items.map((chart) => (
                  <Col lg={12 / chartGroup.items.length} key={chart.apiKey}>
                    <Card.Body>
                      <div>
                        <h4 className="mb-4">
                          <FormattedMessage
                            id={`app.common.${chart.titleKey}`}
                          />
                        </h4>
                        <LineChart
                          data={getDataset(apiData, chart.apiKey)}
                          options={options}
                        />
                      </div>
                    </Card.Body>
                  </Col>
                ))}
              </Row>
              {groupIndex < chartGroups.length - 1 && <hr />}
            </React.Fragment>
          ))}
      </Card>
    </>
  );
}

TabPaneItem.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TabPaneItem;
